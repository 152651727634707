import React, { useContext } from "react";
import { makeStyles } from "@material-ui/styles";
import { FONTS, parseUtcToLocal } from "../../../../../../utils";
import { Button } from "@material-ui/core";
import { UsersCount } from "../../../../../../commons/user-count";
import { ALERTS_REDUCER_TYPES, DEVICES_REDUCER_TYPES } from "../../../../../../reducers";
import { UserPermissionContext } from "../../../../../../commons";
import { useDispatch, useSelector } from "react-redux";
import { DevicesDialog } from "../../../../../../commons/dialogs/devices-dialog";

const CSS_VALS = {
  SPACE_BETWEEN: "space-between",
};

const useStyles = makeStyles((theme) => ({
  cardWrapper: {
    width: "100%",
  },
  cardHeader: {
    backgroundColor: "#FF453A",
    color: theme.palette.common.black,
    padding: "5px",
    display: "flex",
    justifyContent: CSS_VALS.SPACE_BETWEEN,
  },
  cardTitle: {
    ...FONTS.Label2_Bold_12,
  },
  cardTitleTime: {
    ...FONTS.B5_Reg_12,
  },
  zoneName: {
    ...FONTS.Label1_ShellBook_24,
    justifyContent: "space-between",
    alignItems: "center",
    display: "flex",
    marginBottom: "10.8px"
  },
  paper: { width: "100%", height: "100%" },
  usersInfo: {
    display: "flex",
    justifyContent: CSS_VALS.SPACE_BETWEEN,
    marginBottom: "30px",
  },
  actions: {
    display: "flex",
    justifyContent: CSS_VALS.SPACE_BETWEEN,
    alignItems: "center",
  },
  content: {
    padding: "10px",
  },
  lastUpdated: {
    ...FONTS.B5_Reg_12,
  },
  alertDeviceInfo: {
    fontSize: "12px",
    lineHeight: "109%",
    marginBottom: "17px"
  },
}));

export const PlotClearCard = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { Permissions } = useContext(UserPermissionContext);
  const { alert, clearAlert } = props;
  const showDialog = useSelector((state) => state.devicesScreen.showListofDevicesDialog);
  return (
    <div className={classes.cardWrapper}>
      <div className={classes.cardHeader}>
        <span className={classes.cardTitle}>{alert.alertTitle}</span>
        <span className={classes.cardTitleTime}>{parseUtcToLocal(alert.createdTimestamp)}</span>
      </div>
      <div className={classes.content}>
        <div className={classes.zoneName}>
          {alert.zone.name} ({alert.zone.headcount})
        </div>
        <div className={classes.alertDeviceInfo}>Triggered by: {alert.createdBy}</div>
        <div className={classes.usersInfo}>
          <UsersCount label={"IN ZONE"} count={alert.inZoneCount} countColor="#DB4052" />
          <UsersCount
            label={"IN TRANSIT TO MUSTER POINT"}
            count={alert.inTransitCount}
            countColor=""
          />
          <UsersCount label={"MARKED SAFE"} count={alert.markedSafeCount} countColor="" />
          <UsersCount label={"ERT IN ZONE"} count={alert.ertCount} countColor="#13BC86" />
          <UsersCount label={"Sent to devices"} showDialog={true} count={alert.sentCount} countColor="#13BC86" alertData={alert} />
        </div>
        <div className={classes.actions}>
          <div className={classes.lastUpdated}>
            <div>Last Updated</div>
            <div>
              {alert.lastModifiedTimestamp ? parseUtcToLocal(alert.lastModifiedTimestamp) : null}
            </div>
          </div>
          <div>
            {Permissions.Manage_Alerts ? (
              <Button
                variant="outlined"
                onClick={() => {
                  dispatch({
                    type: ALERTS_REDUCER_TYPES.SET_SELECTED_CURRENT_ALERT,
                    payload: alert,
                  });
                  clearAlert();
                }}
              >
                CLEAR
              </Button>
            ) : null}
          </div>
        </div>
        <DevicesDialog
          show={showDialog}
          onCancel={() => {
            dispatch({type: DEVICES_REDUCER_TYPES.DEVICES_DIALOG_DISPLAY, payload: {allDevicesList:[],show:false}});
          }}
        />
      </div>
    </div>
  );
};
