import React, { useEffect, useState } from "react";
import { Paper, Box, DialogTitle, Button, makeStyles } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ShellIcon } from "../../commons/icon-manager/icons/shell";
import { PrivacyConsentForm, PrivacyAcknowledgeForm } from "./components";
import { UserAPI } from "../../api";
import { GlobalReducerActions } from "../../reducers";
import { FONTS, parseUtcToLocal } from "../../utils";
import { QR } from "../../commons/qrcode";
import { ParagraphJSON } from "../../commons/paragraphs";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "max-content",
    maxWidth: "95vw",
    backgroundColor: theme.palette.base.elevation_1,
    marginBottom: "40px",
    boxShadow: "0px 15px 12px rgba(0, 0, 0, 0.22), 0px 19px 38px rgba(0, 0, 0, 0.3)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  iconWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "40px",
    paddingTop: "40px",
  },
  title: {
    paddingTop: "24px",
    paddingBottom: 0,
  },
  button: {
    marginRight: "20px",
  },
  qrExpiryMessage: {
    marginBottom: "30px",
    "@media(max-width: 500px)": {
      marginBottom: "15px",
    },
  },
  qrPaper: {
    width: "80%",
    maxWidth: "760px",
    backgroundColor: theme.palette.base.elevation_1,
    marginBottom: "40px",
    boxShadow: "0px 15px 12px rgba(0, 0, 0, 0.22), 0px 19px 38px rgba(0, 0, 0, 0.3)",
    "@media(max-width: 950px)": {
      width: "95%",
    },
    "@media(max-width: 570px)": {
      width: "100%",
    },
    alignItems: "left",
  },
  qrContainer: {
    float: "left",
    width: "37%",
    padding: "5%",
    "@media(max-width: 800px)": {
      width: "45%",
    },
    "@media(max-width: 540px)": {
      width: "48%",
    },
    "@media(max-width: 500px)": {
      width: "90%",
      padding: "15px 25%",
    },
  },
  qrTitle: {
    ...FONTS.H5_ShellBold_18,
    marginTop: "24px",
    marginBottom: "19px",
    "@media(max-width: 500px)": {
      marginTop: "5px",
    },
  },
  warnMsg: {
    float: "left",
    width: "60%",
    ...FONTS.B3_Reg_14,
    "@media(max-width: 800px)": {
      width: "45%",
    },
    "@media(max-width: 540px)": {
      width: "48%",
    },
    "@media(max-width: 500px)": {
      width: "90%",
      padding: "10px 5%",
    },
  },
}));

const USER_STATUS = {
  ACCEPTED: "Accepted",
  DECLINED: "Declined",
  PENDING: "Pending",
};

async function acceptPrivacyConsent(
  siteLocationId,
  isAcknowledgementEnabled,
  userId,
  dispatch,
  optInStatuses,
  setOptInStatuses
) {
  try {
    const params = new URLSearchParams(window.location.search);
    const securityStamp = params.get("uuid");
    await UserAPI.updateOptInStatus({
      id: userId,
      userOptInStatus: USER_STATUS.ACCEPTED,
      securityStamp,
      siteLocationId,
      isAcknowledged: isAcknowledgementEnabled,
    });
    setSiteStatus(siteLocationId, USER_STATUS.ACCEPTED, optInStatuses, setOptInStatuses);
  } catch (error) {
    dispatch(GlobalReducerActions.setApiRequestError(error));
  }
}

async function declinePrivacyConsent(
  siteLocationId,
  userId,
  dispatch,
  optInStatuses,
  setOptInStatuses
) {
  try {
    const params = new URLSearchParams(window.location.search);
    const securityStamp = params.get("uuid");
    await UserAPI.updateOptInStatus({
      id: userId,
      userOptInStatus: USER_STATUS.DECLINED,
      securityStamp,
      siteLocationId,
    });
    setSiteStatus(siteLocationId, USER_STATUS.DECLINED, optInStatuses, setOptInStatuses);
  } catch (error) {
    dispatch(GlobalReducerActions.setApiRequestError(error));
  }
}

function setSiteStatus(siteLocationId, status, optInStatuses, setOptInStatuses) {
  const index = optInStatuses.findIndex((o) => o.siteLocationId === siteLocationId);
  if (index > -1) {
    const newStatuses = [...optInStatuses];
    newStatuses[index].userOptInStatus = status;
    newStatuses[index].isAcknowledged = newStatuses[index].isAcknowledgementEnabled;
    setOptInStatuses(newStatuses);
  }
}

const headerLabel = (optInStatuses) => {
  if (optInStatuses.length === 1 && optInStatuses[0].isAcknowledgementEnabled === true) {
    return "Sitewise Privacy Policy";
  } else if (optInStatuses.length === 1 && optInStatuses[0].isAcknowledgementEnabled === false) {
    return "Sitewise Privacy Agreement for Site Access";
  } else {
    return "Sitewise Privacy Agreements for Multisite Access";
  }
};

const PrivacyConsentScreen = () => {
  const { userId } = useParams();
  const [optInStatuses, setOptInStatuses] = useState([]);
  const [personalToken, setPersonalToken] = useState({
    userId: 0,
    otpToken: "",
    expiry: "",
  });
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    UserAPI.getOptInStatus(userId).then((res) => {
      setOptInStatuses(res);
    });
  }, [userId]);

  function renderRow(optInStatus) {
    const {
      userOptInStatus,
      isAcknowledgementEnabled,
      isAcknowledged,
      siteLocationName,
      siteLocationId,
    } = optInStatus;
    const isAccepted =
      userOptInStatus === USER_STATUS.DECLINED ||
      (!isAcknowledgementEnabled && userOptInStatus === USER_STATUS.ACCEPTED) ||
      (isAcknowledgementEnabled && isAcknowledged);
    if (isAccepted) {
      return (
        <PrivacyAcknowledgeForm
          siteLocation={siteLocationName}
          userStatus={userOptInStatus}
          onClick={() => setSiteStatus(siteLocationId, "Pending", optInStatuses, setOptInStatuses)}
          isAcknowledgementEnabled={isAcknowledgementEnabled}
          isAcknowledged={isAcknowledged}
        />
      );
    } else if (isAcknowledgementEnabled || optInStatus.userOptInStatus === USER_STATUS.PENDING) {
      return (
        <PrivacyConsentForm
          siteLocationId={siteLocationId}
          siteLocation={siteLocationName}
          userStatus={userOptInStatus}
          acceptPrivacyConsent={() => {
            acceptPrivacyConsent(
              siteLocationId,
              isAcknowledgementEnabled,
              userId,
              dispatch,
              optInStatuses,
              setOptInStatuses
            );
          }}
          declinePrivacyConsent={() => {
            declinePrivacyConsent(
              siteLocationId,
              userId,
              dispatch,
              optInStatuses,
              setOptInStatuses
            );
          }}
          isAcknowledgementEnabled={isAcknowledgementEnabled}
        />
      );
    } else {
      return null;
    }
  }

  async function getPersonalToken() {
    try {
      dispatch(GlobalReducerActions.showPageLoader());
      const params = new URLSearchParams(window.location.search);
      const securityStamp = params.get("uuid");
      const response = await UserAPI.getOtpToken(userId, securityStamp);
      dispatch(GlobalReducerActions.hidePageLoader());
      setPersonalToken(response);
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
    }
  }

  return (
    <Box style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <Box className={classes.iconWrapper}>
        <ShellIcon
          style={{ width: "66px", height: "66px" }}
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        />
      </Box>
      <Paper className={classes.paper}>
        <DialogTitle className={classes.title}>
          <span style={{ ...FONTS.Button3_ShellBold_12 }}>{headerLabel(optInStatuses)}</span>
        </DialogTitle>
        {optInStatuses.map((status) => renderRow(status))}
      </Paper>
      {!personalToken.userId ? (
        <Button
          className={classes.button}
          onClick={getPersonalToken}
          variant="outlined"
          color="primary"
        >
          Generate QR Code for mobile
        </Button>
      ) : (
        <Paper className={classes.qrPaper}>
          <div className={classes.qrContainer}>
            <QR
              url={`${process.env.REACT_APP_PERSONAL_QR_CODE_GENERATOR_URL}/${personalToken.userId}/${personalToken.otpToken}`}
              size="155"
            />
          </div>

          <div className={classes.warnMsg}>
            <div className={classes.qrTitle}>Onboard mobile Device</div>
            <div className={classes.qrExpiryMessage}>
              QR Code expires {parseUtcToLocal(personalToken.expiry)}
            </div>
            <div>{ParagraphJSON.qrWarning}</div>
          </div>
        </Paper>
      )}
    </Box>
  );
};

export default PrivacyConsentScreen;
