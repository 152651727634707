import React, { useEffect, useState } from "react";
import { Grid, Button, Paper, CircularProgress, Link, makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { FONTS } from "../../utils";
import { AuthReducerActions, GlobalReducerActions, GLOBAL_REDUCER_TYPES } from "../../reducers";
import { useHistory } from "react-router-dom";
import { ShellIcon } from "../../commons/icon-manager/icons/shell";
import { Version, USER_PERMISSIONS } from "../../commons";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    position: "relative",
    justifyContent: "center",
    top: "20%",
  },
  paper: {
    minWidth: "684px",
    height: "431px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "25px",
    backgroundColor: theme.palette.base.elevation_1,
  },
  titleWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "60px",
  },
  title: {
    ...FONTS.H2_ShellBold_32,
    marginTop: "15px",
  },
  bttnContainer: {
    position: "relative",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  form: {
    paddingTop: "20px",
    paddingBottom: "42px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  loginBttn: {
    ...FONTS.H8_ShellBook_18,
    padding: "16px",
    borderRadius: "2px",
    borderWidth: "2px",
    borderColor: "#FDE26A",
  },
  troubleLogin: {
    ...FONTS.B3_Reg_14,
    paddingTop: "60px",
  },
  versionContainer: {
    paddingTop: "36px",
    ...FONTS.B5_Reg_12,
  },
}));

function renderButtonContainer({ accessDenied, isUserLogginIn, handleSignIn, classes }) {
  if (accessDenied) {
    return "You do not have access to Sitewise at this time. Please contact your manager.";
  } else {
    return (
      <>
        <Button
          size="large"
          disabled={isUserLogginIn}
          color="primary"
          variant="outlined"
          onClick={handleSignIn}
          data-testid="login-bttn"
          style={{
            fontWeight: "bold",
            border: "2px solid #FDE26A",
            padding: "20px 15px",
          }}
        >
          Login As Shell User
        </Button>
        {isUserLogginIn && (
          <CircularProgress
            data-testid="circular-loader"
            size={24}
            color="primary"
            className={classes.buttonProgress}
          />
        )}
      </>
    );
  }
}

export const LoginScreen = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [isUserLogginIn, setIsUserLogginIn] = useState(false);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const accessDenied = useSelector((state) => state.auth.accessDenied);
  const tokenInfo = useSelector((state) => state.auth.sitewiseUser);

  function handleSignIn() {
    dispatch(AuthReducerActions.signInRedirect());
    setIsUserLogginIn(true);
  }

  useEffect(() => {
    if (window.location.search) {
      dispatch(AuthReducerActions.signInRedirectCb());
      setIsUserLogginIn(true);
    }
  }, [dispatch]);

  useEffect(() => {
    if (isLoggedIn && tokenInfo) {
      if ((tokenInfo.Permissions && tokenInfo.Permissions.includes(USER_PERMISSIONS.Manage_Sites)) || tokenInfo.SiteLocationIds.length > 1) {
        history.push("/sites");
      } else {
        const siteID = Array.isArray(tokenInfo.SiteLocationIds)
          ? tokenInfo.SiteLocationIds[0]
          : tokenInfo.SiteLocationIds;
        localStorage.setItem("SITE_ID", siteID);
        dispatch({ type: GLOBAL_REDUCER_TYPES.SET_SITE_ID, payload: siteID });

        dispatch(GlobalReducerActions.getSiteById(siteID)).then(() => {
          history.push("/zones");
        });
      }

      setIsUserLogginIn(false);
    }
  }, [isLoggedIn, history, tokenInfo, dispatch]);

  return (
    <Grid className={classes.wrapper}>
      <Paper className={classes.paper}>
        <div className={classes.titleWrapper}>
          <ShellIcon
            style={{ width: "66px", height: "66px" }}
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          />
          <span className={classes.title}>Welcome to Sitewise</span>
        </div>
        <div className={classes.bttnContainer}>
          {renderButtonContainer({
            accessDenied,
            handleSignIn,
            isUserLogginIn,
            classes,
          })}
        </div>
        <div className={classes.troubleLogin}>
          <Link href="mailto:GXSITIJARVISProgram@shell.com" style={{ color: "white" }}>
            Trouble logging in?
          </Link>
        </div>
        <Version title="Shell Sitewise Version" className={classes.versionContainer} />
      </Paper>
    </Grid>
  );
};
