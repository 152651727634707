import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";

export const YellowRadio = withStyles({
  root: {
    "&$checked": {
      color: "#FBCE07",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);
