import { React, useEffect, useState } from "react";
import { makeStyles, Dialog, DialogTitle, FormControl, Button, Box, DialogContent, IconButton, Tooltip } from "@material-ui/core";
import { useDialogStyles, ICONS, RemoveDialog as SuperAdminRemoveDialog } from "../../../../../commons";
import { useDispatch, useSelector } from "react-redux";
import { SITES_REDUCER_TYPES, SitesReducerActions } from "../../../../../reducers";
import { EditSuperAdminDialog } from "../edit-super-admin-dialog/index";
import { withStyles } from "@material-ui/core/styles";

const cssProperties = {
  filledVariant: "filled",
  primaryColor: "primary",
};
const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    marginBottom: "32px",
  },
  formControlActions: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  divSuperuserlist: {
    overflowY: "scroll",
    height: "200px"
  },
  superAdminName: {
    display: "inline-block",
    width: "75%",
  },
  deleteIconStyle: {
    color: "#FEF5CD",
    Width: "14px",
    Height: "18px",
    marginTop: "3px",
    marginLeft: "10px",
    "&:hover": {
      color: "#FBCE07"
    },
  },
  editIconStyle: {
    color: "#FEF5CD",
    Width: "18px",
    Height: "18px",
    marginTop: "3px",
    marginLeft: "3px",
    "&:hover": {
      color: "#FBCE07"
    },
  },
  superAdminListTitle: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "100%",
    letterSpacing: "0.02em",
    marginBottom: "16px"
  },
  superAdminListItem: {
    fontSize: "16px",
    lineHeight: "120%",
    marginBottom: "12px",
    letterSpacing: "0.02em"
  }
}));


export const SuperAdminSettingsDialog = ({ show, onCancel, onConfirm }) => {
  const classes = useStyles();
  const dialogStyles = useDialogStyles();
  const dispatch = useDispatch();
  const allSuperUserslist = useSelector((state) => state.sitesScreen.allSuperUser);
  const showEditSuperAdminDialog = useSelector((state) => state.sitesScreen.dialogs.showUpdateSuperAdminUser);
  const showRemoveSuperAdminDialog = useSelector((state) => state.sitesScreen.dialogs.showRemoveSuperAdminUser);
  const [superAdminName, setSuperAdminName] = useState("");
  const [superAdminId, setSuperAdminId] = useState(0);

  function handleEdit(id, name) {
    setSuperAdminId(id);
    setSuperAdminName(name);
    dispatch({ type: SITES_REDUCER_TYPES.SET_UPDATE_SUPER_USER_DIALOG_DISPLAY, payload: true });
  }

  function handleDelete(id, name) {
    setSuperAdminId(id);
    setSuperAdminName(name);
    dispatch({ type: SITES_REDUCER_TYPES.SET_REMOVE_SUPER_USER_DIALOG_DISPLAY, payload: true });
  }

  async function deleteSuperUser() {
    await dispatch(SitesReducerActions.deleteSuperUSer(superAdminId));
    setSuperAdminId(0);
  }

  function handleFormSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    onConfirm && onConfirm(event);
  }

  useEffect(() => {
    if (!showRemoveSuperAdminDialog) {
      setSuperAdminId(0);
      setSuperAdminName("");
    }
  }, [showRemoveSuperAdminDialog]);

  if (!show) {
    return null;
  }

  const SuperAdminTooltip = withStyles({
    tooltip: {
      backgroundColor: "#464659",
      fontSize: "14px",
      lineHeight: "100%",
      margin: "12px 0px"
    }
  })(Tooltip);

  return (
    <Dialog open={show} onClose={onCancel} PaperProps={{ className: dialogStyles.dialogPaper }}>
      <Box className={dialogStyles.dialogContentWrapper}>
        <DialogTitle className={dialogStyles.dialogTitle} disableTypography onClose={onCancel}>
          <span>Super Admin Settings</span>
        </DialogTitle>
        <DialogContent>
          <div className={classes.superAdminListTitle}>Sitewise Super Admins:</div>
          {allSuperUserslist != null && allSuperUserslist.length > 0 ?
            <div className={classes.divSuperuserlist}>
              {allSuperUserslist.map((user) => {
                return <div className={classes.superAdminListItem} key={user.id}>
                  <div className={classes.superAdminName}>
                    {user.name}
                  </div>
                  <SuperAdminTooltip title="Edit Name" placement="top">
                    <IconButton
                      size="small"
                      className={classes.editIconStyle}
                      variant="outlined"
                      onClick={() => { handleEdit(user.id, user.name) }}
                    >
                      <ICONS.Edit />
                    </IconButton>
                  </SuperAdminTooltip>
                  <SuperAdminTooltip title={`Delete ${user.name}`} placement="top">
                    <IconButton
                      size="small"
                      className={classes.deleteIconStyle}
                      variant="outlined"
                      onClick={() => { handleDelete(user.id, user.name) }}
                    >
                      <ICONS.Delete />
                    </IconButton>
                  </SuperAdminTooltip>
                </div>;
              })
              }
            </div> : <div></div>}

        </DialogContent>

        <Button
          startIcon={<ICONS.Add />}
          color="primary"
          onClick={() =>
            dispatch({
              type: SITES_REDUCER_TYPES.SET_CREATE_NEW_SUPER_ADMIN_USER_DIALOG_DISPLAY,
              payload: true,
            })
          }
        >
          Add New Super Admin
          </Button>


        <form className={classes.form} autoComplete="off" onSubmit={handleFormSubmit}>
          <FormControl
            required
            variant={cssProperties.filledVariant}
            className={classes.formControlActions}
          >

            <Button
              type="submit"
              variant="contained"
              color={cssProperties.primaryColor}
              style={{ marginLeft: "10px" }}
              className={dialogStyles.dialogActionBttn}
              data-testid="create-bttn"
              onClick={() =>
                dispatch({
                  type: SITES_REDUCER_TYPES.SET_SUPER_ADMIN_SETTINGS_DISPLAY,
                  payload: false,
                })
              }
            >
              DONE
            </Button>
          </FormControl>
        </form>
      </Box>


      <EditSuperAdminDialog
        show={showEditSuperAdminDialog}
        onCancel={() => {
          dispatch({ type: SITES_REDUCER_TYPES.SET_UPDATE_SUPER_USER_DIALOG_DISPLAY, payload: false });
        }}
        superAdminId={superAdminId}
        superAdminName={superAdminName}

      />

      <SuperAdminRemoveDialog
        title={`Are you sure you want to remove ${superAdminName}?`}
        show={showRemoveSuperAdminDialog}
        onConfirm={deleteSuperUser}
        onCancel={() => {
          dispatch({ type: SITES_REDUCER_TYPES.SET_REMOVE_SUPER_USER_DIALOG_DISPLAY, payload: false });
        }}
      />

    </Dialog>
  );
};
