export const FONTS = {
  H1_Bold_40: {
    fontWeight: "bold",
    fontSize: "40px",
    fontStyle: "normal",
  },
  H2_ShellBold_32: {
    fontWeight: "700",
    fontSize: "32px",
    fontStyle: "normal",
    fontFamily: "ShellBold",
  },
  H4_Bold_24: {
    fontWeight: "bold",
    fontSize: "24px",
    fontStyle: "normal",
  },
  H4_ShellBold_24: {
    fontWeight: "bold",
    fontSize: "24px",
    fontStyle: "normal",
    fontFamily: "ShellBold",
  },
  H5_Bold_22: {
    fontWeight: 600,
    fontSize: "22px",
    fontStyle: "normal",
  },
  H5_ShellBold_18: {
    fontWeight: "700",
    fontSize: "18px",
    fontStyle: "normal",
    fontFamily: "ShellBold",
  },
  H6_Bold_18: {
    fontWeight: "bold",
    fontSize: "18px",
    fontStyle: "normal",
  },
  H7_BOLD_16: {
    fontWeight: 600,
    fontSize: "16px",
    fontStyle: "normal",
  },
  H8_Med_16: {
    fontWeight: 500,
    fontSize: "16px",
    fontStyle: "normal",
  },
  H8_ShellBook_18: {
    fontWeight: 400,
    fontSize: "18px",
    fontStyle: "normal",
    fontFamily: "ShellBook",
  },
  B1_Reg_18px: {
    fontWeight: "normal",
    fontSize: "18px",
    fontStyle: "normal",
  },
  B2_Reg_16: {
    fontWeight: "normal",
    fontSize: "16px",
    fontStyle: "normal",
  },
  B3_Reg_14: {
    fontWeight: "normal",
    fontSize: "14px",
    fontStyle: "normal",
  },
  B4_BOLD_14: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
  },
  B4_Reg_12: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    fontFamily: "Arial",
  },
  B5_Reg_12: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
  },
  B5_Bold_12: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "12px",
  },
  Button1_Bold_16: {
    fontWeight: "bold",
    fontSize: "16px",
    fontStyle: "normal",
  },
  Label1_Reg_12: {
    fontWeight: "normal",
    fontSize: "12px",
    fontStyle: "normal",
  },
  Label1_ShellBook_12: {
    fontWeight: "400",
    fontSize: "12px",
    fontStyle: "normal",
    fontFamily: "ShellBook",
  },
  Label1_ShellBook_24: {
    fontWeight: "400",
    fontSize: "24px",
    fontStyle: "normal",
    fontFamily: "ShellBook",
  },
  Label2_Bold_12: {
    fontWeight: "bold",
    fontSize: "12px",
    fontStyle: "normal",
  },
  Label2_ShellBold_12: {
    fontWeight: "700",
    fontSize: "12px",
    fontStyle: "normal",
    fontFamily: "ShellBold",
  },
  Label1_Med_12: {
    fontWeight: 500,
    fontSize: "12px",
    fontStyle: "normal",
  },
  Button3_ShellBold_12: {
    fontWeight: "700",
    fontSize: "12px",
    fontStyle: "normal",
    fontFamily: "ShellBold",
  },
  Label1_Bold_8: {
    fontSize: "8px",
    fontWeight: "700",
    fontStyle: "normal",
  },
  Label_Reg_10: {
    fontSize: "10px",
    fontWeight: "400",
    fontStyle: "normal",
  },
  Button1_Bold_14: {
    fontWeight: 700,
    fontSize: "14px",
    fontStyle: "normal",
  },
};
