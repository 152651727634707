import React from "react";
import { TableCell, TableRow, makeStyles, IconButton } from "@material-ui/core";
import { ICONS, useCustomTableStyles } from "../../../../../commons";
import { FONTS } from "../../../../../utils";
import { CollapsedRow } from "./collapsed-row";

const useStyles = makeStyles((theme) => ({
  tableRow: {
    backgroundColor: theme.palette.base.elevation_overlay_2,
    boxShadow: "inset 0px -1px 0px #7C7C94",
    "&$selected, &$selected:hover": {
      backgroundColor: theme.palette.base.elevation_overlay_3,
    },
  },
  selected: {
    backgroundColor: theme.palette.base.elevation_overlay_3,
  },
  formLabel1: {
    ...FONTS.B5_Bold_12,
  },
  formLabel2: {
    ...FONTS.B5_Bold_12,
  },
  buttonContainer: {
    justifyContent: "flex-end",
    display: "flex",
    marginRight: "4px",
    marginTop: "4px",
    marginBottom: "12px",
  },
  button: {
    border: "1px solid",
    color: "#FBCE07",
    ...FONTS.B5_Bold_14,
    marginRight: "8px",
  },
  primaryButton: {
    backgroundColor: "#FBCE07",
    ...FONTS.B5_Bold_14,
    marginRight: "8px",
  },
  gridSection: {
    marginTop: "8px",
  },
}));

export const Row = (props) => {
  const classes = useStyles();
  const tableStyles = useCustomTableStyles();
  const {
    rowData,
    onTableExpandBttnCb,
    isItemSelected,
    headCells,
    canManagePermissions,
    dependencyMatrix,
    suggestionsMatrix,
  } = props;

  return (
    <>
      <TableRow
        classes={{
          root: classes.tableRow,
          selected: classes.selected,
        }}
        hover
        selected={isItemSelected}
        onClick={(event) => {
          event.stopPropagation();
        }}
        tabIndex={-1}
        key={`${rowData.id}-firstRow`}
      >
        {headCells.map((cell) => {
          return (
            <TableCell key={`${rowData.id}-${cell.id}`} className={tableStyles.cell}>
              {cell.content(rowData)}
            </TableCell>
          );
        })}
        <TableCell
          key={`${rowData.id}-${rowData.name}-action-bttns`}
          style={{ textAlign: "right" }}
          className={tableStyles.cell}
        >
          <IconButton
            color="primary"
            aria-label="expand row"
            size="small"
            onClick={(event) => {
              event.stopPropagation();
              onTableExpandBttnCb(rowData);
            }}
          >
            {isItemSelected ? <ICONS.KeyboardArrowUp /> : <ICONS.KeyboardArrowDown />}
          </IconButton>
        </TableCell>
      </TableRow>
      <CollapsedRow
        isItemSelected={isItemSelected}
        rowData={rowData}
        classes={classes}
        canManagePermissions={canManagePermissions}
        dependencyMatrix={dependencyMatrix}
        suggestionsMatrix={suggestionsMatrix}
      />
    </>
  );
};
