import { loadModules } from "esri-loader";
import { zoomInfoMapComponent } from "./utils/zoom-info-map-component";
import { store } from "../../../../../../store";
import { SITES_REDUCER_TYPES } from "../../../../../../reducers";
import { addMapWidgets, showCoordinates } from "./utils/add-map-Widgets";
import { MAP_ICONS } from "../../../../../../commons/map";

let ArcGISMap, MapView, GraphicsLayer, Graphic, Point, Search, Zoom;

const GRAPHICS_LAYERS_ID = {
  SUBSITES: "SUBSITES",
};

export class SiteCordsMap {
  constructor({ onMapLoadCb }) {
    this.onMapLoadCb = onMapLoadCb;
    this.modulesLoaded = false;
    this.map = null;
    this.mapView = null;
    this.store = store;
    this.loadModulesAndInitMap();
  }

  async loadModulesAndInitMap() {
    [ArcGISMap, MapView, GraphicsLayer, Graphic, Point, Search, Zoom] = await loadModules(
      [
        "esri/Map",
        "esri/views/MapView",
        "esri/layers/GraphicsLayer",
        "esri/Graphic",
        "esri/geometry/Point",
        "esri/widgets/Search",
        "esri/widgets/Zoom",
      ],
      {
        css: true,
      }
    );

    this.map = new ArcGISMap({
      basemap: "satellite",
    });

    this.mapView = new MapView({
      container: "site-cords-map-container",
      map: this.map,
      zoom: 3,
      constraints: {
        maxZoom: 2,
        minZoom: 22,
        rotationEnabled: true,
      },
    });

    this.mapView.ui.components = [];

    this.mapView.when(() => {
      this.modulesLoaded = true;

      this.renderMap(this.store.getState().sitesScreen.currentSite);

      addMapWidgets(Search, Zoom, this.mapView);

      this.mapView.watch("stationary", (isStationary) => {
        if (!this.store.getState().sitesScreen.mapState.showMapInfoWidget) {
          return;
        }
        if (isStationary) {
          this.store.dispatch({
            type: SITES_REDUCER_TYPES.SET_CURRENT_SUBSITE,
            payload: {
              zoom: parseInt(this.mapView.zoom, 10),
              rotation: this.mapView.rotation,
              extent: {
                xmin: this.mapView.extent.xmin,
                ymin: this.mapView.extent.ymin,
                xmax: this.mapView.extent.xmax,
                ymax: this.mapView.extent.ymax,
                spatialReference: {
                  wkid: this.mapView.extent.spatialReference.wkid,
                },
              },
            },
          });
        }
      });

      this.mapView.on("pointer-move", (event) => {
        showCoordinates(this.mapView.toMap({ x: event.x, y: event.y }), this.mapView.zoom);
      });

      this.mapView.on("double-click", (event) => {
        event.stopPropagation();
        if (this.store.getState().sitesScreen.mapState.enableDoubleClick) {
          this.store.dispatch({
            type: SITES_REDUCER_TYPES.SET_SHOW_MAP_INFO_WIDGET,
            payload: true,
          });

          this.store.dispatch({
            type: SITES_REDUCER_TYPES.SET_CURRENT_SUBSITE,
            payload: {
              latitude: parseFloat(event.mapPoint.latitude.toFixed(5), 10),
              longitude: parseFloat(event.mapPoint.longitude.toFixed(5), 10),
              zoom: parseInt(this.mapView.zoom, 10),
              rotation: this.mapView.rotation,
              extent: {
                xmin: this.mapView.extent.xmin,
                ymin: this.mapView.extent.ymin,
                xmax: this.mapView.extent.xmax,
                ymax: this.mapView.extent.ymax,
                spatialReference: {
                  wkid: this.mapView.extent.spatialReference.wkid,
                },
              },
            },
          });
        }
      });

      // callback method
      this.onMapLoadCb && this.onMapLoadCb(this.store.getState().sitesScreen.currentSite);
    });
  }

  getLayerById = (id) => {
    return this.map.allLayers.find((layer) => {
      return layer.id === id;
    });
  };

  resetMap() {
    this.mapView.graphics.removeAll();

    for (var key in GRAPHICS_LAYERS_ID) {
      if (GRAPHICS_LAYERS_ID.hasOwnProperty(key)) {
      const layer = this.getLayerById(GRAPHICS_LAYERS_ID[key]);
      this.map.remove(layer);
      }
    }
  }

  renderMap = (site, currentSubSite, showMapInfoWidget) => {
    if (!this.modulesLoaded) {
      return;
    }

    this.resetMap();

    const subSitesLayer = new GraphicsLayer({
      id: GRAPHICS_LAYERS_ID.SUBSITES,
    });

    site &&
      site.subSites &&
      site.subSites.forEach(async (_subsite) => {
        if (!(currentSubSite && currentSubSite.name === _subsite.name)) {
          var point = new Point({
            latitude: _subsite.latitude,
            longitude: _subsite.longitude,
          });

          const pointGraphicSymbol = {
            type: "simple-marker",
            style: "path",
            size: "28px",
            outline: {
              color: [255, 255, 255],
              width: 0,
            },
            color: currentSubSite && currentSubSite.name === _subsite.name ? "red" : "#FBCE07",
            path: MAP_ICONS.LOCATION_ICON,
          };

          const pointGraphic = new Graphic({
            geometry: point,
            symbol: pointGraphicSymbol,
          });

          subSitesLayer.add(pointGraphic);
        }
      });

    this.map.addMany([subSitesLayer]);
    this.renderCurrentSubsiteOnMap(currentSubSite);
    this.toggleZoomInfoCmp(showMapInfoWidget);
  };

  toggleZoomInfoCmp(toShow) {
    const _this = this;
    if (toShow) {
      this.mapView.ui.empty("top-left");

      function previewCB() {
        const fullMapView = _this.store.getState().sitesScreen.fullMapView;
        _this.store.dispatch({
          type: SITES_REDUCER_TYPES.SET_FULL_MAP_VIEW,
          payload: !fullMapView,
        });
      }

      function cancelCB() {
        _this.store.dispatch({
          type: SITES_REDUCER_TYPES.SET_SHOW_MAP_INFO_WIDGET,
          payload: false,
        });

        _this.store.dispatch({
          type: SITES_REDUCER_TYPES.RESET_CURRENT_SUBSITE,
          payload: null,
        });

        _this.store.dispatch({
          type: SITES_REDUCER_TYPES.NEW_SITE_FORM_VISIBLE,
          payload: false,
        });

        if (!_this.store.getState().sitesScreen.showDefaultSiteForm) {
          _this.store.dispatch({
            type: SITES_REDUCER_TYPES.SET_MAP_ENABLE_DOUBLE_CLICK,
            payload: false,
          });
        }
      }

      const zoomInfoCMP = zoomInfoMapComponent(
        previewCB,
        cancelCB,
        _this.store.getState().sitesScreen.fullMapView
      );
      this.mapView.ui.add(zoomInfoCMP, "top-left");
    } else {
      this.mapView.ui.empty("top-left");
    }
  }

  renderCurrentSubsiteOnMap(subSite) {
    if (!subSite || subSite.latitude === 0) {
      return;
    }

    const point = new Point({
      latitude: subSite.latitude,
      longitude: subSite.longitude,
    });

    this.mapView.graphics.add(
      new Graphic({
        geometry: point,
        symbol: {
          type: "simple-marker",
          style: "path",
          size: "28px",
          outline: {
            color: [255, 255, 255],
            width: 1,
          },
          path: MAP_ICONS.LOCATION_ICON,
        },
      })
    );

    this.goToSubSite(subSite);
  }

  async goToSubSite(subsite) {
    if (subsite.latitude && subsite.longitude && subsite.zoom) {
      const latitude = parseFloat(subsite.latitude, 10);
      const longitude = parseFloat(subsite.longitude, 10);
      const zoom = subsite.zoom;

      var opts = {
        duration: 2000,
        easing: "ease-in-out",
      };

      try {
        await this.mapView.goTo(
          {
            center: [longitude, latitude],
            zoom: zoom,
            rotation: subsite.rotation || 0,
          },
          opts
        );
      } catch (error) {
        console.log(error);
      }
    }
  }
}
