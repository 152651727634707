import { loadModules } from "esri-loader";
import { store } from "../../../../store";
import { AlertsMapUtils } from "./alerts-map-utils";
import { MapUtilsV2 } from "../../../../commons/map";
import { pointerMoveEventHandler, onUserDeviceClick } from "./util-fns";
import { plotPlanImageLayerJson, MESSAGES_REDUCER_TYPES, ZONE_REDUCER_TYPES } from "../../../../reducers";
import { ICONS } from "../../../../commons/icon-manager/index";

const ALERTS_MAP_CONFIG = {
  DEFAULT_ZOOM: 16,
  type: "ZONE",
};

const GRAPHICS_LAYERS_ID = {
  ZONES: "ZONES_GRAPHICS_LAYER",
  LABELS: "ZONES_LABEL_LAYER",
  HEADCOUNT: "ZONES_HEADCOUNT_LAYER",
  USER_DEVICES: "USER_DEVICES_LAYER",
  MAP_VECTOR_TILE: "MAP_VECTOR_TILE",
  MAP_IMAGE_LAYER: "MAP_IMAGE_LAYER",
  USER_DEVICES_LOG: "USER_DEVICES_LOG",
};

let ArcGISMap,
  MapView,
  GraphicsLayer,
  Graphic,
  Polygon,
  Point,
  geometryEngine,
  VectorTileLayer,
  TileLayer,
  ImageryLayer,
  MapImageLayer;

export class AlertsMap {
  constructor({ onMapLoadCb, viewDevicesEnabled, viewZonesEnabled }) {
    this.alertsMapModulesLoaded = false;
    this.AlertMap = null;
    this.AlertMapView = null;
    this.onMapLoadCb = onMapLoadCb;
    this.selectedGraphic = null;
    this.store = store;
    this.viewDevicesEnabled = viewDevicesEnabled;
    this.viewZonesEnabled = viewZonesEnabled;
    this.state = {
      showAllUsers: false,
    };

    this.loadModulesAndInitAlertMap();
  }

  async loadModulesAndInitAlertMap() {
    [
      ArcGISMap,
      MapView,
      GraphicsLayer,
      geometryEngine,
      Point,
      Polygon,
      Graphic,
      VectorTileLayer,
      TileLayer,
      ImageryLayer,
      MapImageLayer,
    ] = await loadModules(
      [
        "esri/Map",
        "esri/views/MapView",
        "esri/layers/GraphicsLayer",
        "esri/geometry/geometryEngine",
        "esri/geometry/Point",
        "esri/geometry/Polygon",
        "esri/Graphic",
        "esri/layers/VectorTileLayer",
        "esri/layers/TileLayer",
        "esri/layers/ImageryLayer",
        "esri/layers/MapImageLayer",
      ],
      {
        css: true,
      }
    );

    this.alertsMapModulesLoaded = true;

    this.AlertMap = new ArcGISMap({
      basemap: "satellite",
    });

    const site = this.store.getState().globalState.site;
    const subSite = site?.subSites?.[0];
    const siteName = site?.name.toLowerCase();
    const currentSiteLayer = plotPlanImageLayerJson[siteName];

    if (currentSiteLayer?.show) {
      currentSiteLayer.layers.forEach((index) => {
        const layerType = index.layer;
        this.validateLayerType(layerType, this.AlertMap, index.layerProperties);
      });
    }


    this.AlertMapView = new MapView({
      map: this.AlertMap,
      container: "alerts-map-container",
      popup: {
        collapseEnabled: false,
        actions: [],
        visibleElements: {
          closeButton: true,
        },
        dockOptions: {
          buttonEnabled: false,
          breakpoint: false,
        },
      },
      highlightOptions: {
        color: [255, 255, 0, 1],
        haloOpacity: 0.9,
        fillOpacity: 0.2,
      },
      ...(subSite && {
          center: [subSite.longitude, subSite.latitude],
        }),
      zoom: subSite ? subSite.zoom : 3,
      constraints: {
        maxZoom: site.maxZoom ? site.maxZoom : 3,
        minZoom: site.minZoom ? site.minZoom : 20,
      },
    });

    if (subSite) {
      this.AlertMapView.rotation = subSite.rotation;
    }

    this.AlertMapView.ui.components = [];

    MapUtilsV2.addDefaultWidgets(this.AlertMapView);

    this.AlertMapView.when(() => {
      AlertsMapUtils.addCustomWidgets(this.labelClickCb, this.AlertMapView);

      this.AlertMapView.on("click", async (event) => {
        event.stopPropagation();

        const isOnUserClick = await onUserDeviceClick(
          event,
          this.AlertMapView,
          this.store,
          GRAPHICS_LAYERS_ID
        );

        if (isOnUserClick) {
          return;
        }

        const graphic = MapUtilsV2.getClickSelectedZone({
          event,
          GRAPHICS_LAYERS_ID,
          geometryEngine,
          _map: this.AlertMap,
        });

        if (graphic) {
          this.selectedGraphic = graphic;
          MapUtilsV2.MapWidgetTmpl.updateGraphicLabelActionBttn(graphic, true);
          this.gotToGraphic(graphic);
        }
      });

      if ("ontouchstart" in window) {
      } else {
        pointerMoveEventHandler(this.AlertMapView, this.store, GRAPHICS_LAYERS_ID);
      }

      const AlertMapView = this;
      this.AlertMapView.popup.on("trigger-action", function (event) {
    if (event.action.id === "send-message") { 
      AlertMapView.store.dispatch({
        type: ZONE_REDUCER_TYPES.SET_SEND_MESSAGE_DIALOG_ZONES_ALERTS,
        payload:{
          manufacturerId: event.action.manufacturerId,
          id: event.action.deviceID,
          isAlerts: event.action.isAlerts
      },
      });
      AlertMapView.store.dispatch({
        type: MESSAGES_REDUCER_TYPES.SET_SEND_MESSAGE_ZONES_ALERTS_DIALOG_DISPLAY,
        payload: true,
      });
    }   
});

      this.onMapLoadCb(
        this.renderMap,
        this.store.getState().alertsScreen.zones,
        this.store.getState().alertsScreen.userDevices
      );
    });
  }

  validateLayerType(layerType, mapContext, layerContext) {
    let Layer;
    if (layerType === "ImageryLayer") {
      Layer = new ImageryLayer(layerContext);
    } else if (layerType === "MapImageLayer") {
      Layer = new MapImageLayer(layerContext);
    } else if (layerType === "VectorTileLayer") {
      Layer = new VectorTileLayer(layerContext);
    } else {
      Layer = new TileLayer(layerContext);
    }
    mapContext.add(Layer);
  }

  identifyWithinZoneAndNotWithinZone(unique) {
    if (unique?.length === 1) {
       this.goToSiteLocation(unique[0].device?.status?.location, true);
     }
   }
   filterRecentAlertDevice(activeAlerts) {
     const unique = [
       ...new Map(
         activeAlerts.map((item) => [item.device?.id ? item["device"]["id"] : 0, item])
       ).values(),
     ];
     this.identifyWithinZoneAndNotWithinZone(unique);
   }
 
   goToRecentAlertDevice() {
    const store = this.store.getState();
    const { activeAlerts } = store.globalState?.alerts || [];
     if (activeAlerts.length) {
       this.filterRecentAlertDevice(activeAlerts);
     }
   }

  async gotToGraphic(graphic) {
    try {
      var opts = {
        duration: 1500,
        easing: "ease-out",
      };
      await this.AlertMapView.goTo(
        {
          target: graphic,
        },
        opts
      );
      this.rerenderUserDevices();
    } catch (error) {
      console.log(error);
    }
  }

  labelClickCb = () => {
    this.AlertMapView.goTo({
      zoom: ALERTS_MAP_CONFIG.DEFAULT_ZOOM,
    });
    MapUtilsV2.MapWidgetTmpl.updateGraphicLabelActionBttn(null, false);
    this.selectedGraphic = null;
    this.rerenderUserDevices();
  };

  renderMap = (zonesData, userDevicesData) => {
    if (!this.alertsMapModulesLoaded) {
      return;
    }

    const zonesGraphicsLayer = new GraphicsLayer({
      id: GRAPHICS_LAYERS_ID.ZONES,
    });

    const zonesHeadcountLayer = new GraphicsLayer({
      id: GRAPHICS_LAYERS_ID.HEADCOUNT,
    });

    const zonesLabelsGraphicLayer = new GraphicsLayer({
      id: GRAPHICS_LAYERS_ID.LABELS,
    });

    const userDevicesGraphicLayer = new GraphicsLayer({
      id: GRAPHICS_LAYERS_ID.USER_DEVICES,
    });

    const userDevicesLogGraphicLayer = new GraphicsLayer({
      id: GRAPHICS_LAYERS_ID.USER_DEVICES_LOG,
    });

    this.AlertMap.addMany([
      zonesGraphicsLayer,
      zonesHeadcountLayer,
      zonesLabelsGraphicLayer,
      userDevicesGraphicLayer,
      userDevicesLogGraphicLayer,
    ]);

    this.renderZones(zonesData);
    this.renderUserDevices(userDevicesData);
  };

  resetZonesMapLayer = () => {
    const toClear = [
      GRAPHICS_LAYERS_ID.ZONES,
      GRAPHICS_LAYERS_ID.HEADCOUNT,
      GRAPHICS_LAYERS_ID.LABELS,
    ];

    for (const layerID of toClear) {
      const layer = MapUtilsV2.getLayerById(this.AlertMap, layerID);
      layer && layer.graphics.removeAll();
    }

    AlertsMapUtils.hideAllActionsBttns(false);
  };

  resetUserDevicesMapLayer = async () => {
    const toClearLayers = [GRAPHICS_LAYERS_ID.USER_DEVICES];
    for (const layerID of toClearLayers) {
      const layer = MapUtilsV2.getLayerById(this.AlertMap, layerID);
      layer && layer.graphics.removeAll();
    }

    AlertsMapUtils.hideAllActionsBttns(false);
  };

  async rerenderUserDevices() {
    const selectedGraphic = this.selectedGraphic;
    const layer = MapUtilsV2.getLayerById(this.AlertMap, GRAPHICS_LAYERS_ID.USER_DEVICES);
    const allUserDevices = layer.graphics;

    allUserDevices.forEach((userDeviceGraphic) => {
      const { alerts } = userDeviceGraphic.attributes;
      if (alerts.length === 0) {
        userDeviceGraphic.visible = this.state.showAllUsers;
      } else {
        userDeviceGraphic.visible = true;
      }
    });

    if (selectedGraphic) {
      allUserDevices.forEach((userDeviceGraphic) => {
        if (
          userDeviceGraphic.attributes.zoneEntryTimes &&
          userDeviceGraphic.attributes.zoneEntryTimes[selectedGraphic.attributes.id]
        ) {
          userDeviceGraphic.visible = true;
        } else {
          userDeviceGraphic.visible = false;
        }
      });
    }
  }

  async renderZones(zones) {
    if (!this.alertsMapModulesLoaded) {
      return;
    }

    const zonesGraphicsLayer = MapUtilsV2.getLayerById(this.AlertMap, GRAPHICS_LAYERS_ID.ZONES);
    const zonesHeadcountLayer = MapUtilsV2.getLayerById(
      this.AlertMap,
      GRAPHICS_LAYERS_ID.HEADCOUNT
    );
    const zonesLabelsGraphicLayer = MapUtilsV2.getLayerById(
      this.AlertMap,
      GRAPHICS_LAYERS_ID.LABELS
    );

    if (!zonesGraphicsLayer && !zonesHeadcountLayer && !zonesLabelsGraphicLayer) {
      return;
    }

    this.resetZonesMapLayer();

    zones.forEach(async (zone) => {
      const zoneRings = zone.polygonPoints.map((p) => [p.longitude, p.latitude]);

      const zonePolygon = new Polygon({
        rings: zoneRings,
      });

      let colourWithoutAlpha;
      if (zone.displayColour) {
        colourWithoutAlpha = [
          zone.displayColour["red"],
          zone.displayColour["green"],
          zone.displayColour["blue"],
        ];
      } else {
        colourWithoutAlpha = [255, 0, 0];
      }

      const zoneTextSymbol = {
        text: zone.headcount,
        color: "black",
        yoffset: -4,
        type: "text",
        font: {
          size: 12,
          weight: "bold",
        },
        xoffset: 0,
      };

      const zonePolygonFillSymbol = {
        color: [...colourWithoutAlpha, 0.3],
        style: "solid",
        type: "simple-fill",
        outline: {
          color: {
            r: colourWithoutAlpha[0],
            g: colourWithoutAlpha[1],
            b: colourWithoutAlpha[2],
            a: 1,
          },
          width: 2,
        },
      };

      const pointMarkerSymbol = {
        style: "circle",
        size: 20,
        type: "simple-marker",
        color: [...colourWithoutAlpha, 1],
        outline: {
          width: 0,
        },
      };

      const polygonGraphic = new Graphic({
        geometry: zonePolygon,
        symbol: zonePolygonFillSymbol,
        attributes: {
          ...zone,
          type: ALERTS_MAP_CONFIG.type,
        },
      });

      const point = new Point(polygonGraphic.geometry.centroid);

      polygonGraphic.attributes = {
        ...zone,
        type: ALERTS_MAP_CONFIG.type,
      };

      if (this.viewDevicesEnabled) {
        const pointGraphic = new Graphic({
          geometry: point,
          symbol: pointMarkerSymbol,
        });

        const textGraphic = new Graphic({
          geometry: point,
          symbol: zoneTextSymbol,
        });

        zonesHeadcountLayer.add(pointGraphic);
        zonesLabelsGraphicLayer.add(textGraphic);
      }
      if (this.viewZonesEnabled) {
        zonesGraphicsLayer.add(polygonGraphic);
      }
    });
  }

  async renderUserDevices(userDevices) {
    if (!this.alertsMapModulesLoaded) {
      return;
    }

    const userDevicesGraphicLayer = MapUtilsV2.getLayerById(
      this.AlertMap,
      GRAPHICS_LAYERS_ID.USER_DEVICES
    );

    if (!userDevicesGraphicLayer) {
      return;
    }

    this.resetUserDevicesMapLayer();

    MapUtilsV2.renderUserDevicesOnMap({ userDevices, Point, Graphic, userDevicesGraphicLayer });

    this.AlertMap.addMany([userDevicesGraphicLayer]);

    this.rerenderUserDevices();
  }

  async goToSiteLocation(site, isSpecificDeviceLocation, isAlertLogsLocation = false) {
    if (site?.latitude && site?.longitude) {
      const { latitude, longitude, zoom = 17, rotation = 0 } = site;

      const layer = MapUtilsV2.getLayerById(this.AlertMap, GRAPHICS_LAYERS_ID.USER_DEVICES_LOG);
      layer.visible = false;
      if (isAlertLogsLocation) {
        layer.visible = true;
        const point = new Point({
          latitude: latitude,
          longitude: longitude,
        });

        const pointMarkerSymbol = {
          type: "simple-marker",
          style: "icon",
          size: "10px",
          color: [255, 69, 58],
          outline: {
            color: [255, 255, 255],
            width: 0,
          },
          icon: ICONS.FiberManualRecord,
        };
        const pointGraphic = new Graphic({
          geometry: point,
          symbol: pointMarkerSymbol,
        });
        layer.add(pointGraphic);
      }
      var opts = {
        duration: 1000,
        easing: "ease-in-out",
      };
      if(this.AlertMapView){
      try {
        await this.AlertMapView.goTo(
          {
            center: [longitude, latitude],
            zoom: zoom,
            rotation: !isSpecificDeviceLocation ? rotation : 0,
          },
          opts
        );
      } catch (error) {
        console.log(error);
      }
    }
  }
  }

  showHideLayerLogic(graphicID) {
    const layer = MapUtilsV2.getLayerById(this.AlertMap, graphicID);
    if (layer) {
      layer.visible = !layer.visible;
    }
  }

  showHidePlotPlan(graphicID) {
    this.showHideLayerLogic(graphicID);
  }

  showHideMapImageLayer(graphicID) {
    this.showHideLayerLogic(graphicID);
  }
}
