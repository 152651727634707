import React, { useState, useRef, useEffect, useCallback } from "react";
import { Button, DialogActions, DialogContent, DialogTitle, makeStyles } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { PdfViewer } from "../../../../commons";
import { FONTS } from "../../../../utils";
import { PrivacyOptOutContent } from "./privacy-opt-out-content";
import { UserAPI } from "../../../../api";

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: theme.palette.base.elevation_2,
    padding: "16px",
    margin: "24px 24px 0px 24px",
  },
  content: {
    maxHeight: "50vh",
    display: "flex",
    justifyContent: "center",
    padding: "0px 16px 16px 16px",
    margin: "0px 24px",
    backgroundColor: theme.palette.base.elevation_2,
  },
  actions: {
    margin: "20px 0px",
    padding: "0px",
  },
  buttonContained: {
    ...FONTS.B4_BOLD_14,
    marginRight: "32px",
  },
  buttonOutlined: {
    ...FONTS.B4_BOLD_14,
    marginRight: "36px",
  },
}));

export const PrivacyConsentForm = (props) => {
  const [consentProgress, setConsentProgress] = useState(0);
  const {
    acceptPrivacyConsent,
    declinePrivacyConsent,
    siteLocation,
    siteLocationId,
    isAcknowledgementEnabled,
  } = props;
  const [bttnDisabled, setBttnDisabled] = useState(true);
  const [url, setUrl] = useState("");
  const scrollElementPrivacy = useRef(null);
  const scrollElementOptOut = useRef(null);
  const [isDocumentLoaded, setIsDocumentLoaded] = useState(false);
  const { userId } = useParams();
  const classes = useStyles();

  const getSiteLocation = useCallback(async () => {
    try {
      return await UserAPI.getPrivacyUrl(userId);
    } catch (error) {
      console.error(error);
      return null;
    }
  }, [userId]);

  useEffect(() => {
    getSiteLocation().then((res) => {
      if (res?.urls?.length > 0) {
        const url = res.urls.filter(
          (obj) =>
            parseInt(obj.substring(obj.lastIndexOf("/") + 1).replace(/\.[^/.]+$/, "")) ===
            siteLocationId
        );
        setUrl(url?.length > 0 ? url[0] : "");
      }
    });
  }, [getSiteLocation]);

  useEffect(() => {
    if (isDocumentLoaded) {
      scrollHandlerPrivacyAgreement();
    }
  }, [isDocumentLoaded]);

  function setLoadedDocument() {
    setIsDocumentLoaded(true);
  }

  function scrollHandlerPrivacyAgreement() {
    const elm = scrollElementPrivacy.current;
    if (elm.scrollTop + elm.offsetHeight + 1 >= elm.scrollHeight) {
      setBttnDisabled(false);
    }
  }

  function scrollHandlerPrivacyOptOutAgreement() {
    const elm = scrollElementOptOut.current;
    if (elm.scrollTop + elm.offsetHeight + 1 >= elm.scrollHeight) {
      setBttnDisabled(false);
    }
  }

  function renderCMP() {
    if (consentProgress === 0) {
      return (
        <>
          <DialogTitle className={classes.title}>
            <span style={{ ...FONTS.H5_ShellBold_18 }}>
              Jarvis Privacy Agreement - {siteLocation}
            </span>
          </DialogTitle>
          <DialogContent
            className={classes.content}
            onScroll={scrollHandlerPrivacyAgreement}
            ref={scrollElementPrivacy}
          >
            <PdfViewer file={url} setLoadedDocument={setLoadedDocument} />
          </DialogContent>
          <DialogActions className={classes.actions}>
            {!isAcknowledgementEnabled && (
              <Button
                className={classes.buttonOutlined}
                onClick={() => {
                  setConsentProgress(1);
                  setBttnDisabled(true);
                }}
                color="primary"
                variant="outlined"
              >
                Do Not Accept
              </Button>
            )}
            <Button
              className={classes.buttonContained}
              disabled={bttnDisabled}
              onClick={acceptPrivacyConsent}
              color="primary"
              variant="contained"
            >
              {isAcknowledgementEnabled ? "Acknowledge" : "Accept"}
            </Button>
          </DialogActions>
        </>
      );
    } else {
      return (
        <>
          <DialogTitle className={classes.title}>
            <span style={{ ...FONTS.H5_ShellBold_18 }}>
              Jarvis Privacy Agreement — Risk of Opting Out
            </span>
          </DialogTitle>
          <DialogContent
            className={classes.content}
            onScroll={scrollHandlerPrivacyOptOutAgreement}
            ref={scrollElementOptOut}
          >
            <span tabIndex={-1} style={{ ...FONTS.B5_Reg_12, outline: "none" }}>
              <PrivacyOptOutContent />
            </span>
          </DialogContent>
          <DialogActions className={classes.actions}>
            <Button
              className={classes.buttonOutlined}
              onClick={() => {
                setConsentProgress(0);
                setBttnDisabled(true);
              }}
              color="primary"
              variant="outlined"
            >
              Back
            </Button>
            <Button
              className={classes.buttonContained}
              onClick={declinePrivacyConsent}
              color="primary"
              variant="contained"
            >
              Confirm - Do Not Accept
            </Button>
          </DialogActions>
        </>
      );
    }
  }

  return <div data-testid="privacy-consent-form">{renderCMP()}</div>;
};
