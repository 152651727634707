import { DevicesAPI, SiteLocationsAPI } from "../api";
import { GlobalReducerActions } from "./global-reducer";

export const DEVICES_REDUCER_TYPES = {
  FETCH_DEVICES_STATUS_SUCCESS: "FETCH_DEVICES_STATUS_SUCCESS",
  SET_UNASSIGN_DEVICE_DIALOG_DISPLAY: "SET_UNASSIGN_DEVICE_DIALOG_DISPLAY",
  SET_UNASSIGNED_ASSIGN_DEVICE_DIALOG_DISPLAY: "SET_UNASSIGNED_ASSIGN_DEVICE_DIALOG_DISPLAY",
  SET_ASSIGN_NEW_DEVICE_DIALOG_DISPLAY: "SET_ASSIGN_NEW_DEVICE_DIALOG_DISPLAY",
  SET_SELECTED_DEVICE: "SET_SELECTED_DEVICE",
  SET_QR_CODE_DIALOG_DISPLAY: "SET_QR_CODE_DIALOG_DISPLAY",
  DEVICES_DIALOG_DISPLAY: "DEVICES_DIALOG_DISPLAY",
};

export const DEVICE_INITIAL_STATE = {
  devices: [],
  ListofDevicesDialog: [],
  showListofDevicesDialog: false,
  selected: {
    device: null,
  },
  dialogs: {
    showAssignNewDeviceDialog: false,
    showUnassignDeviceDialog: false,
    showAssignUnassignedDeviceDialog: false,
    showQrDialog: false
  },
};

export const devicesReduer = (_state, action) => {
  const state = _state || DEVICE_INITIAL_STATE;
  const { type, payload } = action;
  switch (type) {
    case DEVICES_REDUCER_TYPES.FETCH_DEVICES_STATUS_SUCCESS:
      if(action.payload?.length){
        return {
          ...state,
          devices: action.payload,
        };
      }

    case DEVICES_REDUCER_TYPES.SET_UNASSIGNED_ASSIGN_DEVICE_DIALOG_DISPLAY:
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          showAssignUnassignedDeviceDialog: payload,
        },
      };

      case DEVICES_REDUCER_TYPES.DEVICES_DIALOG_DISPLAY:
        return {
          ...state,
          ListofDevicesDialog: payload.allDevicesList,
          showListofDevicesDialog:payload.show
        };

    case DEVICES_REDUCER_TYPES.SET_UNASSIGN_DEVICE_DIALOG_DISPLAY:
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          showUnassignDeviceDialog: payload,
        },
      };

    case DEVICES_REDUCER_TYPES.SET_ASSIGN_NEW_DEVICE_DIALOG_DISPLAY:
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          showAssignNewDeviceDialog: payload,
        },
      };

    case DEVICES_REDUCER_TYPES.SET_SELECTED_DEVICE:
      return {
        ...state,
        selected: {
          ...state.selected,
          device: payload,
        },
      };

    case DEVICES_REDUCER_TYPES.SET_QR_CODE_DIALOG_DISPLAY:
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          showQrDialog: payload,
        },
      };

    default:
      return state;
  }
};

// ACTIONS
export const getAllDevices = (showPageLoader = true) => {
  return async (dispatch, getState) => {
    try {
      showPageLoader && dispatch(GlobalReducerActions.showPageLoader());
      const siteID = getState().globalState.siteID;
      const response = await SiteLocationsAPI.getAllDevicesBySiteID(siteID);
      dispatch({ type: DEVICES_REDUCER_TYPES.FETCH_DEVICES_STATUS_SUCCESS, payload: response });
      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
    } catch (error) {
      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
      dispatch(GlobalReducerActions.setApiRequestError(error));
    }
  };
};

const assignDevice = (device, user, showPageLoader = true) => {
  return async (dispatch) => {
    try {
      showPageLoader && dispatch(GlobalReducerActions.showPageLoader());
      await DevicesAPI.assignDevice(device, user);
      dispatch({
        type: DEVICES_REDUCER_TYPES.SET_ASSIGN_NEW_DEVICE_DIALOG_DISPLAY,
        payload: false,
      });

      dispatch({
        type: DEVICES_REDUCER_TYPES.SET_UNASSIGNED_ASSIGN_DEVICE_DIALOG_DISPLAY,
        payload: false,
      });
      showPageLoader && dispatch(DevicesReducerActions.getAllDevices());
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

const unAssignDevice = (showPageLoader = true) => {
  return async (dispatch, getState) => {
    try {
      showPageLoader && dispatch(GlobalReducerActions.showPageLoader());
      const selectedDevice = getState().devicesScreen.selected.device;
      await DevicesAPI.unAssignDevice(selectedDevice);
      dispatch({ type: DEVICES_REDUCER_TYPES.SET_UNASSIGN_DEVICE_DIALOG_DISPLAY, payload: false });
      dispatch(DevicesReducerActions.getAllDevices());
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      dispatch(GlobalReducerActions.hidePageLoader());
    }
  };

};

const setQrCodeDialogDisplay = (instruction) => {
  return async (dispatch) => {
    try {
      dispatch({ type: DEVICES_REDUCER_TYPES.SET_QR_CODE_DIALOG_DISPLAY, payload: instruction });
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
    }
  };
}

export const DevicesReducerActions = {
  getAllDevices,
  assignDevice,
  unAssignDevice,
  setQrCodeDialogDisplay
};
