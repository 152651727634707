import { AxiosClient } from "./axios-client";
import { handleErrorFn } from "../custom-errors";
import { authService } from "../utils/auth-service";

const getMediaById = async (siteId) => {
    try {
      const headers = await authService.getAuthorizeHeader();
      const res = await AxiosClient({
        url: `/api/SiteLocation/${siteId}/Assets`,
        method: "GET",
        headers,
      });
      return res.data;
    } catch (error) {
      throw handleErrorFn(error.response.data);
    }
  };

  export const MediaAPI = {
    getMediaById,
  };