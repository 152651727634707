import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export const UnAssign = (props) => {
  return (
    <SvgIcon>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.82 3H19C20.1 3 21 3.9 21 5V19C21 20.1 20.1 21 19 21H5C3.9 21 3 20.1 3 19V5C3 3.9 3.9 3 5 3H9.18C9.6 1.84
           10.7 1 12 1C13.3 1 14.4 1.84 14.82 3ZM13 4C13 3.45 12.55 3 12 3C11.45 3 11 3.45 11 4C11 4.55 11.45 5 12 5C12.55
            5 13 4.55 13 4ZM15.1943 8L16 8.80571L12.8057 12L16 15.1943L15.1943 16L12 12.8057L8.80571 16L8 15.1943L11.1943 12L8 8.80571L8.80571 8L12 11.1943L15.1943 8Z"
          fill="#FBCE07"
        />
      </svg>
    </SvgIcon>
  );
};
