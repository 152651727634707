import React, {useContext, useState, useEffect } from "react";
import { IconButton, Box, TextField, Button, Tooltip } from "@material-ui/core";
import { ICONS, UserPermissionContext } from "../../../../../../commons";
import { SITES_REDUCER_TYPES } from "../../../../../../reducers";
import { useDispatch } from "react-redux";

export const SiteDetail = (props) => {
  const {
    id,
    currentSubSite,
    subSite,
    updateSite,
    goToSubSite,
    mapSelectedSubSite,
    showMapInfoWidget,
  } = props;
  const [edit, setEdit] = useState(false);
  const dispatch = useDispatch();
  const { Permissions } = useContext(UserPermissionContext);

  let isSaveBttnDisabled = true;

  if (
    currentSubSite &&
    currentSubSite.name &&
    currentSubSite.latitude &&
    currentSubSite.longitude &&
    currentSubSite.zoom > 2
  ) {
    isSaveBttnDisabled = false;
  }

  useEffect(() => {
    if (currentSubSite === null) {
      setEdit(false);
    }
  }, [currentSubSite, subSite]);

  return (
    <>
      {edit ? (
        <Box>
          <Box display="flex" justifyContent="space-between" pt={3}>
            <TextField
              label={"Name of Site Area"}
              value={currentSubSite?.name}
              onChange={(event) => {
                dispatch({
                  type: SITES_REDUCER_TYPES.SET_CURRENT_SUBSITE,
                  payload: {
                    ...currentSubSite,
                    id,
                    name: event.target.value,
                  },
                });
              }}
              type="text"
              variant="filled"
              fullWidth
            />
          </Box>

          <Box display="flex" justifyContent="space-between" pt={3}>
            <TextField
              label="Latitude"
              onChange={(event) => {
                dispatch({
                  type: SITES_REDUCER_TYPES.SET_CURRENT_SUBSITE,
                  payload: {
                    ...currentSubSite,
                    id,
                    latitude: parseFloat(event.target.value, 10),
                  },
                });
              }}
              type="number"
              variant="filled"
              value={currentSubSite?.latitude}
            />
            <TextField
              label="Longitude"
              onChange={(event) => {
                dispatch({
                  type: SITES_REDUCER_TYPES.SET_CURRENT_SUBSITE,
                  payload: {
                    ...currentSubSite,
                    id,
                    longitude: parseFloat(event.target.value, 10),
                  },
                });
              }}
              type="number"
              variant="filled"
              value={currentSubSite?.longitude}
            />

            <TextField
              label="Zoom"
              onChange={(event) => {
                dispatch({
                  type: SITES_REDUCER_TYPES.SET_CURRENT_SUBSITE,
                  payload: {
                    ...currentSubSite,
                    id,
                    zoom: parseInt(event.target.value, 10),
                  },
                });
              }}
              type="number"
              variant="filled"
              value={currentSubSite?.zoom}
            />
          </Box>
          <Box pt={3} alignSelf="flex-end">
            <Button
              disabled={isSaveBttnDisabled}
              onClick={() => {
                updateSite(currentSubSite);
              }}
              variant="outlined"
              color="primary"
            >
              SAVE
            </Button>
          </Box>
        </Box>
      ) : (
        <Box pt={2}>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div>{subSite.name}</div>
            <div>
              <Tooltip title="View on Map">
                <IconButton
                  aria-label="View On Map"
                  size="medium"
                  onClick={() =>
                    dispatch({
                      type: SITES_REDUCER_TYPES.SET_MAP_SELECTED_SUBSITE,
                      payload: subSite,
                    })
                  }
                  {...(mapSelectedSubSite &&
                    mapSelectedSubSite.name === subSite.name && {
                      color: "primary",
                    })}
                >
                  <ICONS.Visibility fontSize="inherit" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Edit">
                <IconButton
                  disabled={showMapInfoWidget || !Permissions.Manage_Sites}
                  aria-label="Edit"
                  size="medium"
                  onClick={() => {
                    setEdit(true);
                    goToSubSite({ ...subSite, id: id });
                    dispatch({
                      type: SITES_REDUCER_TYPES.SET_MAP_ENABLE_DOUBLE_CLICK,
                      payload: true,
                    });
                    dispatch({
                      type: SITES_REDUCER_TYPES.SET_SHOW_ADD_NEW_SITE_BTTN,
                      payload: false,
                    });
                    dispatch({
                      type: SITES_REDUCER_TYPES.SET_SHOW_MAP_INFO_WIDGET,
                      payload: true,
                    });
                  }}
                >
                  <ICONS.Edit fontSize="inherit" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Remove">
                <IconButton
                  disabled={!Permissions.Manage_Sites}
                  aria-label="Remove"
                  size="medium"
                  onClick={() =>
                    dispatch({
                      type: SITES_REDUCER_TYPES.SET_REMOVE_SUBSITE_DIALOG_DISPLAY,
                      payload: {
                        show: true,
                        id,
                      },
                    })
                  }
                >
                  <ICONS.Delete fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </div>
          </div>
          <div>
            {subSite.latitude}, {subSite.longitude}
          </div>
        </Box>
      )}
    </>
  );
};
