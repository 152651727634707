import { CustomError } from "./custom-error";

export class BadRequest extends CustomError {
  statusCode = 400;
  constructor(message, errors) {
    super(message);
    this.errors = errors;
    Object.setPrototypeOf(this, BadRequest.prototype);
  }

  serializeErrors() {
    var arr = [];
    if (typeof this.errors === "string") {
      arr.push({
        field: "",
        message: this.errors,
      });
    } else {
      for (const i in this.errors) {
        arr.push({
          field: i,
          message: this.errors[i],
        });
      }
    }

    return arr;
  }
}
