import { makeStyles } from "@material-ui/core";
import { FONTS } from "../../utils";
import { fade } from "@material-ui/core/styles/colorManipulator";

export const useCommonStyles = makeStyles((theme) => ({
  page_wrapper: {
    flex: 1,
    display: "flex",
    paddingTop: "32px",
    paddingLeft: "24px",
    paddingRight: "24px",
    flexDirection: "column",
  },
  page_header: {
    marginBottom: "35px",
    display: "flex",
    justifyContent: "space-between",
  },
  page_title: {
    ...FONTS.H4_ShellBold_24,
  },
  page_subtitle: {
    ...FONTS.Label1_ShellBook_24,
  },
  formPaper: {
    width: "100%",
    height: "100%",
    background: "#2b2b36",
    padding: "24px",
    overflow: "scroll",
    [theme.breakpoints.down("sm")]: {
      // tablets
      height: "40vh",
      marginTop: "10px",
    },
  },
  //TABS COMPONENT
  tabs_wrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: theme.palette.background.default,
  },
  tabs_indicator: {
    background: "transparent",
  },
  tabs_root: {
    background: fade(theme.palette.base.elevation_1, 0.5),
  },
  tab_root: {
    textTransform: "none",
    ...FONTS.B2_Reg_16,
  },
  tab_selected: {
    background: theme.palette.base.elevation_1,
    ...FONTS.H7_BOLD_16,
  },
  ellipses: {
    whiteSpace: "no-wrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));
