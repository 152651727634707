import React from "react";
import {
    makeStyles,
    Dialog,
    DialogTitle,
    Button,
    Box
} from "@material-ui/core";
import { FONTS } from "../../utils";
import { useDialogStyles } from "../../commons";
import { QR } from "../qrcode";

const useStyles = makeStyles(() => ({
    dialogContainer: {
        padding: "8px 0px",
        position: "absolute",
        width: "448px",
        height: "488px",
        left: "34.44%",
        right: "34.44%",
        top: "131px",
        background: "#2B2B36",
        boxShadow: "0px 15px 12px rgba(0, 0, 0, 0.22), 0px 19px 38px rgba(0, 0, 0, 0.3)",
        borderRadius: "4px",
    },
    qrBox: {
        margin: "0 auto"
    },
    qrTitle: {
        fontSize: "18px",
        fontWeight: "bold",
        marginBottom: "44px",
        paddingLeft: "24px",
        paddingTop: "12px",
        lineHeight: "120%",
        letterSpacing: "0.02em",
        ...FONTS.H5_ShellBold_18
    },
    qrCode: {
        padding: "26px",
        backgroundColor: "#FFF",
    },
    qrExpireMessage: {
        textAlign: "center",
        fontSize: "14px",
        color: "#D9D9D9",
        letterSpacing: "0.02em",
        lineHeight: "19px",
        fontWeight: "400"
    },
    buttonsContainer: {
        marginTop: "50px"
    }
}));

function setQrExpiryOrGenerationMessage(expiryDate) {
    let message;
    if (expiryDate) {
        message = `QR Code expires ${expiryDate}`;
    } else {
        message = "QR Code generation in progress";
    }
    return message;
}


export const QRCodeDisplay = ({
    show,
    title = "",
    expiryDate = "",
    url,
    onCancel,
}) => {
    const classes = useStyles();
    const dialogStyles = useDialogStyles();

    return (
        <Dialog open={show} onClose={onCancel} PaperProps={{ className: classes.dialogContainer }}>
            <div className={classes.qrTitle}>{title}</div>
            <Box className={classes.qrBox}>
                <DialogTitle className={dialogStyles.dialogTitle} disableTypography onClose={onCancel}>
                    <QR url={url} size="195"/>
                </DialogTitle>
                <div className={classes.qrExpireMessage}>{setQrExpiryOrGenerationMessage(expiryDate)}</div>
            </Box>
            <div className={classes.buttonsContainer}>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                        onCancel()
                    }}
                    style={{
                        marginLeft: "10px",
                        marginRight: "30px",
                        float: "right"
                    }}
                >
                    Done
                </Button>
            </div>
        </Dialog>
    );

};
