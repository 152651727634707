import React from "react";
import * as ReactDOM from "react-dom";
import { ICONS } from "../../icon-manager/index";
import { parseUtcToLocal } from "../../../utils";

export const renderMapTooltipMarkupMediaAssets = (graphicsData, store, mapView) => {

  const mediaFiles = graphicsData.graphic.attributes;
  mapView.popup.location = graphicsData.mapPoint;

  // Global div references
  var listDiv = document.createElement("div");
  var previewDiv = document.createElement("div");

  function showMediaFiles() {
    listDiv.innerHTML = ""; // Clear previous content
    previewDiv.innerHTML = ""; // Clear any previous preview
    mediaFiles.media.forEach(function (attachment) {
      var container = document.createElement("div");
      container.className = "arcgis-popup-item-container";

      var link = document.createElement("a");
      link.href = "javascript:void(0)";
      link.className = "arcgis-popup-item";
      previewDiv.className = "arcgis-popup-container-wrap";

      // Create and append an icon element based on attachment type
      var icon = document.createElement("span");
      icon.className = "arcgis-popup-icon";
      if (attachment.type === "Image") {
        var photoIcon = React.createElement(ICONS.ImageIcon);
        ReactDOM.render(photoIcon, icon);
      } else if (attachment.type === "Video") {
        var playIcon = React.createElement(ICONS.PlayCircleFilledIcon);
        ReactDOM.render(playIcon, icon);
      }
      icon.style.color = "red";
      icon.style.marginRight = "8px";

      var containerFlex = document.createElement("div");
      containerFlex.style.display = "flex";
      containerFlex.style.justifyContent = "space-between";
      containerFlex.style.alignItems = "center";
      containerFlex.style.cursor = "pointer";

      var textContainer = document.createElement("div");
      textContainer.style.display = "flex";
      textContainer.style.flexDirection = "column";
      var name = document.createElement("span");
      name.innerText = attachment.fileName;
      name.style.color = "black";
      name.style.fontWeight = "bold";
      name.style.cursor = "pointer";

      // Create and append a timestamp element
      var timestamp = document.createElement("span");
      timestamp.innerText = parseUtcToLocal(attachment.createdTimestamp);
      timestamp.style.fontSize = "10px"; // Adjust font size as needed
      timestamp.style.color = "grey";

      textContainer.appendChild(name);
      textContainer.appendChild(timestamp);

      containerFlex.appendChild(icon);
      containerFlex.appendChild(textContainer);
      link.appendChild(containerFlex);

      container.appendChild(link);

      link.addEventListener("click", function (e) {
        e.preventDefault(); // Prevent default action

        listDiv.style.display = "none";
        previewDiv.innerHTML = ""; // Clear previous preview
        // Button Container for holding both back and fullscreen buttons
        var buttonContainer = document.createElement("div");
        buttonContainer.className = "arcgis-popup-button-container";

        // Add back button
        var backButton = document.createElement("button");
        var backIcon = React.createElement(ICONS.KeyboardBackspaceIcon);
        ReactDOM.render(backIcon, backButton);
        backButton.className = "arcgis-popup-back";
        backButton.addEventListener("click", function () {
          previewDiv.style.display = "none";
          listDiv.style.display = "block";
        });
        buttonContainer.appendChild(backButton);

        // Fullscreen button
        var fullscreenButton = document.createElement("button");
        var fullScreenIcon = React.createElement(ICONS.FullscreenIcon);
        ReactDOM.render(fullScreenIcon, fullscreenButton);
        fullscreenButton.className = "arcgis-popup-fullscreen";
        fullscreenButton.addEventListener("click", function () {
          if (document.fullscreenElement) {
            document.exitFullscreen();
          } else {
            previewDiv.requestFullscreen();
          }
        });
        buttonContainer.appendChild(fullscreenButton);
        previewDiv.appendChild(buttonContainer);

        if (attachment.type === "Image") {
          var img = document.createElement("img");
          img.src = attachment.assetUrl;
          img.className = "arcgis-popup-img";
          previewDiv.appendChild(img);
        } else if (attachment.type === "Video") {
          var videoElement = document.createElement("Video");
          videoElement.controls = false;
          videoElement.className = "arcgis-popup-video";

          var sourceElement = document.createElement("source");
          sourceElement.src = attachment.assetUrl;
          videoElement.appendChild(sourceElement);
          videoElement.setAttribute("controlsList", "nodownload");
          videoElement.controls = true;

          previewDiv.appendChild(videoElement);
        }

        previewDiv.style.display = "block";
      });

      listDiv.appendChild(container);
    });

    var parentContainer = document.createElement("div");
    parentContainer.appendChild(listDiv);
    parentContainer.appendChild(previewDiv);
    parentContainer.className = ".arcgis-popup-container-parent";
    return parentContainer;
  }

  // Scoped CSS
  var styles = `
 
  .arcgis-popup-container-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 80vh; 
    overflow-y: hidden; 
}
 
 
 .arcgis-popup-video, .arcgis-popup-image {
    max-width: 100%;
    max-height: 100%;
}
 
.arcgis-popup-item {
    display: block;
    margin-bottom: 8px;
    text-decoration: none;
}

.arcgis-popup-item-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

 
.arcgis-popup-back {
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
  margin-bottom: 8px;
  outline: none;
  color:grey;
}
 
 
.arcgis-popup-img {
    width: 100%;
    height: auto;
    max-height: 75%;
    border-radius: 4px;
    display: block;
    margin: 0 auto;
}
 
.arcgis-popup-video {
    width: 100%;
    height: 75%;
    border: none;
}
 
.arcgis-popup-button-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}
 
.arcgis-popup-fullscreen {
  background: none;
  font-size: 1.2em;
  cursor: pointer;
  padding: 5px 8px;
  border-radius: 4px;
  outline: none;
  border: none;
  color:grey;
}

.arcgis-popup-fullscreen:hover {
  color: black;
}

.arcgis-popup-back:hover {
  color: black;
}

.arcgis-popup-item-container:hover {
  background-color: #f3f2f2;
}

.arcgis-popup-container-parent{
  max-height:80vh;
  overflow-y:hidden;
}

.esri-component.esri-popup .esri-popup__main-container.esri-widget {
  width: auto;
}‍‍‍‍‍‍
 
`;

  var styleSheet = document.createElement("style");
  styleSheet.type = "text/css";
  styleSheet.innerText = styles;
  document.head.appendChild(styleSheet);

  mapView.popup.title = "Captured files";
  mapView.popup.content = showMediaFiles();
};
