import { AxiosClient } from "./axios-client";
import { handleErrorFn } from "../custom-errors";
import { authService } from "../utils/auth-service";

const URL = `/api/user`;

const getSitelocationUsersByQueryString = async (siteId, queryString) => {
  const headers = await authService.getAuthorizeHeader();
  try {
    const res = await AxiosClient({
      url: `/api/siteLocation/${siteId}/User?${queryString}`,
      method: "GET",
      headers: headers,
    });
    return res.data;
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

const createUser = async (user) => {
  const headers = await authService.getAuthorizeHeader();
  try {
    return await AxiosClient({
      url: URL,
      method: "POST",
      data: user,
      headers: headers,
    });
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

const updateUser = async (userData) => {
  const headers = await authService.getAuthorizeHeader();
  const { id } = userData;
  try {
    return await AxiosClient({
      url: `${URL}/${id}`,
      method: "PUT",
      data: userData,
      headers: headers,
    });
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

const getUserById = async (userId) => {
  const headers = await authService.getAuthorizeHeader();
  try {
    const res = await AxiosClient({
      url: `${URL}/${userId}`,
      method: "GET",
      headers: headers,
    });
    return res.data;
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

const deleteUser = async (userId, siteId) => {
  const headers = await authService.getAuthorizeHeader();
  try {
    const res = await AxiosClient({
      url: `/api/SiteLocation/${siteId}/User/${userId}`,
      method: "DELETE",
      headers: headers,
    });
    return res.data;
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

const deleteRole = async (roleId, token) => {
  const headers = await authService.getAuthorizeHeader();
  try {
    const res = await AxiosClient({
      url: `/api/userrole/${roleId}`,
      method: "DELETE",
      headers: headers,
    });
    return res.data;
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

const getUserCategoriesSummary = async (siteID) => {
  const headers = await authService.getAuthorizeHeader();
  try {
    const res = await AxiosClient({
      url: `/api/SiteLocation/${siteID}/UserCategory/Summary`,
      method: "GET",
      headers: headers,
    });
    return res.data;
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

const createRole = async (roleData) => {
  const headers = await authService.getAuthorizeHeader();
  try {
    const res = await AxiosClient({
      url: `/api/UserRole`,
      method: "POST",
      data: roleData,
      headers: headers,
    });
    return res.data;
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

const updateRole = async (roleData) => {
  const headers = await authService.getAuthorizeHeader();
  try {
    const res = await AxiosClient({
      url: `/api/UserRole/${roleData.id}`,
      method: "PUT",
      data: roleData,
      headers: headers,
    });
    return res.data;
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

const getOptInStatus = async (id) => {
  try {
    const res = await AxiosClient({
      url: `/api/User/${id}/optInStatus`,
      method: "GET",
    });
    return res.data;
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

const updateOptInStatus = async (data) => {
  try {
    const res = await AxiosClient({
      url: `/api/User/${data.id}/optInStatus`,
      method: "PUT",
      data: data,
    });
    return res.data;
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

const sendPrivacyLink = async (email) => {
  try {
    const res = await AxiosClient({
      url: `/api/user/sendPrivacyLink/${email}`,
      method: "POST",
      data: {},
    });
    return res.data;
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

const getPrivacyUrl = async (id) => {
  try {
    const res = await AxiosClient({
      url: `/api/User/${id}/PrivacyFormUrl`,
      method: "GET",
    });
    return res.data;
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

const getOtpToken = async (id, uuid) => {
  try {
    const res = await AxiosClient({
      url: `/api/User/${id}/OtpToken?uuid=${uuid}`,
      method: "POST",
    });
    return res.data;
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
}

export const UserAPI = {
  getSitelocationUsersByQueryString,
  getUserById,
  createUser,
  createRole,
  deleteUser,
  updateUser,
  getUserCategoriesSummary,
  deleteRole,
  updateRole,
  getOptInStatus,
  updateOptInStatus,
  sendPrivacyLink,
  getPrivacyUrl,
  getOtpToken
};
