import React from "react";
import { Route, Redirect } from "react-router-dom";

export const PrivateRoute = (props) => {
  const { component, isLoggedIn, auth, ...otherProps } = props;
  return auth.isLoggedIn ? (
    <Route exact component={component} render={undefined} {...otherProps} />
  ) : (
    <Redirect to={{ pathname: "/" }} />
  );
};
