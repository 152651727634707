import React, {useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, IconButton, TextField } from "@material-ui/core";
import { ICONS, useCommonStyles, UserPermissionContext } from "../../../../../../commons";
import { SitesReducerActions, SITES_REDUCER_TYPES } from "../../../../../../reducers";

export const EditSiteName = (props) => {
  const { currentSite } = props;
  const commonStyles = useCommonStyles();
  const [edit, setEdit] = useState(false);
  const dispatch = useDispatch();
  const { Permissions } = useContext(UserPermissionContext);

  return (
    <Box
      className={commonStyles.page_header}
      style={{ justifyContent: "flex-start", alignItems: "center" }}
    >
      {edit ? (
        <>
          <TextField
            variant="filled"
            id="site-name-input"
            label="Site Name"
            value={currentSite?.name}
            onChange={(event) =>
              dispatch({
                type: SITES_REDUCER_TYPES.SET_CURRENT_SITE,
                payload: {
                  ...currentSite,
                  name: event.target.value,
                },
              })
            }
          />
          <IconButton
            aria-label="save"
            title="Save"
            color="primary"
            size="medium"
            onClick={() =>
              dispatch(SitesReducerActions.updateSiteConfig()).then(() => {
                setEdit(false);
              })
            }
            style={{ marginLeft: "10px" }}
          >
            <ICONS.Save />
          </IconButton>
        </>
      ) : (
        <>
          <span className={commonStyles.page_title} data-testid="page-title">
            {currentSite?.name}
          </span>
          {Permissions.Manage_Sites && (
          <IconButton
            aria-label="edit"
            color="primary"
            size="medium"
            onClick={() => setEdit(true)}
            style={{ marginLeft: "10px" }}
            title="Edit"
          >
            <ICONS.Edit />
          </IconButton>)}
        </>
      )}
    </Box>
  );
};
