import React from "react";
import {useDispatch, useSelector } from "react-redux";
import { makeStyles, Dialog, DialogTitle, FormControl, Button, Box, DialogContent, Tooltip, Grid } from "@material-ui/core";
import { useDialogStyles, ICONS } from "../../../commons";
import { DEVICES_REDUCER_TYPES } from "../../../reducers";
import { withStyles } from "@material-ui/core/styles";

const cssProperties = {
    filledVariant: "filled",
    primaryColor: "primary",
  };
  const useStyles = makeStyles((theme) => ({
    formControl: {
      width: "100%",
      marginBottom: "32px",
    },
    formControlActions: {
      flexDirection: "row",
      justifyContent: "flex-end",
    },
    form: {
      display: "flex",
      flexDirection: "column",
    },
    divDevicelist: {
      overflowY: "scroll",
      height: "200px"
    },
    deviceID: {
      display: "inline-block",
      width: "75%",
    },
    deviceListTitle: {
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "16px",
      lineHeight: "100%",
      letterSpacing: "0.02em",
      marginBottom: "16px"
    },
    deviceListItem: {
      fontSize: "16px",
      lineHeight: "120%",
      marginBottom: "12px",
      letterSpacing: "0.02em"
    }
  }));


  const deviceTypeTooltip = withStyles({
    tooltip: {
      backgroundColor: "#464659",
      fontSize: "14px",
      lineHeight: "100%",
      margin: "12px 0px"
    }
  })(Tooltip);

  function renderDeviceType(device) {
    let icon;
    switch (device) {
        case "Apple":
            icon = <ICONS.PhoneIphoneIcon />
            break;
        case "Android":
            icon = <ICONS.PhoneAndroidIcon />
            break;
        case "Blackline":
            icon = <ICONS.PersonPinCircleIcon />
            break;
        default:
          icon = null;
          break;
      }
      return icon;
  }

  const dialogContentDeviceInfo = (classes,devicesList,count,title)=>{
    const devicesCount =count ? `(${count})` : `(0)`;
    return(
    <Grid item xs={12}>
        <div className={classes.deviceListTitle}>{title} {devicesCount}:</div>
          {devicesList && devicesList != null && devicesList.length > 0 ?
            <div className={classes.divDevicelist}>
              {devicesList.map((user) => {
                const devicesModel = user.receiverDevice?.model ? `${user.receiverDevice?.manufacturer} - ${user.receiverDevice?.model}` : user.receiverDevice?.manufacturer;
                return <div className={classes.deviceListItem} key={user.id}>
                  <div className={classes.deviceID}>
                    {user.receiverDevice?.manufacturerId}
                  </div>
                <deviceTypeTooltip title={devicesModel} placement="top">
                {renderDeviceType(user.receiverDevice?.manufacturer)}
                </deviceTypeTooltip>
                </div>;
              })
              }
            </div> : <div> NA </div>}
         </Grid>
    );
  }

export const DevicesDialog = (props) => {
    const {show, onCancel} = props;
    const dialogStyles = useDialogStyles();
    const dispatch = useDispatch();
    const rowData = useSelector((state) => state.devicesScreen?.ListofDevicesDialog ?? []);

    const devicesList = rowData?.deviceMessages;
    const acknowledgedCount = rowData?.acknowledgedCount;
    const acknowledgedDevices =devicesList?.length && devicesList.filter((device)=>device.ackTimestamp);
    const unAcknowledgedDevices =devicesList?.length && devicesList.filter((device)=>!device.ackTimestamp);

  const classes = useStyles();

    if (!show) {
        return null;
    }

    return (
        <Dialog open={show} onClose={onCancel} >
        <Box className={dialogStyles.dialogContentWrapper}>
          <DialogTitle className={dialogStyles.dialogTitle} disableTypography onClose={onCancel}>
            <span>Devices Acknowledgement Information</span>
          </DialogTitle>
          <DialogContent>
          <Grid container spacing={2}>
            {dialogContentDeviceInfo(classes,acknowledgedDevices,acknowledgedCount,"Acknowledged Devices ")}
            {dialogContentDeviceInfo(classes,unAcknowledgedDevices,unAcknowledgedDevices?.length,"UnAcknowledged Devices ")}
              </Grid>
          </DialogContent>
            <form className={classes.form} autoComplete="off" >
                <FormControl
                required
                variant={cssProperties.filledVariant}
                className={classes.formControlActions}
                >
              <Button
                type="submit"
                variant="contained"
                color={cssProperties.primaryColor}
                style={{ marginLeft: "10px" }}
                className={dialogStyles.dialogActionBttn}
                data-testid="create-bttn"
                onClick={() =>
                dispatch({type: DEVICES_REDUCER_TYPES.DEVICES_DIALOG_DISPLAY, payload: {allDevicesList:[],show:false}})
                }
              >
                Cancel
              </Button>
            </FormControl>
          </form>
        </Box>
      </Dialog>
    );
};
