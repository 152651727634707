import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  TableBody,
  TableFooter,
  TableContainer,
  TablePagination,
  Button,
  Paper,
} from "@material-ui/core";

import { Row } from "./row";
import {
  useCustomTableHook,
  useCustomTableStyles,
  EnhancedTableHead,
  UserPermissionContext,
  ICONS,
  DeviceIdWithBatteryIcon,
  DeviceIdWithBatteryIconTooltip,
  useDebounce,
} from "../../../../../commons";
import { DEVICES_REDUCER_TYPES } from "../../../../../reducers";
import { gasTypes, parseUtcToLocal, setGasTypeEnabled } from "../../../../../utils";

const headCellMap = {
  manufacturerId: "manufacturerId",
  deviceType: "manufacturer",
  beaconID: "status.beacon.manufacturerId",
  gasType: "status.sensors",
  contractCompanyName: "currentAssignedUser.company",
  userCategory: "currentAssignedUser.userCategory.name",
  currentAssignedUserId: "currentAssignedUser.id",
  userType: "currentAssignedUser.userType",
  assignmentTimestamp: "assignmentTimestamp",
  currentAssignedUserRole: "currentAssignedUser.role.role",
  width10: "10%",
};

function getHeadCells({ showUserRoles }, deviceConfiguration) {
  return [
    {
      id: headCellMap.manufacturerId,
      sortBy: headCellMap.manufacturerId,
      label: "Device ID",
      width: "11%",
      content: (row) => (
        <DeviceIdWithBatteryIcon
          manufacturerId={row.manufacturerId}
          level={row.status?.battery.internalLevel}
          isOnline={row.status?.isOnline}
        />
      ),
      toolTipContent: (row) =>
        row.manufacturerId ? (
          <DeviceIdWithBatteryIconTooltip
            manufacturerId={row.manufacturerId}
            level={row.status?.battery.internalLevel}
            isOnline={row.status?.isOnline}
          />
        ) : null,
      showEllipses: true,
      copyCellTextFn: (row) => row.manufacturerId,
    },
    {
      id: headCellMap.deviceType,
      sortBy: headCellMap.deviceType,
      label: "Device Type",
      width: "11%",
      content: (row) => row?.model ? `${row.manufacturer} - ${row.model}` : row.manufacturer,
    },
    {
      id: headCellMap.beaconID,
      sortBy: headCellMap.beaconID,
      label: "Beacon ID",
      width: "9.5%",
      content: (row) => row.status?.beacon?.manufacturerId,
    },
    ...(deviceConfiguration ?
      [{
        id: headCellMap.gasType,
        sortBy: headCellMap.gasType,
        label: "Gas Type",
        width: "15%",
        content: (row) => gasTypes(row.status?.sensors, true),
      }] : []),
    {
      id: headCellMap.contractCompanyName,
      sortBy: headCellMap.contractCompanyName,
      label: "Company",
      width: "10%",
      content: (row) => row.currentAssignedUser?.company,
    },
    {
      id: headCellMap.currentAssignedUserId,
      sortBy: headCellMap.currentAssignedUserId,
      label: "User ID",
      width: "5%",
      content: (row) => row.currentAssignedUser?.id,
      showEllipses: true,
      copyCellTextFn: (row) => row.currentAssignedUser?.id,
    },
    {
      id: headCellMap.userCategory,
      sortBy: headCellMap.userCategory,
      label: "User Category",
      width: "12%",
      content: (row) => row.currentAssignedUser?.userCategory?.name,
    },
    {
      id: headCellMap.assignmentTimestamp,
      sortBy: headCellMap.assignmentTimestamp,
      label: "Date Assigned",
      width: headCellMap.width10,
      content: (row) => parseUtcToLocal(row.assignmentTimestamp),
    },
  ]
};

export const AssignedDevicesTable = (props) => {
  const dispatch = useDispatch();
  const tableClasses = useCustomTableStyles();
  const [tableSearchStr, setTableSearchStr] = useState("");
  const [tableData, setTableData] = useState([]);
  const { Permissions } = useContext(UserPermissionContext);
  const showUserRoles = Permissions.View_Roles
  const deviceConfiguration = useSelector((state) => state.globalState.site?.deviceConfiguration);

  const headCells = getHeadCells({ showUserRoles }, setGasTypeEnabled(deviceConfiguration));

  const {
    order,
    orderBy,
    page,
    rowsPerPage,
    setPage,
    getComparator,
    stableSort,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
  } = useCustomTableHook({ defaultSortBy: headCells[0].sortBy, rowsPerPage: 10 });

  const { data, ...otherProps } = props;

  useEffect(() => {
    setTableData(data);
  }, [data]);

  const assignDeviceTableSearchDebounced = useDebounce((_nextVal) => {
    setPage(0);
    setTableSearchStr(_nextVal);
  }, 300);

  useEffect(() => {
    let arr = [];

    if (tableSearchStr) {
      arr = data.filter(
        (tData) =>{
          return(
          tData?.manufacturerId.toLowerCase().includes(tableSearchStr.toLowerCase()) ||
          (tData?.status?.beacon && tData.status.beacon.manufacturerId &&
             tData.status.beacon.manufacturerId.toLowerCase().includes(tableSearchStr.toLowerCase()))
          )
        }
      );
    } else {
      arr = data;
    }

    setTableData(arr);
  }, [tableSearchStr, setTableData, data]);

  return (
    <Paper>
      <TableContainer className={tableClasses.tableContainer}>
        <Table
          aria-labelledby="tableTitle"
          aria-label="assigned devices table"
          stickyHeader
          data-testid="assigned-devices-table"
          classes={{
            root: tableClasses.tableRoot,
          }}
        >
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={headCells}
            toShow={true}
            tableWithRowActions={true}
            onChangeSearch={(event) => {
              assignDeviceTableSearchDebounced(event.target.value);
            }}
            searchLabel={"Device ID or Beacon ID"}
          />
          <TableBody data-testid="assigned-devices-table-body">
            {stableSort(tableData, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((rowData, index) => {
                return (
                  <Row
                    key={`${rowData}-${index}-row`}
                    rowData={rowData}
                    headCells={headCells}
                    {...otherProps}
                  />
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TableFooter className={tableClasses.tabelFooterRoot} component="div">
        {Permissions.Manage_Devices ? (
          <Button
            startIcon={<ICONS.Add />}
            color="primary"
            onClick={() =>
              dispatch({
                type: DEVICES_REDUCER_TYPES.SET_ASSIGN_NEW_DEVICE_DIALOG_DISPLAY,
                payload: true,
              })
            }
            data-testid="assign-new-device-bttn"
          >
            Assign New Device to User
          </Button>
        ) : (
          <div></div>
        )}

        <TablePagination
          classes={{
            root: tableClasses.tablePaginationRoot,
          }}
          component="div"
          rowsPerPageOptions={[]}
          labelRowsPerPage=""
          count={tableData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableFooter>
    </Paper>
  );
};