import React, { useState, useContext } from "react";
import {
  FormControlLabel,
  FormGroup,
  RadioGroup,
  Button,
  makeStyles,
  Tooltip,
  TextField,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { YellowSwitch } from "../../../../commons/switch";
import { YellowRadio } from "../../../../commons/radio-button";
import { YellowCheckbox, DisabledYellowCheckbox } from "../../../../commons/checkbox";
import { UploadPdfDialog } from "../../components/dialogs";
import { FONTS } from "../../../../utils";
import {
  ICONS,
  InfoDialog as DataPrivacyInfoDialog,
  UserPermissionContext,
} from "../../../../commons";
import {
  SITES_REDUCER_TYPES,
  SitesReducerActions,
  GLOBAL_REDUCER_TYPES,
} from "../../../../reducers";
import { pdfConsentContent } from "./pdf-consent-content";

const spaceEvenly = "space-evenly";

const useStyles = makeStyles((theme) => ({
  formContainer1: {
    paddingTop: "25px",
    paddingBottom: "25px",
    [`${theme.breakpoints.down("md")} and (orientation: landscape)`]: {
      paddingBottom: "5px",
      paddingTop: "5px",
    },
  },
  formContainer: {
    display: "flex",
    justifyContent: spaceEvenly,
    marginBottom: "15px",
  },
  formContentContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    overflowY: "auto",
  },
  formGroup: {
    display: "flex",
  },
  formLabel1: {
    ...FONTS.Button1_Bold_14,
  },
  formLabel2: {
    marginBottom: "17px",
    ...FONTS.Label2_ShellBold_12,
  },
  uploadButton: {
    color: "#FBCE07",
    width: "50%",
    ...FONTS.Button1_Bold_16,
  },
  checkboxContainer: {
    marginRight: "60px",
  },
  icons: {
    cursor: "pointer",
    marginLeft: "5px",
  },
  iconContainer: {
    display: "flex",
    aliginItems: "center",
  },
  pdfContainer: {
    display: "flex",
    alignItems: "center",
    ...FONTS.Button1_Bold_14,
  },
  pdfText: {
    marginRight: "20px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    margin: "10px",
    color: "#FBCE07",
    border: "1px solid",
    ...FONTS.Button1_Bold_16,
  },
  primaryButton: {
    margin: "10px",
    backgroundColor: "#FBCE07",
    ...FONTS.Button1_Bold_16,
  },
  disclaimerTickerButton: {
    margin: "10px",
    backgroundColor: theme.palette.primary.main,
    ...FONTS.Button1_Bold_16,
    float: "right",
  },
}));

function footerBttns({
  classes,
  navigateToSites,
  disClaimerTicker,
  currentSite,
  dispatch,
  privacy,
  setActive,
  manageSites,
  enabledPrivacySettings,
}) {
  return (
    <div className={classes.buttonContainer}>
      <Button
        size="medium"
        variant="outlined"
        className={classes.button}
        onClick={() => {
          navigateToSites();
        }}
      >
        Back to Site Coordinates
      </Button>
      <Button
        size="medium"
        variant="outlined"
        className={classes.button}
        onClick={() => {
          navigateToSites();
        }}
      >
        Cancel
      </Button>
      <Button
        size="medium"
        variant="contained"
        className={classes.primaryButton}
        onClick={() => {
          savePrivacySettings({
            currentSite,
            dispatch,
            privacy,
            setActive,
            disClaimerTicker,
            enabledPrivacySettings,
          });
        }}
      >
        {manageSites ? "Save and Next" : "Next"}
      </Button>
    </div>
  );
}

function renderForm({ classes, privacy, enabledPrivacySettings, setPrivacy, manageSites }) {
  return (
    <div className={classes.formContainer1}>
      <label className={classes.formLabel2}>PRIVACY AND PI DATA CONSENT ACCESS</label>
      <FormGroup>
        <FormControlLabel
          control={
            <DisabledYellowCheckbox
              checked={true}
              name="email"
              disabled={true}
              className={classes.formLabel1}
            />
          }
          label={
            <span className={classes.formLabel1}>
              Email (Users will get automated email with access to privacy consent form)
            </span>
          }
          className={classes.formLabel1}
        />
        <FormControlLabel
          control={
            <YellowCheckbox
              checked={privacy.printConsent}
              onChange={(e) => setPrivacy({ ...privacy, printConsent: e.target.checked })}
              name="printConsent"
              disabled={!enabledPrivacySettings || !manageSites}
            />
          }
          label={
            <span className={classes.formLabel1}>
              Print (Allows manual update of status on Sitewise)
            </span>
          }
        />
        <FormControlLabel
          control={
            <YellowCheckbox
              checked={privacy.isAcknowledgementEnabled}
              onChange={(e) =>
                setPrivacy({ ...privacy, isAcknowledgementEnabled: e.target.checked })
              }
              name="isAcknowledgementEnabled"
              disabled={!enabledPrivacySettings || !manageSites}
            />
          }
          label={
            <span className={classes.formLabel1}>
              Do not require user consent (Shows only acknowledge option for privacy consent form)
            </span>
          }
        />
      </FormGroup>
    </div>
  );
}

function renderFormElements({
  classes,
  privacy,
  enabledPrivacySettings,
  setPrivacy,
  manageSites,
  manageSites_1,
}) {
  return (
    <div className={classes.formGroup}>
      <FormGroup className={classes.checkboxContainer}>
        <label className={classes.formLabel2}>ON TABLE</label>
        <FormControlLabel
          disabled={!manageSites}
          control={
            <YellowCheckbox
              checked={privacy.tableShowName}
              onChange={(e) => {
                setPrivacy({ ...privacy, tableShowName: e.target.checked });
              }}
              name="tableShowName"
              disabled={!enabledPrivacySettings}
            />
          }
          label={<span className={classes.formLabel1}>Show Name</span>}
        />
        <FormControlLabel
          disabled={!manageSites}
          control={
            <YellowCheckbox
              checked={privacy.tableShowEmail}
              onChange={(e) => setPrivacy({ ...privacy, tableShowEmail: e.target.checked })}
              name="tableShowEmail"
              disabled={!enabledPrivacySettings}
            />
          }
          label={<span className={classes.formLabel1}>Show Email</span>}
          className={classes.formLabel1}
        />
      </FormGroup>
      <div>
        <label className={classes.formLabel2}>ON MAP</label>
        <RadioGroup
          row
          className={classes.formLabel1}
          value={
            privacy.mapShowNameAlways === null ? null : privacy.mapShowNameAlways ? "true" : "false"
          }
          onChange={(e) => {
            setPrivacy({ ...privacy, mapShowNameAlways: e.target.value === "true" });
          }}
        >
          <FormControlLabel
            value={"true"}
            control={<YellowRadio />}
            label={<span className={classes.formLabel1}>Always Show Name</span>}
            className={classes.formLabel1}
            disabled={manageSites_1}
          />
          <FormControlLabel
            value="false"
            control={<YellowRadio />}
            label={<span className={classes.formLabel1}>Only Show Name on Emergency</span>}
            className={classes.formLabel1}
            disabled={manageSites_1}
          />
        </RadioGroup>
      </div>
    </div>
  );
}

const savePrivacySettings = ({
  currentSite,
  dispatch,
  privacy,
  setActive,
  disClaimerTicker,
  enabledPrivacySettings,
}) => {
  dispatch(SitesReducerActions.updateSiteDisclaimerTickerMessages(disClaimerTicker, true));
  if (enabledPrivacySettings) {
    if (privacy.privacyPdfUrl !== "") {
      if (currentSite?.privacySettings?.privacyPdfUrl && !privacy.privacyPdfUrl) {
        dispatch(SitesReducerActions.deleteSitePrivacyById(true));
      } else if (privacy.privacyPdfFile instanceof File) {
        dispatch(
          SitesReducerActions.updateSitePrivacyById(
            { ...privacy, mapShowNameAlways: privacy.mapShowNameAlways },
            true
          )
        );
      } else {
        dispatch(
          SitesReducerActions.patchSitePrivacyById(
            { ...privacy, mapShowNameAlways: privacy.mapShowNameAlways },
            true
          )
        );
      }
      setActive(1);
    } else {
      dispatch({
        type: GLOBAL_REDUCER_TYPES.SET_API_REQUEST_ERROR,
        payload: {
          message: "Please upload consent form to proceed with saving personal information changes",
        },
      });
    }
  } else {
    dispatch(SitesReducerActions.deleteSitePrivacyById(true));
    setActive(1);
  }
};

const handleToggleSwitch = ({ e, dispatch, setEnabledPrivacySettings }) => {
  if (e.target.checked) {
    setEnabledPrivacySettings(true);
  } else {
    dispatch({
      type: SITES_REDUCER_TYPES.SET_DATA_PRIVACY_INFO_DIALOG_DISPLAY,
      payload: true,
    });
  }
};

function handleInfoDialogBttn({ dispatch }) {
  dispatch({
    type: SITES_REDUCER_TYPES.SET_DATA_PRIVACY_INFO_DIALOG_DISPLAY,
    payload: false,
  });
}

const prefilledDisclaimerMessage = `<b>Disclaimer Message</b><br>
Please enter your text<br><br>
<b>Replace Disclaimer heading in lang2 here or clear this text</b><br>
Enter Disclaimer in lang.2 here or clear this text`;

const prefilledTickerMessage = `Enter ticker message<br>
Enter a message in lang2 here or clear this text`;

const privacySettingProperties = (currentSite) => {
  return {
    tableShowName: currentSite?.privacySettings?.tableShowName ?? false,
    tableShowEmail: currentSite?.privacySettings?.tableShowEmail ?? false,
    printConsent: currentSite?.privacySettings?.printConsent ?? false,
    privacyPdfUrl: currentSite?.privacySettings?.privacyPdfUrl ?? "",
    emailConsent: currentSite?.privacySettings?.emailConsent ?? false,
    mapShowNameAlways: currentSite?.privacySettings?.mapShowNameAlways ?? null,
    isAcknowledgementEnabled: currentSite?.privacySettings?.isAcknowledgementEnabled ?? false,
    disclaimerMessage:
      currentSite?.privacySettings?.disclaimerMessage || prefilledDisclaimerMessage,
    disclaimerTickerMessage:
      currentSite?.privacySettings?.disclaimerTickerMessage || prefilledTickerMessage,
  };
};

const disclaimerTickerProperties = (currentSite) => {
  return {
    disclaimerMessage: currentSite?.disclaimerMessage || prefilledDisclaimerMessage,
    disclaimerTickerMessage: currentSite?.disclaimerTickerMessage || prefilledTickerMessage,
  };
};

export const PersonalInfoForm = ({ currentSite, navigateToSites, setActive }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { Permissions } = useContext(UserPermissionContext);
  const manageSites = Permissions?.Manage_Sites;
  const [privacy, setPrivacy] = useState(privacySettingProperties(currentSite));
  const [disClaimerTicker, setdisClaimerTicker] = useState(disclaimerTickerProperties(currentSite));
  const [enabledPrivacySettings, setEnabledPrivacySettings] = useState(
    !!currentSite?.privacySettings
  );

  const manageSites_1 = !enabledPrivacySettings || !manageSites;

  const uploadDialog = useSelector((state) => state.sitesScreen.dialogs.showPrivacyUploadPdf);

  const showDataPrivacyInfoDialog = useSelector(
    (state) => state.sitesScreen.dialogs.showDataPrivacyInfoDialog
  );

  const handlePdfUpload = (file) => {
    setPrivacy({ ...privacy, consent: file, privacyPdfFile: file, privacyPdfUrl: file.name });
    dispatch({ type: SITES_REDUCER_TYPES.SET_PRIVACY_UPLOAD_DIALOG_DISPLAY, payload: false });
  };

  const handlePrivacyDialog = (payload) => {
    dispatch({
      type: SITES_REDUCER_TYPES.SET_PRIVACY_UPLOAD_DIALOG_DISPLAY,
      payload,
    });
  };

  return (
    <>
      <div className={classes.formContainer}>
        <div className={classes.formContentContainer}>
          <FormControlLabel
            disabled={!manageSites}
            label={<span className={classes.formLabel1}>Enable Showing Personal Information</span>}
            control={
              <YellowSwitch
                checked={enabledPrivacySettings}
                onChange={(e) =>
                  handleToggleSwitch({
                    e,
                    dispatch,
                    setEnabledPrivacySettings,
                    setPrivacy,
                    privacy,
                  })
                }
              />
            }
          />
        </div>
        <div className={classes.formContentContainer}>
          <label className={classes.formLabel2}>Disclaimer Message</label>
          <TextField
            disabled={!manageSites}
            fullWidth
            multiline
            rowsMax={3}
            variant="outlined"
            value={disClaimerTicker.disclaimerMessage}
            onChange={(event) => {
              setdisClaimerTicker({ ...disClaimerTicker, disclaimerMessage: event.target.value });
            }}
          />
        </div>
      </div>

      <div className={classes.formContainer}>
        <div className={classes.formContentContainer}>
          {renderFormElements({
            classes,
            privacy,
            enabledPrivacySettings,
            setPrivacy,
            manageSites,
            manageSites_1,
          })}
        </div>

        <div className={classes.formContentContainer}>
          <label className={classes.formLabel2}>Ticker Message</label>
          <TextField
            disabled={!manageSites}
            fullWidth
            multiline
            variant="outlined"
            rowsMax={3}
            value={disClaimerTicker.disclaimerTickerMessage}
            onChange={(event) => {
              setdisClaimerTicker({
                ...disClaimerTicker,
                disclaimerTickerMessage: event.target.value,
              });
            }}
          />
        </div>
      </div>
      {renderForm({ classes, privacy, enabledPrivacySettings, setPrivacy, manageSites })}

      <label className={classes.formLabel2}>EDIT DATA CONSENT FORM</label>

      {!privacy.consent ? (
        pdfConsentContent({
          privacyPdfUrl: privacy.privacyPdfUrl,
          enabledPrivacySettings,
          classes,
          handlePrivacyDialog,
          setPrivacy,
          privacy,
          manageSites,
        })
      ) : (
        <div className={classes.pdfContainer}>
          <span className={classes.pdfText}>{privacy.consent.name}</span>
          {manageSites ? (
            <span className={classes.iconContainer}>
              <Tooltip title="Upload File">
                <ICONS.ArrowUpwardIcon
                  onClick={() => handlePrivacyDialog(true)}
                  className={classes.icons}
                />
              </Tooltip>
              <Tooltip title="Remove File">
                <ICONS.Delete
                  onClick={() =>
                    setPrivacy({
                      ...privacy,
                      consent: null,
                      privacyPdfFile: null,
                      privacyPdfUrl: "",
                    })
                  }
                  className={classes.icons}
                />
              </Tooltip>
            </span>
          ) : (
            ""
          )}
        </div>
      )}

      {footerBttns({
        classes,
        navigateToSites,
        disClaimerTicker,
        currentSite,
        dispatch,
        privacy,
        setActive,
        manageSites,
        enabledPrivacySettings,
      })}

      {uploadDialog ? (
        <UploadPdfDialog
          show={uploadDialog}
          onCancel={() => handlePrivacyDialog(false)}
          onConfirm={handlePdfUpload}
        />
      ) : null}

      <DataPrivacyInfoDialog
        show={showDataPrivacyInfoDialog}
        infoText={`<div>
          <span>By disabling this option, user data will no longer be collected and 
          devices may be assigned without consent form acceptance but you will no longer be able to 
          see or manage user data.</span>
          <span>Settings will be saved as is untill you re-enable data collection.</span>
         </div>`}
        bttnOne={{ label: "Disable", Icon: ICONS.CheckIcon }}
        bttnTwo={{ label: "Cancel" }}
        onCancel={() => {
          handleInfoDialogBttn({ dispatch });
        }}
        onConfirmActionOne={() => {
          setEnabledPrivacySettings(false);
          dispatch({
            type: SITES_REDUCER_TYPES.SET_DATA_PRIVACY_INFO_DIALOG_DISPLAY,
            payload: false,
          });

          setPrivacy({
            ...privacy,
            printConsent: false,
            tableShowName: false,
            tableShowEmail: false,
            mapShowNameAlways: null,
            privacyPdfUrl: "",
            isAcknowledgementEnabled: false,
          });
        }}
        onConfirmActionTwo={() => {
          handleInfoDialogBttn({ dispatch });
        }}
      />
    </>
  );
};
