import { withStyles, Switch } from "@material-ui/core";

export const YellowSwitch = withStyles({
  switchBase: {
    "&$checked": {
      color: "#FBCE07",
    },
    "&$checked + $track": {
      backgroundColor: "#FBCE07",
    },
  },
  checked: {},
  track: {},
})(Switch);
