import { loadModules } from "esri-loader";
import { MapWidgetTmpl, renderUserDevicesOnMap, renderMapTooltipMarkup,renderMediaAssetsOnMap,renderMapTooltipMarkupMediaAssets } from "./util-fns";
import { gasTypes, parseUtcToLocal } from "../../utils";

let Zoom, BasemapToggle;

const getLayerById = (map, id) => {
  return map.allLayers.find((layer) => {
    return layer.id === id;
  });
};

const addDefaultWidgets = async (mapView) => {
  [Zoom, BasemapToggle] = await loadModules(["esri/widgets/Zoom", "esri/widgets/BasemapToggle"], {
    css: true,
  });

  const zoomWidget = new Zoom({
    view: mapView,
  });

  const toggleBasemapWidget = new BasemapToggle({
    view: mapView,
    nextBasemap: "topo",
  });

  mapView.ui.add([zoomWidget], "bottom-right");
  mapView.ui.add(toggleBasemapWidget, "bottom-left");
};

const showHidePlotPlan = async () => {
  const layer = await this.getLayerById("PLOT_PLAN");
  layer.visible = !layer.visible;
};

const getGasName = (gasName, dateStamp) => {
  return gasName
    ? `</br> ${gasName} </br> <span class="alert_timestamp">(${dateStamp})</span>`
    : "";
};

const getNumberOfGases = (gasAlerts) => {
  return gasAlerts.length > 1 ? `(${gasAlerts.length} Alerts)` : "";
};

const showGasType = (alert) => {
  return alert.alertType === "GasDetected" && alert?.pushNotification?.alert?.gasType
    ? `<span class="alert_gastype"> - ${alert.pushNotification.alert.gasType} </span>`
    : "";
};

const getAlertsName = (alerts, sensors = null) => {
  var alertNames = {
    GasDetected: "Gas Alert",
    SOS: "SOS Alert",
    ManDown: "Man-Down Alert",
  };

  var str = "";
  let dateStamp = null;
  let gasName = "";
  const seen = {};
  const gasAlerts = alerts.filter((alert) => alert.alertType === "GasDetected");
  if (gasAlerts.length > 0 && sensors) {
    const latestGasAlert = gasAlerts[gasAlerts.length - 1];
    dateStamp = parseUtcToLocal(latestGasAlert.createdTimestamp);
    gasName = gasTypes(latestGasAlert.deviceStatus.sensors, true, true).split(",").join("</br>");
  }
  alerts.forEach((alert) => {
    const name = alertNames[alert.alertType] ? alertNames[alert.alertType] : "Alert";
    if (alert.alertType === "GasDetected" && !seen[gasTypes(sensors)]) {
      str += `<div class="alert_gas_detected"> ${name} ${getNumberOfGases(gasAlerts)} ${getGasName(
        gasName,
        dateStamp
      )} ${showGasType(alert)} </div>`;
      seen[gasTypes(sensors)] = true;
    } else {
      str += `<div> ${name} ${showGasType(alert)}</div>`;
    }
  });

  return str;
};

const getAlertPriorityArr = (alerts) => {
  var priority = ["GasDetected", "SOS", "ManDown"];
  return alerts.sort(function (a, b) {
    var aKey = priority.indexOf(a.alertType);
    var bKey = priority.indexOf(b.alertType);
    return aKey - bKey;
  });
};

const getClickSelectedZone = ({ event, geometryEngine, GRAPHICS_LAYERS_ID, _map }) => {
  const selectedGraphics = [];
  const layer = MapUtilsV2.getLayerById(_map, GRAPHICS_LAYERS_ID.ZONES);
  layer.graphics.forEach((graphic) => {
    if (graphic.geometry.contains(event.mapPoint)) {
      const area = geometryEngine.geodesicArea(
        geometryEngine.simplify(graphic.geometry),
        "square-meters"
      );
      graphic.attributes.area = area;
      selectedGraphics.push(graphic);
    }
  });

  selectedGraphics.sort((graphicA, graphicB) =>
    graphicA.attributes.area > graphicB.attributes.area ? 1 : -1
  );

  return selectedGraphics[0];
};

const getSubSiteCenter = (site) => {
  return (
    site &&
    site.subSites[0] && {
      center: [site.subSites[0].longitude, site.subSites[0].latitude],
    }
  );
};

const showLastKnownTimestamp = (lastKnownLocationTime) => {
  return lastKnownLocationTime
    ? `<div class="last_known_timestamp">
    <b>Last known<b>: ${lastKnownLocationTime} ago
  </div>`
    : ` <div></div>`;
};

const showBeaconID = (BeaconInfo) => {
  return BeaconInfo && BeaconInfo.manufacturerId
    ? `<div class="beacon_info">
    <b>Beacon id<b>: ${BeaconInfo.manufacturerId}
  </div>`
    : ` <div></div>`;
};

export const MapUtilsV2 = {
  getLayerById: getLayerById,
  addDefaultWidgets: addDefaultWidgets,
  showHidePlotPlan: showHidePlotPlan,
  getAlertsName: getAlertsName,
  getAlertPriorityArr: getAlertPriorityArr,
  getClickSelectedZone: getClickSelectedZone,
  renderUserDevicesOnMap: renderUserDevicesOnMap,
  MapWidgetTmpl: MapWidgetTmpl,
  getSubSiteCenter: getSubSiteCenter,
  renderMapTooltipMarkup: renderMapTooltipMarkup,
  showLastKnownTimestamp:showLastKnownTimestamp,
  showBeaconID: showBeaconID,
  renderMediaAssetsOnMap: renderMediaAssetsOnMap,
  renderMapTooltipMarkupMediaAssets: renderMapTooltipMarkupMediaAssets
};
