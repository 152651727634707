import React, { useEffect, useState } from "react";
import { Paper, Tabs, Tab, Box, Tooltip, IconButton } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { TabPanel, a11yProps } from "../../commons/tabs";
import { AssignedDevicesTable } from "./components/table/assigned-devices-table";
import { UnassignedDevicesTable } from "./components/table/unassigned-devices-table";
import { DevicesReducerActions, DEVICES_REDUCER_TYPES, GlobalReducerActions } from "../../reducers";
import { RemoveDialog as UnassignDeviceDialog, useCommonStyles } from "../../commons";
import { AssignNewDeviceDialog, AssignUnassignedDeviceDialog } from "./components";
import { QRCodeDisplay } from "../../commons/qrcode-dialog";
import { withStyles } from "@material-ui/core/styles";
import { parseUtcToLocal } from "../../utils";

export const DevicesManagement = (props) => {
  const { siteID } = props;
  const dispatch = useDispatch();
  const [tabValue, setTabValue] = useState(0);
  const commonStyles = useCommonStyles();

  const devices = useSelector((state) => state.devicesScreen.devices);
  const assignedDevices = devices.filter((device) => device.currentAssignedUser);
  const unAssignedDevices = devices.filter((device) => !device.currentAssignedUser);
  const site = useSelector((state) => state.globalState.site);
  const showQRDialog = useSelector((state) => state.devicesScreen.dialogs.showQrDialog);
  const [showQRCodeIcon, setShowQRCodeIcon] = useState(false);
  const [mobileApiToken, setMobileApiToken] = useState("");
  const [qrExpriryDate, setQrExpiryDate] = useState("");

  const showAssignNewDeviceDialog = useSelector(
    (state) => state.devicesScreen.dialogs.showAssignNewDeviceDialog
  );

  const showAssignUnassignedDeviceDialog = useSelector(
    (state) => state.devicesScreen.dialogs.showAssignUnassignedDeviceDialog
  );

  const showUnassignDeviceDialog = useSelector(
    (state) => state.devicesScreen.dialogs.showUnassignDeviceDialog
  );

  const handleTabClick = (_, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    dispatch(GlobalReducerActions.getSiteById(siteID));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteID]);

  useEffect(() => {
    if (site) {
      if (site.mobileApiToken) {
        setMobileApiToken(site.mobileApiToken.value);
        const expiryDate = parseUtcToLocal(site.mobileApiToken.expiry)
        setQrExpiryDate(expiryDate);
      }

      setShowQRCodeIcon(site.active && !!site.deviceConfiguration.Mobile);
    }
  }, [site]);

  const QrCodeTooltip = withStyles({
    tooltip: {
      backgroundColor: "#464659",
      fontSize: "14px",
      lineHeight: "100%",
      margin: "12px 0px"
    }
  })(Tooltip);

  return (
    <Paper>
      <Box className={commonStyles.tabs_wrapper}>
        <Tabs
          value={tabValue}
          onChange={handleTabClick}
          aria-label="tabs"
          style={{ display: "flex", justifyContent: "space-between" }}
          classes={{
            root: commonStyles.tabs_root,
            indicator: commonStyles.tabs_indicator,
          }}
        >
          <Tab
            label={`Assigned Devices (${assignedDevices.length})`}
            {...a11yProps(0)}
            classes={{
              root: commonStyles.tab_root,
              selected: commonStyles.tab_selected,
            }}
            data-testid="assigned-devices-tab"
          />
          <Tab
            label={`Unassigned Devices (${unAssignedDevices.length})`}
            {...a11yProps(1)}
            classes={{
              root: commonStyles.tab_root,
              selected: commonStyles.tab_selected,
            }}
            data-testid="unassigned-devices-tab"
          />
        </Tabs>
        {showQRCodeIcon &&
          <QrCodeTooltip title="Generate QR Code" placement="top">
            <IconButton size="small">
              <i style={{ cursor: "pointer" }} class="fa fa-qrcode fa-lg" onClick={() => dispatch(DevicesReducerActions.setQrCodeDialogDisplay(true))}></i>
            </IconButton>
          </QrCodeTooltip>
        }
      </Box>
      <TabPanel value={tabValue} index={0}>
        <AssignedDevicesTable data={assignedDevices} />
        <UnassignDeviceDialog
          title="Are you sure you want to unassign Device from user?"
          show={showUnassignDeviceDialog}
          onConfirm={() => {
            dispatch(DevicesReducerActions.unAssignDevice());
          }}
          onCancel={() => {
            dispatch({
              type: DEVICES_REDUCER_TYPES.SET_UNASSIGN_DEVICE_DIALOG_DISPLAY,
              payload: false,
            });
          }}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <UnassignedDevicesTable data={unAssignedDevices} />
        <AssignUnassignedDeviceDialog
          show={showAssignUnassignedDeviceDialog}
          onConfirm={() => { }}
          onCancel={() => {
            dispatch({
              type: DEVICES_REDUCER_TYPES.SET_UNASSIGNED_ASSIGN_DEVICE_DIALOG_DISPLAY,
              payload: false,
            });
          }}
          siteID={siteID}
        />
      </TabPanel>

      <AssignNewDeviceDialog
        show={showAssignNewDeviceDialog}
        onConfirm={() => { }}
        onCancel={() => {
          dispatch({
            type: DEVICES_REDUCER_TYPES.SET_ASSIGN_NEW_DEVICE_DIALOG_DISPLAY,
            payload: false,
          });
        }}
        siteID={siteID}
      />

      <QRCodeDisplay
        show={showQRDialog}
        title="Onboard mobile Device"
        expiryDate={qrExpriryDate}
        url={`${process.env.REACT_APP_QR_CODE_GENERATOR_URL}/${siteID}/${mobileApiToken}`}
        onCancel={() => {
          dispatch(DevicesReducerActions.setQrCodeDialogDisplay(false));
        }}
      />
    </Paper>
  );
};
