import React from "react";
import { Link, makeStyles } from "@material-ui/core";
import { ICONS } from "../../commons";
import { FONTS } from "../../utils";

const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.common.white,
    display: "flex",
    alignItems: "center",
    ...FONTS.Label1_Reg_12,
  },
}));

export const BackBttn = (props) => {
  const classes = useStyles();
  const { show, onClick, label = "BACK" } = props;

  if (show) {
    return (
      <Link color="textSecondary" component="button" variant="body2" onClick={onClick}>
        <span className={classes.label}>
          <ICONS.ChevronLeft fontSize="small" /> {label}
        </span>
      </Link>
    );
  }
  return null;
};
