import React from "react";
import { Button } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { GLOBAL_REDUCER_TYPES } from "../../reducers";
import { ICONS } from "..";

const ALERT_LABEL = "There are Multiple Alerts. Please take a look.";

export const CombineAlertsSnackbar = ({ activeAlerts, classes, SNACKBAR_ACTIONS_LABELS }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const isAlertsPage = location.pathname.includes("/alerts");

  let allMuted = false;

  activeAlerts
    .filter((a) => a.alertType !== "PlotClear")
    .every((alert) => {
      if (alert.muted === false) {
        allMuted = true;
        return false;
      }
      return true;
    });

  return (
    <Alert
      severity="error"
      classes={{
        root: classes.alertRoot,
        icon: classes.icon,
      }}
      icon={
        !allMuted ? (
          <ICONS.VolumeOff
            fontSize="inherit"
            onClick={() => {
              activeAlerts
                .filter((a) => a.alertType !== "PlotClear")
                .forEach((alert) => {
                  dispatch({
                    type: GLOBAL_REDUCER_TYPES.SET_MUTED_ALERTS_IDS,
                    payload: alert.id,
                  });
                });
            }}
          />
        ) : (
          <ICONS.VolumeUp
            fontSize="inherit"
            onClick={() => {
              activeAlerts
                .filter((a) => a.alertType !== "PlotClear")
                .forEach((alert) => {
                  if (alert.muted === false) {
                    dispatch({
                      type: GLOBAL_REDUCER_TYPES.SET_MUTED_ALERTS_IDS,
                      payload: alert.id,
                    });
                  }
                });
            }}
          />
        )
      }
      action={
        isAlertsPage ? null : (
          <Button
            color="inherit"
            size="small"
            onClick={() => {
              activeAlerts
                .filter((a) => a.alertType !== "PlotClear")
                .forEach((alert) => {
                  dispatch({
                    type: GLOBAL_REDUCER_TYPES.SET_SNACKBAR_SEEN_ALERTS,
                    payload: alert.id,
                  });
                });

              history.push("/alerts");
            }}
          >
            {SNACKBAR_ACTIONS_LABELS.GO_TO_ALERTS}
          </Button>
        )
      }
    >
      {ALERT_LABEL}
    </Alert>
  );
};
