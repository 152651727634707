import React, { useEffect, useState } from "react";
import { Box, Dialog, makeStyles, Button } from "@material-ui/core";
import { FONTS } from "../../../utils";
import { useSelector, useDispatch } from "react-redux";
import { ZONE_REDUCER_TYPES } from "../../../reducers";

const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    backgroundColor: theme.palette.base.elevation_1,
    position: "absolute",
  },
  title: {
    textAlign: "center",
    paddingBottom: "20px",
    ...FONTS.H5_ShellBold_18,
  },
  content: {
    fontStyle: "normal",
    fontSize: "14px",
    lineHeight: "19px",
    display: "flex",
    alignItems: "center",
  },
  body: {
    textAlign: "center",
    marginBottom: "32px",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignIitems: "center",
  },
  customizeButton: {
    padding: "10px 16px",
    width: "103px",
    heigh: "44px",
    backgroundColor: "Transparent",
    backgroundRepeat: "no-repeat",
    border: "none",
    cursor: "pointer",
    overflow: "hidden",
    outline: "none",
    color: "white",
    letterSpacing: "0.02em",
    fontWeight: "bold",
    fontSize: "12px",
    lineHeight: "120%",
  },
  cookiePolicy: {
    fontWeight: "bold",
    color: "#FDE26A",
    textDecoration: "none",
  },
}));

export const PrivacyDisclaimerDialog = () => {
  const disclaimerMessage = useSelector((state) => state.globalState.site?.disclaimerMessage);
  const alertsDetected = useSelector((state) => state.globalState.alerts.activeAlerts);
  const alertsandMessage = !disclaimerMessage || alertsDetected.length;
  const [show, setShow] = useState(alertsandMessage ? false : true);
  const dispatch = useDispatch();

  function handleAcceptDisclaimer() {
    setShow(false);
    dispatch({
      type: ZONE_REDUCER_TYPES.SET_ZONE_CONFIG_POPOVER,
      payload: false,
    });
  }

  const classes = useStyles();

  useEffect(() => {
    if (alertsandMessage) {
      setShow(false);
      dispatch({
        type: ZONE_REDUCER_TYPES.SET_ZONE_CONFIG_POPOVER,
        payload: false,
      });
    } else {
      setShow(true);
      dispatch({
        type: ZONE_REDUCER_TYPES.SET_ZONE_CONFIG_POPOVER,
        payload: true,
      });
    }
  }, [alertsandMessage]);

  if (!show) {
    return null;
  }

  return (
    <Dialog
      className={classes.dialogContainer}
      classes={{
        root: classes.dialogContainer,
      }}
      BackdropProps={{
        classes: { root: classes.dialogContainer },
      }}
      style={{ position: "absolute", cursor: "pointer", zIndex:"99" }}
      disablePortal
      disableEnforceFocus
      open={show}
      PaperProps={{ className: classes.dialogContainer }}
    >
      <Box style={{ padding: "24px 24px 24px 24px" }}>
        <div className={classes.title}>Data here is for safety purpose only</div>
        <div className={classes.content}>
          <div
            className={classes.body}
            dangerouslySetInnerHTML={{ __html: disclaimerMessage }}
          ></div>
        </div>
        <div className={classes.buttonContainer}>
          <Button color="primary" variant="contained" onClick={handleAcceptDisclaimer}>
            Confirm
          </Button>
        </div>
      </Box>
    </Dialog>
  );
};
