import React, { useEffect } from "react";
import { Box, TextField, Button, makeStyles } from "@material-ui/core";
import { SITES_REDUCER_TYPES, SitesReducerActions } from "../../../../../../reducers";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  helperText: {
    color: theme.palette.error.main,
  },
}));

export const NewSubsiteFrom = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { currentSubSite, subsiteTextfieldLabel, id, currentSite, showCancelBttn = true } = props;

  let isSaveBttnDisabled = true;
  let errorNameVisible = false;

  if (
    currentSubSite &&
    currentSubSite.name &&
    currentSubSite.latitude &&
    currentSubSite.longitude &&
    currentSubSite.zoom > 2
  ) {
    isSaveBttnDisabled = false;
  }

  if (
    currentSite &&
    currentSite.subSites &&
    currentSite.subSites.length &&
    currentSubSite &&
    currentSubSite.name
  ) {
    currentSite.subSites.forEach((site) => {
      if (site.name.toLowerCase() === currentSubSite.name.toLowerCase()) {
        errorNameVisible = true;
        isSaveBttnDisabled = true;
      }
    });
  }

  useEffect(() => {
    dispatch({ type: SITES_REDUCER_TYPES.NEW_SITE_FORM_VISIBLE, payload: true });

    return function cleanup() {
      dispatch({ type: SITES_REDUCER_TYPES.NEW_SITE_FORM_VISIBLE, payload: false });
    };
  }, [dispatch]);

  return (
    <Box style={{ display: "flex", flexDirection: "column", padding: "20px 0" }}>
      <Box display="flex" justifyContent="space-between">
        <TextField
          fullWidth
          variant="filled"
          label={subsiteTextfieldLabel}
          type="text"
          value={currentSubSite?.name}
          onChange={(event) => {
            dispatch({
              type: SITES_REDUCER_TYPES.SET_CURRENT_SUBSITE,
              payload: {
                ...currentSubSite,
                id,
                name: event.target.value,
              },
            });
          }}
          helperText={errorNameVisible ? "Site Name Should Be Unique." : ""}
          FormHelperTextProps={{
            className: classes.helperText,
          }}
        />
      </Box>

      <Box display="flex" justifyContent="space-between" pt={2}>
        {currentSubSite === null || !currentSubSite.latitude || !currentSubSite.longitude ? (
          <TextField
            label="Double Click on map to find location"
            variant="filled"
            fullWidth
            disabled
            key="diabledTextfield"
          />
        ) : (
          <>
            <TextField
              label="Lat."
              onChange={(event) => {
                dispatch({
                  type: SITES_REDUCER_TYPES.SET_CURRENT_SUBSITE,
                  payload: {
                    ...currentSubSite,
                    id,
                    latitude: parseFloat(event.target.value, 10),
                  },
                });
              }}
              type="number"
              variant="filled"
              value={currentSubSite?.latitude}
              key="latitude_text_field"
            />
            <TextField
              label="Long."
              onChange={(event) => {
                dispatch({
                  type: SITES_REDUCER_TYPES.SET_CURRENT_SUBSITE,
                  payload: {
                    ...currentSubSite,
                    id,
                    longitude: parseFloat(event.target.value, 10),
                  },
                });
              }}
              type="number"
              variant="filled"
              value={currentSubSite?.longitude}
              key="longitude_text_field"
            />
            <TextField
              label="Zoom Level"
              type="number"
              variant="filled"
              value={currentSubSite?.zoom}
              onChange={(event) => {
                dispatch({
                  type: SITES_REDUCER_TYPES.SET_CURRENT_SUBSITE,
                  payload: {
                    ...currentSubSite,
                    id,
                    zoom: parseInt(event.target.value, 10),
                  },
                });
              }}
              key="zoom_text_field"
            />
          </>
        )}
      </Box>
      <Box pt={2} alignSelf="flex-end">
        {showCancelBttn ? (
          <Button
            color="primary"
            onClick={() => {
              dispatch({
                type: SITES_REDUCER_TYPES.SET_SHOW_MAP_INFO_WIDGET,
                payload: false,
              });

              dispatch({
                type: SITES_REDUCER_TYPES.RESET_CURRENT_SUBSITE,
                payload: null,
              });

              dispatch({
                type: SITES_REDUCER_TYPES.SET_MAP_ENABLE_DOUBLE_CLICK,
                payload: false,
              });

              dispatch({
                type: SITES_REDUCER_TYPES.NEW_SITE_FORM_VISIBLE,
                payload: false,
              });
              dispatch({
                type: SITES_REDUCER_TYPES.SET_SHOW_ADD_NEW_SITE_BTTN,
                payload: true,
              });
            }}
            variant="outlined"
          >
            CANCEL
          </Button>
        ) : null}

        <Button
          style={{ marginLeft: "10px" }}
          color="primary"
          disabled={isSaveBttnDisabled}
          onClick={() => {
            dispatch(SitesReducerActions.saveNewSubsite());
            dispatch({ type: SITES_REDUCER_TYPES.NEW_SITE_FORM_VISIBLE, payload: false });
          }}
          variant="contained"
        >
          SAVE
        </Button>
      </Box>
    </Box>
  );
};
