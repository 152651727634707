import { makeStyles } from "@material-ui/core";
import { FONTS } from "../../utils";

export const useDialogStyles = makeStyles((theme) => ({
  dialogPaper: {
    width: "464px",
    backgroundColor: theme.palette.base.elevation_1,
  },
  closeIconWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    position: "absolute",
    right: "0px",
  },
  dialogContentWrapper: {
    padding: "36px 36px 36px 36px",
  },
  dialogTitle: {
    paddingTop: "0px",
    paddingLeft: "0px",
    paddingRight: "0px",
    ...FONTS.H5_ShellBold_18,
  },
  dialogTitleCentered: {
    textAlign: "center",
    paddingBottom: "40px",
    ...FONTS.H5_ShellBold_18,
  },
  actionsBttnWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  dialogActionBttn: {
    ...FONTS.B4_BOLD_14,
  },
}));
