import React from "react";
import { Link, makeStyles } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import { FONTS } from "../../utils";
import { ZONE_REDUCER_TYPES } from "../../reducers";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.common.white,
    display: "flex",
    alignItems: "center",
    ...FONTS.Label1_Med_12,
  },
}));

export const ToggleViewLink = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { show = true, isLargeMapView } = props;
  const editing = useSelector((state) => state.zonesScreen.editingForm);
  const editingShape = useSelector((state) => state.zonesScreen.mapEditShapeInProgress);

  function renderContent() {
    if (isLargeMapView) {
      return (
        <span className={classes.label}>
          See Full List of Zones and Categories
          <ChevronRightIcon fontSize="small" />
        </span>
      );
    } else {
      return (
        <span className={classes.label}>
          <ChevronLeft fontSize="small" /> Back to Map View
        </span>
      );
    }
  }

  if (show) {
    return (
      <Link
        color="textSecondary"
        component="button"
        variant="body2"
        disabled={isLargeMapView && (editing || editingShape)}
        onClick={(event) => {
          event.preventDefault();
          if (editing || editingShape) {
            dispatch({ type: ZONE_REDUCER_TYPES.SET_EDIT_ZONE_DIALOG_DISPLAY, payload: true });
          } else {
            dispatch({
              type: ZONE_REDUCER_TYPES.SET_MAP_VIEW,
              payload: isLargeMapView ? "SMALL_MAP" : "LARGE_MAP",
            });
            dispatch({ type: ZONE_REDUCER_TYPES.SET_SHOW_ZONES_TPL_TABLE, payload: false });
          }
        }}
      >
        {renderContent()}
      </Link>
    );
  }

  return null;
};
