import { AxiosClient } from "./axios-client";
import { handleErrorFn } from "../custom-errors";

const getAccessToken = async (idToken) => {
  try {
    const res = await AxiosClient({
      url: `/api/Auth/PingIDLogin`,
      method: "POST",
      data: {
        idToken: idToken,
      },
    });
    return res.data;
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

const refreshToken = async (expiredAccessToken, refreshToken, siteLocationId) => {
  try {
    const res = await AxiosClient({
      url: `/api/Auth/RefreshToken`,
      method: "POST",
      data: {
        expiredAccessToken: expiredAccessToken,
        refreshToken: refreshToken,
        siteLocationId: siteLocationId,
      },
    });
    return res.data;
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

const getUserProfile = async (tokenToUse) => {
  const headers = { "Authorization": `Bearer ${tokenToUse}` };
  try {
    const res = await AxiosClient({
      url: `/api/Auth/UserProfile`,
      method: "GET",
      headers: headers,
    });
    return res.data;
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

export const AuthAPI = {
  getAccessToken,
  refreshToken,
  getUserProfile
};
