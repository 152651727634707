import { AlertsAPI, DevicesAPI, SiteLocationsAPI } from "../api";
import { SoundManager } from "../utils/sound-manager";
import { ZonesReducerActions } from "../reducers";

const _setMuteAlerts = (_activeAlerts, _mutedAlerts) => {
  _activeAlerts.map((alert) => {
    alert.muted = _mutedAlerts.includes(alert.id);
    return alert;
  });
};

const _setAlertSound = (alerts) => {
  let startSound = false;
  [...alerts]
    .filter((alert) => alert.alertType !== "PlotClear")
    .every((alert) => {
      if (alert.muted === false) {
        startSound = true;
        return false;
      }
      return true;
    });

  SoundManager.alertSound(startSound);
};

export const plotPlanImageLayerJson = {
  'hazira': {
    show: true,
    layers: [
      {
        showIcon:true,
        layer: "TileLayer",
        plan: "imagelayer",
        layerProperties: {
          url: "https://utility.arcgis.com/usrsvcs/servers/46ea8cd1574d4418b7c85a39a024fb3a/rest/services/ind_hazira_satellite_imagery_05m_sitewise_mazar/MapServer",
          id: "MAP_IMAGE_LAYER_HAZIRA",
          visible: false,
        },
      },
      {
        showIcon:true,
        layer: "VectorTileLayer",
        plan: "plotplan",
        layerProperties: {
          url: "https://utility.arcgis.com/usrsvcs/servers/48b5b3b4cc3c428a8d0fd2bf15449b83/rest/services/SiteWise_Plot_Plan_Hazira_GSPL/VectorTileServer",
          id: "MAP_PLOT_PLAN_HAZIRA",
          visible: false,
        },
      },
    ],
  },
  'scotford': {
    show: true,
    layers: [
      {
    trustedServers: "https://mapserver2.americas.shell.com/arcgis/",
        showIcon:true,
        layer: "ImageryLayer",
        plan: "imagelayer",
        layerProperties: {
          url: "https://mapserver2.americas.shell.com/arcgis/rest/services/TIMS/Scotford_TrueColour/ImageServer",
          id: "MAP_IMAGERY_LAYER_SCOTFORD",
          visible: false,
        },
      },
      {
    trustedServers: "https://mapserver2.americas.shell.com/arcgis/",
        showIcon:true,
        layer: "MapImageLayer",
        plan: "plotplan",
        layerProperties: {
          url: "https://mapserver2.americas.shell.com/arcgis/rest/services/Scotford/Scotford_Site_Plan/MapServer",
          id: "MAP_PLOT_PLAN_SCOTFORD",
          visible: false,
        },
      },
    ],
  },
  'pernis': {
    show: true,
    layers: [
      {
    trustedServers:"https://mapserver10.europe.shell.com/arcgis/",
        showIcon:true,
        layer: "MapImageLayer",
        plan: "plotplan",
        layerProperties: {
          url: "https://mapserver10.europe.shell.com/arcgis/rest/services/Europe/NL_downstream_pernis_europoort/MapServer",
          id: "MAP_PLOT_PLAN_PERNIS",
          visible: false,
        },
      },
    ],
  },
  'oman - stage': {
    show: true,
    layers: [
      {
    trustedServers: "https://sentinel.arcgis.com/arcgis/",
        showIcon:true,
        layer: "ImageryLayer",
        plan: "imagelayer",
        layerProperties: {
          url: "https://sentinel.arcgis.com/arcgis/rest/services/Sentinel2/ImageServer",
          id: "MAP_IMAGERY_LAYER_OMAN",
          visible: false,
        },
      },
      {
    trustedServers: "https://mapserver10.europe.shell.com/arcgis/",
        showIcon:true,
        layer: "MapImageLayer",
        plan: "plotplan",
        layerProperties: {
          url: "https://mapserver10.europe.shell.com/arcgis/rest/services/Middle_East/OM_PINK_UrbanPlanning_Map/MapServer",
          id: "MAP_PLOT_PLAN_OMAN",
          visible: false,
        },
      },
    ],
  },
};

const GLOBAL_INITIAL_STATE = {
  siteID: localStorage.getItem("SITE_ID") ? localStorage.getItem("SITE_ID") : null,
  site: null,
  isPageLoading: false,
  isSoftAlertVisible: false,
  isSignalRConnected: false,
  error: null,
  alerts: {
    activeAlerts: [],
    plotClear: [],
  },
  mutedAlertIds: [],
  snackbarAlerts: {
    list: [],
    seen: [],
  },
  infoToast: {
    show: false,
    message: "",
  },
  mapUserDevices: {
    filters: {
      selectedUserRoles: [],
      selectedUserType: [],
      selectedUserCategories: [],
      selectedUserDevice: null,
      selectedZone: null,
      outsidezone: false,
      filtersApplied: false,
    },
    list: [],
  },
  mapUserCount: 0,
};

export const GLOBAL_REDUCER_TYPES = {
  SET_PAGE_LOADER: "SET_PAGE_LOADER",
  SET_SOFT_ALERT: "SET_SOFT_ALERT",
  SET_SIGNALR_CONN_STATUS: "SET_SIGNALR_CONN_STATUS",
  SET_API_REQUEST_ERROR: "SET_API_REQUEST_ERROR",
  SET_ALL_ALERTS: "SET_ALL_ALERTS",
  SET_SNACKBAR_SEEN_ALERTS: "SET_SNACKBAR_SEEN_ALERTS",
  SET_MUTED_ALERTS_IDS: "SET_MUTED_ALERTS_IDS",
  SET_SITE: "SET_SITE",
  SET_SITE_ID: "SET_SITE_ID",
  SET_INFO_TOAST: "SET_INFO_TOAST",
  SET_MAP_USER_DEVICES: "SET_MAP_USER_DEVICES",
  SET_MAP_USER_COUNT: "SET_MAP_USER_COUNT",
  SET_MAP_USER_FILTERS: "SET_MAP_USER_FILTERS",
};

export const globalReducer = (state = GLOBAL_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case GLOBAL_REDUCER_TYPES.SET_PAGE_LOADER:
      return {
        ...state,
        isPageLoading: payload,
      };

    case GLOBAL_REDUCER_TYPES.SET_SITE_ID:
      localStorage.setItem("SITE_ID", payload);
      return {
        ...state,
        siteID: payload,
      };

    case GLOBAL_REDUCER_TYPES.SET_SITE:
      localStorage.setItem("SITE_ID", payload.id);
      return {
        ...state,
        site: payload,
      };

    case GLOBAL_REDUCER_TYPES.SET_SOFT_ALERT:
      return {
        ...state,
        isSoftAlertVisible: payload,
      };
    case GLOBAL_REDUCER_TYPES.SET_SIGNALR_CONN_STATUS:
      return {
        ...state,
        isSignalRConnected: payload,
      };
    case GLOBAL_REDUCER_TYPES.SET_API_REQUEST_ERROR:
      return {
        ...state,
        error: payload,
      };
    case GLOBAL_REDUCER_TYPES.SET_MUTED_ALERTS_IDS:
      const array = state.mutedAlertIds;
      const i = array.indexOf(payload);

      i > -1 ? array.splice(i, 1) : array.push(payload);

      _setMuteAlerts(state.alerts.activeAlerts, state.mutedAlertIds);

      _setAlertSound(state.alerts.activeAlerts);

      return {
        ...state,
        alerts: {
          ...state.alerts,
          activeAlerts: [...state.alerts.activeAlerts],
        },
        mutedAlertIds: array,
        snackbarAlerts: {
          list: [...state.snackbarAlerts.list],
          seen: [...state.snackbarAlerts.seen],
        },
      };
    case GLOBAL_REDUCER_TYPES.SET_ALL_ALERTS:
      const activeAlerts = payload;
      const snackbarSeenAlerts = state.snackbarAlerts.seen;
      const snackbarAlertList = activeAlerts.filter(
        (alert) => !snackbarSeenAlerts.includes(alert.id)
      );

      const plotClearAlerts = activeAlerts.map((a) => {
        if (a.alertType === "PlotClear") {
          return a.zone.name;
        }
        return null;
      });

      _setMuteAlerts(activeAlerts, state.mutedAlertIds);

      _setAlertSound(activeAlerts);

      return {
        ...state,
        alerts: {
          ...state.alerts,
          activeAlerts: [...activeAlerts],
          plotClear: [...plotClearAlerts],
        },
        snackbarAlerts: {
          ...state.snackbarAlerts,
          list: snackbarAlertList,
        },
      };

    case GLOBAL_REDUCER_TYPES.SET_SNACKBAR_SEEN_ALERTS:
      const seenAlerts = state.snackbarAlerts.seen;
      seenAlerts.push(payload);
      const newList = state.snackbarAlerts.list.filter((alert) => !seenAlerts.includes(alert.id));
      return {
        ...state,
        snackbarAlerts: {
          ...state.snackbarAlerts,
          list: newList,
          seen: seenAlerts,
        },
      };

    case GLOBAL_REDUCER_TYPES.SET_INFO_TOAST:
      return {
        ...state,
        infoToast: {
          show: payload.show,
          message: payload.message,
        },
      };

    case GLOBAL_REDUCER_TYPES.SET_MAP_USER_FILTERS:
      let filtersApplied;

      if (
        payload.selectedUserCategories.length ||
        payload.selectedUserRoles.length ||
        payload.selectedUserType.length ||
        payload.selectedUserDevice ||
        payload.selectedZone ||
        payload.outsidezone === true
      ) {
        filtersApplied = true;
      } else {
        filtersApplied = false;
      }

      return {
        ...state,
        mapUserDevices: {
          ...state.mapUserDevices,
          filters: {
            ...payload,
            filtersApplied: filtersApplied,
          },
        },
      };

    case GLOBAL_REDUCER_TYPES.SET_MAP_USER_DEVICES:
      return {
        ...state,
        mapUserDevices: {
          ...state.mapUserDevices,
          list: payload,
        },
      };

    case GLOBAL_REDUCER_TYPES.SET_MAP_USER_COUNT:
      return {
        ...state,
        mapUserCount: payload,
      };

    default:
      return state;
  }
};

// ACTIONS
const showPageLoader = () => {
  return {
    type: GLOBAL_REDUCER_TYPES.SET_PAGE_LOADER,
    payload: true,
  };
};

const hidePageLoader = () => {
  return {
    type: GLOBAL_REDUCER_TYPES.SET_PAGE_LOADER,
    payload: false,
  };
};
const showSoftAlert = () => {
  return {
    type: GLOBAL_REDUCER_TYPES.SET_SOFT_ALERT,
    payload: true,
  };
};

const updateSignalRConnStatus = (payload) => {
  return {
    type: GLOBAL_REDUCER_TYPES.SET_SIGNALR_CONN_STATUS,
    payload: payload,
  };
};

const setApiRequestError = (error) => {
  return { type: GLOBAL_REDUCER_TYPES.SET_API_REQUEST_ERROR, payload: error };
};

const getAllAlerts = (showPageLoader = true) => {
  return async (dispatch, getState) => {
    try {
      showPageLoader && dispatch(GlobalReducerActions.showPageLoader());
      const siteID = getState().globalState.siteID;
      const response = await AlertsAPI.getAllActiveAlertsBySiteLocation(siteID);
      dispatch({ type: GLOBAL_REDUCER_TYPES.SET_ALL_ALERTS, payload: response });
      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

const getSiteById = (id, showPageLoader = true) => {
  return async (dispatch, getState) => {
    try {
      showPageLoader && dispatch(GlobalReducerActions.showPageLoader());
      const siteID = id ? id : getState().globalState.siteID;
      const response = await SiteLocationsAPI.getSiteById(siteID);
      dispatch({ type: GLOBAL_REDUCER_TYPES.SET_SITE, payload: response });
      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

const getUserDevicesWithAppliedFilters = (filters, showPageLoader = true) => {
  return async (dispatch, getState) => {
    try {
      showPageLoader && dispatch(GlobalReducerActions.showPageLoader());
      let mapFilters = {};
      if (filters) {
        mapFilters = filters;
      } else {
        mapFilters = getState().globalState.mapUserDevices.filters;
      }

      const {
        selectedUserDevice,
        selectedUserCategories,
        selectedUserRoles,
        selectedUserType,
        selectedZone: zone,
        outsidezone,
      } = mapFilters;

      const siteID = getState().globalState.siteID;

      const uc = selectedUserCategories.map((cat) => cat.id);
      const ur = selectedUserRoles.map((role) => role.id);
      let params;

      if (selectedUserDevice) {
        params = new URLSearchParams({
          q: selectedUserDevice?.manufacturerId,
          o: true,
          a: true,
        });
      } else {
        params = new URLSearchParams({
          q: selectedUserDevice ? selectedUserDevice.manufacturerId : "",
          uc: uc.join(),
          ur: ur.join(),
          ut: selectedUserType.join(),
          zoneid: (zone && zone.id) || "",
          o: true,
          a: true,
          outsidezone,
        });
      }

      const str = params.toString();
      const response = await DevicesAPI.getSitelocationDevicesByQueryString(siteID, str);
      dispatch({ type: GLOBAL_REDUCER_TYPES.SET_MAP_USER_DEVICES, payload: response });
      dispatch({ type: GLOBAL_REDUCER_TYPES.SET_MAP_USER_COUNT, payload: response.length });
      // remove below code
      dispatch(ZonesReducerActions.getOnlineAssignedDevices(false));
      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

const getUserDevicesWithinZoneId = (zoneID, showPageLoader = true) => {
  return async (dispatch, getState) => {
    showPageLoader && dispatch(GlobalReducerActions.showPageLoader());
    try {
      const siteID = getState().globalState.siteID;

      const params = new URLSearchParams({
        zoneid: zoneID,
        o: true,
        a: true,
        outsidezone: false,
      });

      const str = params.toString();
      const response = await DevicesAPI.getSitelocationDevicesByQueryString(siteID, str);
      dispatch({ type: GLOBAL_REDUCER_TYPES.SET_MAP_USER_DEVICES, payload: response });
      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

export const GlobalReducerActions = {
  showPageLoader,
  hidePageLoader,
  showSoftAlert,
  updateSignalRConnStatus,
  setApiRequestError,
  getAllAlerts,
  getSiteById,
  getUserDevicesWithAppliedFilters,
  getUserDevicesWithinZoneId,
};
