import React, { useState, useContext } from "react";
import { FormControl, FormControlLabel, FormGroup, makeStyles, Button } from "@material-ui/core";
import { YellowCheckbox } from "../../../commons/checkbox";
import { FONTS } from "../../../utils";
import { useDispatch } from "react-redux";
import { SitesReducerActions } from "../../../reducers";
import { UserPermissionContext } from "../../../commons";

const useStyles = makeStyles(() => ({
  buttonContainer: {
    justifyContent: "flex-end",
    display: "flex",
  },
  button: {
    margin: "10px",
    border: "1px solid",
    color: "#FBCE07",
    ...FONTS.Button1_Bold_16,
  },
  primaryButton: {
    backgroundColor: "#FBCE07",
    margin: "10px",
    ...FONTS.Button1_Bold_16,
  },
  formGroup: {
    display: "flex",
  },
  formLabel1: {
    ...FONTS.Button1_Bold_14,
  },
  checkboxContainer: {
    marginRight: "60px",
    flexDirection: "row",
    width: "100%",
  },
  formControl: {
    width: "16%",
  },
  description1: {
    ...FONTS.B5_Bold_12,
  },
  description2: {
    ...FONTS.B5_Reg_12,
  },
}));

const checkboxLabels = {
  locations: {
    label: "Locations",
  },
  radioChannel: {
    label: "Radio Channel",
  },
  department: {
    label: "Department",
  },
  contractorOrEmployeeId: {
    label: "Employee/Contractor ID",
  },
  email: {
    label: "Email",
  },
  name: {
    label: "Name",
  },
  company: {
    label: "Company",
  },
};

export const UserDataManagementForm = (props) => {
  const { navigateToSites, currentSite, setActive } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [userManagementState, setUserManagementState] = useState({
    locations: currentSite?.userAttributes?.locations,
    radioChannel: currentSite?.userAttributes?.radioChannel,
    department: currentSite?.userAttributes?.department,
    contractorOrEmployeeId: currentSite?.userAttributes?.contractorOrEmployeeId,
  });
  const { Permissions } = useContext(UserPermissionContext);
  const manageSites = Permissions?.Manage_Sites;
  return (
    <>
      <FormControl style={{ height: "48vh", width: "100%" }}>
        <div className={classes.description1}>
          SELECT USER ATTRIBUTES TO COLLECT AND SHOW ON SITEWISE
        </div>
        <p className={classes.description2}>
          By selecting the attributes below, you enable the collection of these user data attributes
          and to show them on Sitewise. Under the ‘User Management’ section you will be able to see
          and edit these fields for each user. User Category, Role and Type will be collected from
          all users regardless of attributes selected below.
        </p>
        <div className={classes.formGroup}>
        <FormGroup           
          className={classes.checkboxContainer}>
            <FormControlLabel
              classes={{
                root: classes.formControl,
              }}
              control={
                <YellowCheckbox
                  checked={true}
                  disabled={true}
                  name={checkboxLabels["name"].label}
                />
              }
              label={<span className={classes.formLabel1}>{checkboxLabels["name"].label}</span>}
            />
            <FormControlLabel
              classes={{
                root: classes.formControl,
              }}
              control={
                <YellowCheckbox
                  disabled={true}
                  checked={true}
                  name={checkboxLabels["email"].label}
                />
              }
              label={<span className={classes.formLabel1}>{checkboxLabels["email"].label}</span>}
            />

            <FormControlLabel
              classes={{
                root: classes.formControl,
              }}
              control={
                <YellowCheckbox
                  name={checkboxLabels["company"].label}
                  checked={true}
                  disabled={true}
                />
              }
              label={<span className={classes.formLabel1}>{checkboxLabels["company"].label}</span>}
            />
            {Object.keys(userManagementState).map((key) => {
              if (["name", "email", "company"].includes(key)) {
                return null;
              }
              const value = userManagementState[key];
              return (
                <FormControlLabel
                  disabled={!manageSites}
                  classes={{
                    root: classes.formControl,
                  }}
                  control={
                    <YellowCheckbox
                      checked={value}
                      onChange={(e) => {
                        setUserManagementState({
                          ...userManagementState,
                          [key]: e.target.checked,
                        });
                      }}
                      name={checkboxLabels[key].label}
                    />
                  }
                  label={<span className={classes.formLabel1}>{checkboxLabels[key].label}</span>}
                />
              );
            })}
          </FormGroup>
        </div>
      </FormControl>

      <div className={classes.buttonContainer}>
        <Button
          size="medium"
          variant="outlined"
          className={classes.button}
          onClick={() => {
            navigateToSites();
          }}
        >
          Back to Site Coordinates
        </Button>
        <Button
          size="medium"
          variant="outlined"
          className={classes.button}
          onClick={() => {
            navigateToSites();
          }}
        >
          Cancel
        </Button>
        <Button
          size="medium"
          disabled={false}
          variant="contained"
          className={classes.primaryButton}
          onClick={() => {
            dispatch(SitesReducerActions.updateUserAttributesBySiteId(userManagementState)).then(
              () => {
                setActive(2);
              }
            );
          }}
        >
          {manageSites ? "Save and Next" : "Next"}
        </Button>
      </div>
    </>
  );
};
