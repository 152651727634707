import React from "react";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  mapContainer: {
    width: "100%",
    height: "100%",
  },
}));

export const SiteCordsMapContainer = () => {
  const classes = useStyles();
  return <div id="site-cords-map-container" className={classes.mapContainer} />;
};
