import React, {useContext} from "react";
import { Box, IconButton, Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ICONS, UserPermissionContext } from "../../../../../../commons";
import { SiteDetail } from "./site-detail";
import { NewSubsiteFrom } from "./new-subsite-form";
import { SITES_REDUCER_TYPES } from "../../../../../../reducers";

export const SiteCordsForm = (props) => {
  const dispatch = useDispatch();
  const { Permissions } = useContext(UserPermissionContext);
  const history = useHistory();
  const {
    currentSite,
    showDefaultSiteForm,
    updateSite,
    currentSubSite,
    goToSubSite,
    newSiteFormVisible,
    mapSelectedSubSite,
    showMapInfoWidget,
  } = props;

  const showAddSubSiteBttn = useSelector((state) => state.sitesScreen.showAddSubSiteBttn);
  const iconDisabled = () => {
    return Permissions.Manage_Sites && (newSiteFormVisible || currentSite.subSites.length === 0)  ? true : false;
  };
  return (
    <div
      style={{
        flexBasis: "45%",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-between",
      }}
    >
      <Box display="flex" flexDirection="column">
        <Box>
          <span>
            Sitewise allows multiple site areas to be shown with shortcuts that zoom to each area.
            To set these areas, add Site Areas below. Double click on the map to the right to drop a
            pin and designate that area on the map.
          </span>
        </Box>

        {showDefaultSiteForm ? (
          <NewSubsiteFrom
            currentSubSite={currentSubSite}
            id={currentSite.subSites.length}
            subsiteTextfieldLabel="Default Site Area Name"
            showCancelBttn={false}
          />
        ) : (
          <div style={{ height: "35vh", overflowY: "auto" }}>
            {currentSite.subSites.map((subSite, index) => {
              return (
                <SiteDetail
                  mapSelectedSubSite={mapSelectedSubSite}
                  currentSubSite={currentSubSite}
                  subSite={subSite}
                  key={`${subSite.name}-${index}`}
                  id={index}
                  updateSite={updateSite}
                  goToSubSite={goToSubSite}
                  showMapInfoWidget={showMapInfoWidget}
                />
              );
            })}
          </div>
        )}

        {newSiteFormVisible && currentSite.subSites.length ? (
          <NewSubsiteFrom
            currentSubSite={currentSubSite}
            id={currentSite.subSites.length}
            subsiteTextfieldLabel="Name of Site Area"
            currentSite={currentSite}
          />
        ) : null}

        {showAddSubSiteBttn ? (
          <Box pt={3}>
            <IconButton
              aria-label="delete"
              size="medium"
              color="primary"
              disabled={iconDisabled() || !Permissions.Manage_Sites}
              onClick={() => {
                dispatch({ type: SITES_REDUCER_TYPES.NEW_SITE_FORM_VISIBLE, payload: true });
                dispatch({ type: SITES_REDUCER_TYPES.SET_MAP_ENABLE_DOUBLE_CLICK, payload: true });
                dispatch({
                  type: SITES_REDUCER_TYPES.SET_SHOW_ADD_NEW_SITE_BTTN,
                  payload: false,
                });
              }}
            >
              <ICONS.Add fontSize="inherit" />
            </IconButton>
            <span>Add Additional Site Markers</span>
          </Box>
        ) : null}
      </Box>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          color="primary"
          variant="outlined"
          style={{ marginRight: "10px" }}
          disabled={currentSite.subSites.length === 0 ? true : false}
          onClick={() => {
            history.push("/sites");
          }}
        >
          Exit
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            dispatch({ type: SITES_REDUCER_TYPES.SET_PAGE_INDEX, payload: 1 });
          }}
          disabled={currentSite.subSites.length === 0 ? true : false}
        >
          Next Site Settings
        </Button>
      </div>
    </div>
  );
};
