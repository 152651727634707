const COLOR_PRIMARY = "#FBCE07";

export const themeConfig = {
  breakpoints: {
    keys: ["tablet", "laptop", "xs", "sm", "md", "lg", "xl"],
    values: {
      tablet: 640,
      laptop: 1024,
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    common: {
      black: "#000",
      white: "#fff",
    },
    primary: {
      main: COLOR_PRIMARY,
    },
    base: {
      elevation_0: "#1D1D2F",
      elevation_1: "#2B2B36",
      elevation_2: "#373746",
      elevation_3: "#464659",
      elevation_overlay_1: "#373746",
      elevation_overlay_2: "#464659",
      elevation_overlay_3: "#4F4F64",
    },
    type: "dark",
    background: {
      default: "#1D1D25", // adds to the background color
      paper: "#373746", // style added
    },
  },
  typography: {
    fontFamily: ["Arial"].join(","),
    h4: {
      fontWeight: 600,
      fontSize: "24px",
      color: "green",
    },
    h9: {
      fontWeight: 300,
      fontSize: "16px",
      color: "red",
    },
  },
  overrides: {
    MuiMenuItem: {
      root: {
        "&$selected": {
          color: COLOR_PRIMARY,
        },
      },
    },
    MuiButton: {
      root: {
        fontWeight: 600,
      },
    },
  },
};
