import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  TableCell,
  TableRow,
  Button,
  makeStyles,
  Box,
  Collapse,
  IconButton,
  Tooltip,
  Popper,
  Grow,
  Paper,
  MenuList,
  MenuItem,
  ClickAwayListener,
  Checkbox,
  Grid,
  Typography
} from "@material-ui/core";
import {
  useCustomTableStyles,
  CategoryChip,
  ICONS,
  ColorPallete,
  UserPermissionContext,
  ModifyZonesDialog,
} from "../../../../../commons";
import { ZONE_REDUCER_TYPES, ZonesReducerActions } from "../../../../../reducers";
import { AlertChip } from "../..";
import { UsersCount } from "../../../../../commons/user-count";
import { FONTS, AppUtils, parseUtcToLocal } from "../../../../../utils";
import { ALERT_COLOR_CODES } from "../../../../../config/alerts-color-codes";
import clsx from "clsx";
import { ZonesColorCodes } from "../../../../../config";

const SPACE_BETWEEN = "space-between";

const options = ["Evacuate", "Modify Zone", "Reshape Zone", "Delete Zone","Edit Zone color"];

var evacuationConfig = {
  id: "2",
  createdTimestamp: "2020-11-26T16:15:00.71Z",
  name: "Evacuation",
  isAdHoc: false,
  colour: {
    red: 255,
    green: 69,
    blue: 58,
  },
  activeZones: 0,
  notEditable: true,
};

const useStyles = makeStyles((theme) => ({
  tableRow: {
    "&$selected, &$selected:hover": {
      backgroundColor: theme.palette.base.elevation_3,
    },
  },
  pointer: {
    cursor: "pointer",
  },
  tableCellAlert: {
  },
  lastUpdated: {
    ...FONTS.B5_Reg_12,
  },
  selected: {},
  chipsWrapperRoot: {
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  plotClearBttn: {
    borderRadius: "4px",
    color: "black",
    backgroundColor: "#FF453A",
  },

  cardWrapper: {
    width: "100%",
  },
  usersInfo: {
    justifyContent: SPACE_BETWEEN,
    marginBottom: "30px",
    display: "flex",
  },
  actions: {
    justifyContent: SPACE_BETWEEN,
    alignItems: "center",
    display: "flex",
  },
  alertsSpan: {
    ...FONTS.B5_Reg_12,
  },
  alertLink: {
    ...FONTS.Label2_Bold_12,
    color: "#FFFFFF",
  },
}));

function renderRowActionCell({
  rowData,
  tableStyles,
  mapEditShapeInProgress,
  isItemSelected,
  dispatch,
  onTableExpandBttnCb,
  setopenEllipses,
}) {
  return (
    <TableCell
      key={`${rowData.id}-${rowData.name}-action-bttns`}
      style={{ textAlign: "right" }}
      className={tableStyles.zoneRowCell}
    >
      {!rowData.isFrontEndOnly && (
        <IconButton
          disabled={mapEditShapeInProgress}
          color="primary"
          aria-label="expand row"
          size="small"
          onClick={(event) => {
            event.stopPropagation();
            _handleTableRowIconClick({
              rowData,
              isItemSelected,
              dispatch,
              onTableExpandBttnCb,
              setopenEllipses,
            });
          }}
        >
          {isItemSelected ? <ICONS.KeyboardArrowUp /> : <ICONS.KeyboardArrowDown />}
        </IconButton>
      )}
    </TableCell>
  );
}

function renderRowActionEllipsesCell({
  rowData,
  tableStyles,
  mapEditShapeInProgress,
  isItemSelected,
  dispatch,
  onTableExpandBttnCb,
  setopenEllipses,
  checkboxZone
}) {
  return (
    <TableCell
      key={`${rowData.id}-${rowData.name}-action-bttns`}
      style={{ textAlign: "right" }}
      className={tableStyles.zoneRowCell}
    >
      {!rowData.isFrontEndOnly && (
        <IconButton
          id={rowData.id}
          disabled={mapEditShapeInProgress || checkboxZone}
          color="primary"
          aria-label="expand row"
          size="small"
          onClick={(event) => {
            event.stopPropagation();
            _handleTableRowEllipsesIconClick({
              event,
              rowData,
              isItemSelected,
              dispatch,
              onTableExpandBttnCb,
              setopenEllipses,
            });
          }}
        >
          <ICONS.MoreVertIcon id={rowData.id} />
        </IconButton>
      )}
    </TableCell>
  );
}

function renderChips(rowData) {
  const { categories } = rowData;

  if (categories) {
    return categories.map((category, index) => {
      return (
        <div style={{ marginTop: "5px" }} key={index}>
          <CategoryChip data={category} key={category.id} />
        </div>
      );
    });
  }

  return null;
}

function checkToRender(sortyBy, orderBy) {
  return sortyBy === orderBy;
}

function renderAlertChip(alert) {
  return alert ? <AlertChip data={alert} key={alert.id} /> : null;
}

function renderNonAlertTableCell({ rowData, isItemSelected, selectedZone }) {
  return (
    <TableCell
      key={`${rowData.id}-collapse-without-alert`}
      style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: "45px" }}
      colSpan={5}
    >
      <Collapse in={isItemSelected} timeout="auto" unmountOnExit>
        <Box display="flex" flexDirection="column" pt={1} pb={1}>
          <Box style={{ padding: "8px", paddingLeft: "0px" }}>{renderChips(rowData)}</Box>
          <Box style={{ padding: "8px", paddingLeft: "0px" }}>
            Entry Message: {selectedZone && selectedZone.entryMessage}
          </Box>
          <Box style={{ padding: "8px", paddingLeft: "0px" }}>
            Exit Message: {selectedZone && selectedZone.exitMessage}
          </Box>
          <Box style={{ padding: "8px", paddingLeft: "0px" }}>
            <div>
              <div>
                {selectedZone && (
                  <Tooltip title={selectedZone.createdBy}>
                    <span>
                      {`Zone Created by ${AppUtils.parseEmailToName(selectedZone.createdBy)} `}
                    </span>
                  </Tooltip>
                )}
                {selectedZone && parseUtcToLocal(selectedZone.createdTimestamp)}
              </div>
              {selectedZone &&
              selectedZone.lastModifiedTimestamp?.slice(0, 22) !==
                selectedZone.createdTimestamp?.slice(0, 22) ? (
                <div>
                  <Tooltip title={selectedZone.lastModifiedBy}>
                    <span>
                      {`Zone Modified by ${AppUtils.parseEmailToName(
                        selectedZone.lastModifiedBy
                      )} `}
                    </span>
                  </Tooltip>
                  {selectedZone && parseUtcToLocal(selectedZone.lastModifiedTimestamp)}
                </div>
              ) : null}
            </div>
          </Box>
        </Box>
      </Collapse>
    </TableCell>
  );
}

function getIsAlertPresent(rowData) {
  let isAlertPresent = false;
  let topAlert = null;

  if (rowData.alerts && rowData.alerts.length) {
    isAlertPresent = true;
    topAlert = rowData.alerts[0];
  }
  const borderLeftColorCode = topAlert
    ? ALERT_COLOR_CODES.AlertColor(topAlert.alertType).colorCode
    : "";

  const borderLeftColor = borderLeftColorCode
    ? `rgba(${borderLeftColorCode[0]}, ${borderLeftColorCode[1]}, ${borderLeftColorCode[2]})`
    : null;

  return { isAlertPresent, topAlert, borderLeftColor };
}

function renderAlertTableCell({
  rowData,
  isItemSelected,
  topAlert,
  history,
  classes,
  dispatch,
  Permissions,
}) {
  return (
    <TableCell
      key={`${rowData.id}-collapse-with-alert`}
      colSpan={5}
      style={{
        padding: 0,
      }}
    >
      <Collapse in={isItemSelected} timeout="auto" unmountOnExit>
        <Box
          style={{
            display: "flex",
            order: 0,
            alignItems: "center",
            justifyContent: SPACE_BETWEEN,
          }}
          p={2}
          m={0}
        >
          {topAlert.alertType !== "PlotClear" ? (
            <>
              {Permissions.View_Alerts && (
                <Button
                  variant="outlined"
                  onClick={() => {
                    history.push("/alerts");
                  }}
                  className={classes.alertLink}
                >
                  View Alerts Page
                </Button>
              )}

              {Permissions.Manage_Alerts && (
                <Button
                  variant="filled"
                  className={classes.plotClearBttn}
                  onClick={() => {
                    dispatch(ZonesReducerActions.plotClear(rowData.id));
                  }}
                >
                  Trigger Evacuation
                </Button>
              )}
            </>
          ) : (
            <div className={classes.cardWrapper}>
              <div className={classes.usersInfo}>
                <UsersCount label={"IN ZONE"} count={topAlert.inZoneCount} countColor="#DB4052" />
                <UsersCount
                  label={"IN TRANSIT TO MUSTER POINT"}
                  count={topAlert.inTransitCount}
                  countColor=""
                />
                <UsersCount label={"MARKED SAFE"} count={topAlert.markedSafeCount} countColor="" />
                <UsersCount label={"ERT IN ZONE"} count={topAlert.ertCount} countColor="#13BC86" />
              </div>
              <div className={classes.actions}>
                <div className={classes.lastUpdated}>
                  <div>Last Updated:</div>
                  <div>
                    {topAlert.lastModifiedTimestamp
                      ? parseUtcToLocal(topAlert.lastModifiedTimestamp)
                      : null}
                  </div>
                </div>
                <div>
                  {Permissions.View_Alerts ? (
                    <Button
                      variant="outlined"
                      onClick={() => {
                        history.push("/alerts");
                      }}
                      className={classes.alertsSpan}
                    >
                      View Alerts Page
                    </Button>
                  ) : null}
                </div>
              </div>
            </div>
          )}
        </Box>
      </Collapse>
    </TableCell>
  );
}

const _handleTableRowClicked = ({
  rowData,
  onTableRowClickCb,
  dispatch,
  mapEditShapeInProgress,
}) => {
  if (!mapEditShapeInProgress) {
    dispatch({
      type: ZONE_REDUCER_TYPES.SET_SELECTED_ZONE,
      payload: rowData,
    });
    onTableRowClickCb && onTableRowClickCb(rowData);
  }
};

function _handleTableRowIconClick({
  rowData,
  isItemSelected,
  dispatch,
  onTableExpandBttnCb,
}) {
  if (!isItemSelected) {
    onTableExpandBttnCb && onTableExpandBttnCb(rowData);
    dispatch({
      type: ZONE_REDUCER_TYPES.SET_SELECTED_ZONE,
      payload: rowData,
    });
  } else {
    onTableExpandBttnCb && onTableExpandBttnCb(null);
    dispatch({
      type: ZONE_REDUCER_TYPES.SET_SELECTED_ZONE,
      payload: null,
    });
  }
}

function _handleTableRowEllipsesIconClick({
  rowData,
  isItemSelected,
  dispatch,
  onTableExpandBttnCb,
  setopenEllipses,
}) {
  if (!isItemSelected) {
    setopenEllipses(true);
    onTableExpandBttnCb && onTableExpandBttnCb(rowData);
    dispatch({
      type: ZONE_REDUCER_TYPES.SET_SELECTED_ZONE,
      payload: rowData,
    });
  } else {
    setopenEllipses(false);
    onTableExpandBttnCb && onTableExpandBttnCb(null);
    dispatch({
      type: ZONE_REDUCER_TYPES.SET_SELECTED_ZONE,
      payload: null,
    });
  }
}

function renderCellsCheck({
  rowData,
  tableStyles,
  isAlertPresent,
  topAlert,
  orderBy,
  isLargeMapView,
  history,
  classes,
  borderLeftColor,
}) {
  function setClass() {
    let response;
    if (isAlertPresent) {
      response = `${tableStyles.zoneRowCell} ${classes.tableCellAlert}`;
    } else {
      response = tableStyles.zoneRowCell;
    }
    return response;
  }

  return (
    <>
      <TableCell
        className={setClass()}
        align="left"
        style={{
          borderLeftColor: `${borderLeftColor}`,
        }}
        key={`${rowData.id}-color-pallete`}
      >
        <Box display="flex" alignItems="center">
          <Box pr={1.5}>
            <ColorPallete colour={rowData.displayColour} data={rowData} />
          </Box>
          <span>{rowData.name}</span>
        </Box>
      </TableCell>

      <TableCell
        key={`${rowData.id}-${rowData.name}-${rowData.headcount}-count`}
        className={tableStyles.zoneRowCell}
      >
        {isAlertPresent ? renderAlertChip(topAlert) : `${rowData.headcount} Users`}
      </TableCell>

      {checkToRender("alerts", orderBy) &&
        (rowData.alerts.length ? (
          <Tooltip
            arrow
            placement="top"
            classes={{
              tooltip: tableStyles.tooltip,
            }}
            title={
              <>
                <div className={tableStyles.tooltipContentWrapper}>
                  <span className={tableStyles.tooltipContent}>Click to View all Alerts</span>
                </div>
              </>
            }
          >
            <TableCell
              key={`${rowData.id}-${rowData.name}-${rowData.alerts}`}
              className={tableStyles.zoneRowCell}
              onClick={() => {
                history.push("/alerts");
              }}
            >
              {isLargeMapView ? `Alerts` : ""} {rowData.alerts.length || null}
            </TableCell>
          </Tooltip>
        ) : (
          <TableCell
            className={tableStyles.zoneRowCell}
            key={`${rowData.id}-${rowData.name}-headcount`}
          ></TableCell>
        ))}

      {checkToRender("categories", orderBy) && (
        <TableCell
          key={`${rowData.id}-${rowData.name}-categories`}
          className={tableStyles.zoneRowCell}
        >
          <div className={classes.chipsWrapperRoot}>{renderChips(rowData)}</div>
        </TableCell>
      )}

      {checkToRender("createdTimestamp", orderBy) && (
        <TableCell
          key={`${rowData.id}-${rowData.name}-${rowData.createdTimestamp}`}
          className={tableStyles.zoneRowCell}
        >
          <span>
            {topAlert && topAlert.createdTimestamp
              ? parseUtcToLocal(topAlert.createdTimestamp)
              : parseUtcToLocal(rowData.createdTimestamp)}
          </span>
        </TableCell>
      )}
    </>
  );
}

export const Row = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const tableStyles = useCustomTableStyles();
  const { Permissions } = useContext(UserPermissionContext);
  const mapEditShapeInProgress = useSelector((state) => state.zonesScreen.mapEditShapeInProgress);
  const selectedZone = useSelector((state) => state.zonesScreen.selectedZone);
  const smallMapRef = useRef();
  const fullMapRef = useRef();
  const anchorRef = useRef();

  const [openEllipses, setopenEllipses] = useState(false);
  const [showModifydialog, setShowModifydialog] = useState(false);
  const [checkboxZone, setCheckboxZone] = useState(false);
  const [colour, setColour] = useState(null);
  const [openColorpicker, setOpenColorpicker] = useState(false);
  const [background, setBackground] = useState(null);

  const isPrivacyConsentPrompt = useSelector(
    (state) => state.zonesScreen.disabledzoneConfigPopover
  );
  const selectedZonesOperations = useSelector(
    (state) => state.zonesScreen.selectedZonesToPerformOperation
  );

  const {
    rowData,
    onTableRowClickCb,
    onTableExpandBttnCb,
    isLargeMapView,
    isItemSelected,
    orderBy,
    selectZoneAndEdit,
    onDeleteZoneCb,
  } = props;

  var evacuationCategory = rowData.categories.filter((e) => e.name === "Evacuation");

  const { isAlertPresent, topAlert, borderLeftColor } = getIsAlertPresent(rowData);

  const onReshapeZone = () => {
    selectZoneAndEdit(selectedZone);
  };

  const onZoneColorUpdate = () => {
    setOpenColorpicker(false);
    dispatch(ZonesReducerActions.updateZoneColor(colour.colorCode, rowData.id));
    setColour(null);
    setBackground(null);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setopenEllipses(false);
  };

  const handleMenuItemClick = (event, index) => {
    if (index === 0) {
      let allCategories;
      allCategories = rowData.categories;
      allCategories.push(evacuationConfig);
      dispatch({
        type: ZONE_REDUCER_TYPES.SET_SELECTED_ZONE_CATEGORIES,
        payload: allCategories,
      });
      dispatch(ZonesReducerActions.updateZone());
    } else if (index === 1) {
      setShowModifydialog(true);
    } else if (index === 2) {
      onReshapeZone();
    } else if (index === 3) {
      dispatch({
        type: ZONE_REDUCER_TYPES.SET_REMOVE_ZONE_DIALOG,
        payload: true,
      });
    } else {
      setOpenColorpicker(true);
    }

    setopenEllipses(false);
  };

  useEffect(() => {
    if (!Permissions.View_Alerts) {
      rowData.alerts = [];
      rowData.colour = { red: 238, green: 238, blue: 238 };
      rowData.displayColour = { red: 238, green: 238, blue: 238 };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowData]);

  useEffect(() => {
    if (evacuationCategory.length) {
      setCheckboxZone(false);
    }
  }, [evacuationCategory]);

  useEffect(() => {
    if (isItemSelected && rowData.id !== "000000000000000000000000") {
      if (isLargeMapView) {
        setTimeout(() => {
          fullMapRef.current.scrollIntoView({ block: "center", behavior: "smooth" });
        }, 650);
      } else {
        setTimeout(() => {
          smallMapRef.current.parentElement.parentElement.parentElement.scroll({
            top: smallMapRef.current.offsetTop - 69,
            behavior: "smooth",
          });
        }, 650);
      }
    }
  }, [isLargeMapView, isItemSelected, rowData.id]);

  useEffect(() => {
    if (!selectedZonesOperations?.length) {
      setCheckboxZone(false);
    }
  }, [selectedZonesOperations]);

  const handleChangeRowSelect = (event) => {
    event.stopPropagation();
    let list = [];
    setCheckboxZone(event.target.checked);
    let zonesList = selectedZonesOperations;
    if (zonesList.length) {
      list = zonesList.filter((x) => x.id !== rowData.id);

      if(event.target.checked){
        list = [...list, { id: rowData.id, checked: event.target.checked }];
      }
    } else {
      //adding a zone into new array
      list = [{ id: rowData.id, checked: event.target.checked }];
    }

    dispatch({
      type: ZONE_REDUCER_TYPES.SET_SELECTED_ZONES_TO_PERFORM_OPERATION,
      payload: list,
    });
  };

  const checkEvacuateDisabled = (index) => {
    let isDisabled = false;
    if (isPrivacyConsentPrompt) {
      isDisabled = true;
    } else if (index === 0) {
      let category = rowData.categories.filter((e) => e.name === "Evacuation");
      if (category.length) {
        isDisabled = true;
      }
    }
    return isDisabled;
  };

  function disabledCheckboxonPopperOpen(){
    if(openEllipses && isItemSelected){
      return true;
    }else{
      return false;
    }
  }

  return (
    <>
      <TableRow
        className={clsx(classes.tableRow, {
          [classes.pointer]: rowData.name !== "{Not in any zone}",
        })}
        classes={{
          selected: classes.selected,
        }}
        hover
        onClick={(event) => {
          event.stopPropagation();
          if (rowData.name === "{Not in any zone}") {
            return;
          }
          _handleTableRowClicked({
            rowData,
            onTableRowClickCb,
            dispatch,
            mapEditShapeInProgress,
          });
        }}
        tabIndex={-1}
        key={`${rowData.id}-firstRow`}
        selected={isItemSelected}
        ref={smallMapRef}
      >
        <TableCell
          key={`${rowData.id}-${rowData.name}-checkbox-bttns`}
          className={tableStyles.zoneRowCell}
        >
          {rowData.name === "{Not in any zone}" ? 
          <Checkbox
          id="zone-select-checkbox"
          onClick={(event) => {
            event.stopPropagation();
          }}
          disabled
          color="primary"
          checked={false}
        />
          : (
            <Checkbox
              id="zone-select-checkbox"
              onClick={(event) => {
                event.stopPropagation();
              }}
              disabled={checkEvacuateDisabled(0) || disabledCheckboxonPopperOpen()}
              color="primary"
              onChange={handleChangeRowSelect}
              checked={checkboxZone}
            />
          )}
        </TableCell>
        {renderRowActionCell({
          rowData,
          tableStyles,
          mapEditShapeInProgress,
          isItemSelected,
          dispatch,
          onTableExpandBttnCb,
          setopenEllipses,
        })}
        {renderCellsCheck({
          rowData,
          tableStyles,
          isAlertPresent,
          topAlert,
          orderBy,
          isLargeMapView,
          history,
          classes,
          borderLeftColor,
        })}

        {renderRowActionEllipsesCell({
          rowData,
          tableStyles,
          mapEditShapeInProgress,
          isItemSelected,
          dispatch,
          onTableExpandBttnCb,
          setopenEllipses,
          checkboxZone
        })}
        <Popper
          key={`${rowData.id}-elipses-popper`}
          unmountOnExit
          placement={"bottom-end"}
          open={openEllipses && isItemSelected}
          role={undefined}
          transition
          disablePortal
          style={{ position: "relative", top: "0", left: "0", zIndex: "99" }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper style={{ position: "absolute", right: "34px", top: "19px" }}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu" autoFocusItem>
                    {options.map((option, index) => (
                      <MenuItem
                        key={option}
                        disabled={checkEvacuateDisabled(index)}
                        onClick={(event) => handleMenuItemClick(event, index)}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
          {/* color picker */}
          <Popper
          key={`${rowData.id}-color-picker-popper`}
          unmountOnExit
          placement={"bottom-end"}
          open={openColorpicker}
          role={undefined}
          transition
          disablePortal
          style={{ position: "relative", top: "0", left: "0", zIndex: "99" }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper
                style={{
                  position: "absolute",
                  right: "34px",
                  top: "19px",
                  width: "275px",
                  padding: "12px 10px 5px",
                }}
              >
                <Grid>
                  <Typography
                    style={{ display: "inline-block", width: "224px" }}
                    variant="subtitle1"
                  >
                    Change color of : <b>{rowData.name}</b>
                  </Typography>

                  <IconButton
                    style={{
                      float: "right",
                    }}
                    size="small"
                    aria-label="close"
                    onClick={() => {
                      setOpenColorpicker(false);
                      setColour(null);
                      setBackground(null);
                    }}
                  >
                    <ICONS.CloseIcon />
                  </IconButton>
                </Grid>
                <Grid container spacing={2}>
                  {ZonesColorCodes.map((colorObj) => {
                    return (
                      <Grid
                        onClick={() => {
                          setBackground(colorObj.type);
                          setColour(colorObj);
                        }}
                        item
                        style={{ background: colorObj.type === background ? "black" : "" }}
                      >
                        <ColorPallete colour={colorObj.colorCode} title={colorObj.label} />
                      </Grid>
                    );
                  })}
                </Grid>

                <Button
                  size="small"
                  disabled={colour === null ? true : false}
                  variant="contained"
                  color="primary"
                  style={{ marginLeft: "180px" }}
                  onClick={onZoneColorUpdate}
                  // className={dialogStyles.dialogActionBttn}
                >
                  Save
                </Button>
                {/* </ClickAwayListener> */}
              </Paper>
            </Grow>
          )}
        </Popper>
      </TableRow>
      {!rowData.isFrontEndOnly && (
        <TableRow
          className={classes.tableRow}
          classes={{
            selected: classes.selected,
          }}
          hover
          selected={isItemSelected}
          key={`${rowData.id}-collapsed-row`}
          ref={fullMapRef}
        >
          {isAlertPresent
            ? renderAlertTableCell({
                rowData,
                isItemSelected,
                topAlert,
                history,
                classes,
                dispatch,
                Permissions,
              })
            : renderNonAlertTableCell({ rowData, isItemSelected, selectedZone })}
        </TableRow>
      )}

      <ModifyZonesDialog
        show={showModifydialog}
        rowCategories={rowData.categories}
        onTableExpandBttnCb={onTableExpandBttnCb}
        onConfirm={() => {
          dispatch(ZonesReducerActions.deleteZone(selectedZone.id, onDeleteZoneCb));
        }}
        onCancel={() => {
          setShowModifydialog(false);
        }}
      />
    </>
  );
};
