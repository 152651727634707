import React, { useState, useEffect } from "react";
import { makeStyles, Dialog, DialogTitle } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { UsersReducerActions } from "../../../../reducers";
import { useDialogStyles } from "../../../../commons";
import { validateEmail } from "../../../../utils";
import { FirstTabPanel } from "./first-tab-panel";
import { SecondTabPanel } from "./second-tab-panel";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    width: "448px",
    backgroundColor: theme.palette.base.elevation_1,
    padding: "20px",
  },
  closeIconWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },
  formControl: {
    width: "100%",
    marginTop: "24px",
  },
  dialogActionButton: {
    maxWidth: "213px",
    width: "213px",
    height: "102px",
    display: "flex",
    flexDirection: "column",
    background: theme.palette.base.elevation_overlay_1,
    "&:hover": {
      color: theme.palette.warning.main,
    },
  },
  formControlActions: {
    flexDirection: "row",
    justifyContent: "flex-end",
    marginTop: "32px",
    display: "flex",
  },
}));

export const NewUsersCreateDialog = ({ show, onCancel, onConfirm, siteID, site }) => {
  const classes = useStyles();
  const dialogStyles = useDialogStyles();
  const dispatch = useDispatch();
  const userCategories = useSelector((state) => state.usersScreen.userCategories);
  const userRoles = useSelector((state) => state.usersScreen.userRoles);

  const [tabValue, setTabValue] = useState(0);
  const [isContractor, setIsContractor] = useState("false");
  const [company, setCompany] = useState("");
  const [contractorOrEmployeeId, setContractorOrEmployeeId] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [userCategory, setUserCategory] = useState(null);
  const [userType, setUserType] = useState("");
  const [assignDevice, setAssignDevice] = useState(false);
  const [roleId, setRoleId] = useState("");
  const [deviceToAssign, setDeviceToAssign] = useState(null);
  const [radioChannel, setRadioChannel] = useState("");
  const [locations, setLocations] = useState("");
  const [department, setDepartment] = useState("");
  const [errorText, setErrorText] = useState("");

  function handleCancelBttnClick() {
    onCancel();
  }

  function resetForm() {
    setTabValue(0);
    setIsContractor("false");
    setCompany("");
    setName("");
    setEmail("");
    setUserCategory(null);
    setUserType("");
    setAssignDevice("");
    setRoleId("");
    setDeviceToAssign(null);
    setContractorOrEmployeeId("");
    setDepartment("");
    setRadioChannel("");
    setLocations("");
  }

  function handleFormSubmit(event) {
    event.preventDefault();
    event.stopPropagation();

    const user = {
      name: name.trim(),
      email: email,
      isContractor: isContractor === "true",
      categoryId: userCategory.id,
      userType: userType,
      roleId: roleId,
      contractorOrEmployeeId: contractorOrEmployeeId.trim(),
      deviceId: deviceToAssign && deviceToAssign.id,
      locations: locations.trim(),
      company: company.trim(),
      radioChannel: radioChannel.trim(),
      department: department.trim(),
    };

    dispatch(UsersReducerActions.createUser(user));
    setErrorText("");
    onConfirm(event);
  }

  function handleNextSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    if (validateEmail(email)) {
      setErrorText("");
      setTabValue(1);
    } else {
      setErrorText("Invalid Email");
    }
  }

  function handleRadioGroupChange(event, val) {
    setIsContractor(val);
  }

  useEffect(() => {
    resetForm();
  }, [show]);

  if (!show) {
    return null;
  }

  return (
    <Dialog open={show} onClose={onCancel} PaperProps={{ className: classes.dialogPaper }}>
      <DialogTitle className={dialogStyles.dialogTitle} disableTypography onClose={onCancel}>
        <span>Add New User</span>
      </DialogTitle>
      <FirstTabPanel
        classes={classes}
        tabValue={tabValue}
        isContractor={isContractor}
        company={company}
        contractorOrEmployeeId={contractorOrEmployeeId}
        name={name}
        email={email}
        errorText={errorText}
        setName={setName}
        setEmail={setEmail}
        setErrorText={setErrorText}
        handleNextSubmit={handleNextSubmit}
        handleCancelBttnClick={handleCancelBttnClick}
        handleRadioGroupChange={handleRadioGroupChange}
        setContractorOrEmployeeId={setContractorOrEmployeeId}
        setCompany={setCompany}
        site={site}
        setRadioChannel={setRadioChannel}
        setDepartment={setDepartment}
        setLocations={setLocations}
        locations={locations}
        department={department}
        radioChannel={radioChannel}
      />
      <SecondTabPanel
        tabValue={tabValue}
        classes={classes}
        handleFormSubmit={handleFormSubmit}
        userCategory={userCategory}
        setRoleId={setRoleId}
        setUserCategory={setUserCategory}
        userCategories={userCategories}
        userRoles={userRoles}
        userType={userType}
        setUserType={setUserType}
        handleCancelBttnClick={handleCancelBttnClick}
        roleId={roleId}
        name={name}
        setTabValue={setTabValue}
        setAssignDevice={setAssignDevice}
        assignDevice={assignDevice}
        deviceToAssign={deviceToAssign}
        setDeviceToAssign={setDeviceToAssign}
        siteID={siteID}
        site={site}
      />
    </Dialog>
  );
};
