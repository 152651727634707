import jwt_decode from "jwt-decode";
import { AuthAPI } from "../api";
import Oidc from "oidc-client";

const configuration = {
  client_id: process.env.REACT_APP_OIDC_CLIENT_ID,
  authority: process.env.REACT_APP_OIDC_AUTHORITY,
  redirect_uri: window.location.origin,
  response_type: "code",
  post_logout_redirect_uri: window.location.origin,
  scope: "openid",
  revokeAccessTokenOnSignout: true,
  loadUserInfo: true,
};

class AuthService {
  constructor(dispatch) {
    this.oidcManager = new Oidc.UserManager(configuration);
    Oidc.Log.logger = console;
    this.dispatch = dispatch;
    this.refreshTokenPromise = null;
  }

  getTokenByKey(key) {
    return localStorage.getItem(key);
  }

  checkIfUserLoggedIn() {
    const accessToken = this.getTokenByKey("sitewise_access_token");
    const refreshToken = this.getTokenByKey("sitewise_refresh_token");
    if (accessToken && refreshToken) {
      return true;
    }

    return false;
  }

  getSitewiseUser() {
    const user = localStorage.getItem("sitewise_user");
    if (user) {
      return JSON.parse(user);
    } else {
      return null;
    }
  }

  getUserInfo() {
    const user = localStorage.getItem("sitewise_user_info");
    if (user) {
      return JSON.parse(user);
    } else {
      return null;
    }
  }

  async refreshTokens(accessToken, refreshToken, siteLocationId) {
    if (this.refreshTokenPromise) {
      return this.refreshTokenPromise;
    } else {
      this.refreshTokenPromise = new Promise(async (resolve, reject) => {
        try {
          const response = await AuthAPI.refreshToken(accessToken, refreshToken, siteLocationId);
          this.setTokens(response);
          this.refreshTokenPromise = null;
          return resolve(response);
        } catch (error) {
          this.logout();
          return reject(error);
        }
      });
      return this.refreshTokenPromise;
    }
  }

  setTokens(tokens) {
    const accessToken = jwt_decode(tokens.accessToken);
    localStorage.setItem("sitewise_user", JSON.stringify(accessToken));
    localStorage.setItem("sitewise_access_token", tokens.accessToken);
    localStorage.setItem("sitewise_refresh_token", tokens.refreshToken);
  }

  setUserInfo(payload) {
    if (payload) {
      localStorage.setItem("sitewise_user_info", JSON.stringify(payload));
    }
  }

  getDecodedTokenPayload(accessToken) {
    return jwt_decode(accessToken);
  }

  checkIfTokenValid() {
    const user = this.getSitewiseUser();
    let result;
    if (!user) {
      result = false;
    }

    if (user) {
      const { exp } = user;
      const now = Math.floor(Date.now() / 1000);
      result = exp >= now ? true : false;
    }

    return result;
  }

  async getValidAccessToken(getValidAccessToken = true) {
    return new Promise(async (resolve, reject) => {
      const accessToken = this.getTokenByKey("sitewise_access_token");
      const refreshToken = this.getTokenByKey("sitewise_refresh_token");
      const siteID = this.getTokenByKey("SITE_ID");
      if (getValidAccessToken && this.checkIfTokenValid()) {
        return resolve(accessToken);
      } else if (accessToken && refreshToken) {
        try {
          const response = await this.refreshTokens(accessToken, refreshToken, siteID);
          return resolve(response.accessToken);
        } catch (error) {
          this.logout();
          return reject(error);
        }
      } else {
        return resolve(null);
      }
    });
  }

  async getValidAccessTokenForSite(siteID) {
    return new Promise(async (resolve, reject) => {
      const accessToken = this.getTokenByKey("sitewise_access_token");
      const refreshToken = this.getTokenByKey("sitewise_refresh_token");
      if (accessToken && refreshToken) {
        try {
          const response = await this.refreshTokens(accessToken, refreshToken, siteID);
          return resolve(response.accessToken);
        } catch (error) {
          this.logout();
          return reject(error);
        }
      } else {
        return resolve(null);
      }
    });
  }

  logout() {
    localStorage.removeItem("SITE_ID");
    localStorage.removeItem("sitewise_user");
    localStorage.removeItem("sitewise_user_info");
    localStorage.removeItem("sitewise_access_token");
    localStorage.removeItem("sitewise_refresh_token");
    window.location.reload(true);
  }

  async getAuthorizeHeader() {
    const tokenToUse = await this.getValidAccessToken();
    const headers = {};
    if (tokenToUse) {
      headers["Authorization"] = `Bearer ${tokenToUse}`;
    } else {
      window.location.href = "/";
    }
    return headers;
  }
}

export const authService = new AuthService();
