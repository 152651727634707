import React from "react";
import { makeStyles, IconButton, Tooltip } from "@material-ui/core";
import { ICONS } from "../../../";
import { useSelector } from "react-redux";
import { plotPlanImageLayerJson } from "../../../../reducers";
const useStyles = makeStyles((theme) => ({
  plotPlanWrapper: {
    position: "absolute",
    bottom: "112px",
    right: "15px",
    background: theme.palette.common.black,
    display: "flex",
  },

  mapImageWrapper: {
    position: "absolute",
    bottom: "145px",
    right: "15px",
    background: theme.palette.common.black,
    display: "flex",
  },
}));

export const PlotPlan = (props) => {
  const classes = useStyles();
  const { showHidePlotPlan, showHideMapImageLayer } = props;
  const site = useSelector((state) => state.globalState.site);
  const siteName = site?.name.toLowerCase();
  const plotPlan = "plotplan";
  const imageLayer = "imagelayer";

  const PlotPlanID = plotPlanImageLayerJson[siteName]?.layers.filter((index) => {
    return index.plan === plotPlan;
  });
  const ImageLayerID = plotPlanImageLayerJson[siteName]?.layers.filter((index) => {
    return index.plan === imageLayer;
  });

  return (
    <>
      {PlotPlanID[0]?.showIcon && (
        <div className={classes.plotPlanWrapper}>
          <Tooltip title={"Show/Hide Plot Plan"}>
            <IconButton
              aria-label="plot plan"
              style={{
                padding: "5px 4px",
              }}
              onClick={() => {
                showHidePlotPlan(PlotPlanID[0].layerProperties?.id);
              }}
            >
              <ICONS.StreetviewIcon />
            </IconButton>
          </Tooltip>
        </div>
      )}
      {ImageLayerID[0]?.showIcon && (
        <div className={classes.mapImageWrapper}>
          <Tooltip title={"Show/Hide Map Layer"}>
            <IconButton
              aria-label="map layer"
              style={{
                padding: "5px 4px",
              }}
              onClick={() => {
                showHideMapImageLayer(ImageLayerID[0].layerProperties?.id);
              }}
            >
              <ICONS.MapIcon />
            </IconButton>
          </Tooltip>
        </div>
      )}
    </>
  );
};