import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Dialog,
  DialogTitle,
  FormControl,
  Button,
  Box,
  TextField,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { UserTypeDropdown, useDialogStyles } from "../../../../../commons";
import { MessagesReducerActions } from "../../../../../reducers";

const useStyles = makeStyles(() => ({
  formControl: {
    width: "100%",
    marginBottom: "32px",
  },
  formControlActions: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
}));

function getHelperText(message) {
  return `(${message ? message.length : 0}/120)`;
}

export const SendMessageZonesAlertsDialog = ({ show, onCancel }) => {
  const classes = useStyles();
  const dialogStyles = useDialogStyles();
  const dispatch = useDispatch();
  const showDialoginZonesAlerts = useSelector(
    (state) => state.zonesScreen.DeviceInfo?.manufacturerId
  );
  const deviceID = useSelector((state) => state.zonesScreen.DeviceInfo?.id);
  const isAlerts = useSelector((state) => state.zonesScreen.DeviceInfo?.isAlerts);
  const [message, setMessage] = useState("");

  const [dialogState, setDialogState] = useState({
    userTypes: [],
    userRoleIds: [],
    userCategoryIds: [],
    zoneIds: [],
    zoneCategoryIds: [],
    deivceIds: [],
    sent: false,
  });

  function handleCancelBttnClick() {
    onCancel();
    setMessage(isAlerts?"HELP is on the way!":"");
  }

  function onSendMessageBttnClick() {
    setDialogState({ ...dialogState, sent: true });
    dispatch(
      MessagesReducerActions.sendBulkDeviceMessages({
        message: message,
        userTypes: dialogState.userTypes,
        userRoleIds: dialogState.userRoleIds,
        userCategoryIds: dialogState.userCategoryIds,
        zoneIds: dialogState.zoneIds,
        zoneCategoryIds: dialogState.zoneCategoryIds,
        deivceIds: [deviceID],
      })
    ).then(() => {
      setDialogState({ ...dialogState, sent: false });
      setMessage(isAlerts?"HELP is on the way!":"");
    });
  }

  useEffect(() => {
    if (isAlerts) {
      setMessage("HELP is on the way!");
    } else {
      setMessage("");
    }
  }, [isAlerts]);

  return (
    <Dialog
      open={show}
      onClose={onCancel}
      PaperProps={{ className: dialogStyles.dialogPaper }}
      data-testid="send-message-dialog"
    >
      <Box className={dialogStyles.dialogContentWrapper}>
        <DialogTitle
          className={dialogStyles.dialogTitle}
          disableTypography
          id="responsive-dialog-title"
          onClose={onCancel}
        >
          <span>Send Message</span>
        </DialogTitle>
        <form className={classes.form} autoComplete="off">
          {showDialoginZonesAlerts && (
            <FormControl variant="filled" className={classes.formControl}>
              <TextField
                variant="filled"
                value={showDialoginZonesAlerts}
                label="Device ID"
                disabled
              />
            </FormControl>
          )}

          <FormControl variant="filled" className={classes.formControl}>
            <TextField
              autoFocus
              multiline
              rows={3}
              rowsMax={3}
              label="Message"
              variant="filled"
              value={message}
              helperText={getHelperText(message)}
              onChange={(event) => {
                setMessage(event.target.value.slice(0, 120));
              }}
              onFocus={function(e) {
                var val = e.target.value;
                e.target.value = '';
                e.target.value = val;
            }}
            />
          </FormControl>

          <FormControl variant="filled" className={classes.formControlActions}>
            <Button
              color="primary"
              variant="outlined"
              onClick={handleCancelBttnClick}
              className={dialogStyles.dialogActionBttn}
              disabled={dialogState.sent}
            >
              Cancel
            </Button>

            <Button
              disabled={!message || dialogState.sent}
              variant="contained"
              color="primary"
              style={{ marginLeft: "10px" }}
              onClick={onSendMessageBttnClick}
              className={dialogStyles.dialogActionBttn}
            >
              Send
            </Button>
          </FormControl>
        </form>
      </Box>
    </Dialog>
  );
};
