import React, {useContext, useEffect, useState, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Grid, makeStyles, Paper } from "@material-ui/core";
import { DocumentTitle, BackBttn, useCommonStyles, RemoveDialog, UserPermissionContext } from "../../../../commons";
import { SiteConfigForms, EditSiteName, SiteCordsForm, SiteCordsMap } from "./components";
import { SitesReducerActions, SITES_REDUCER_TYPES } from "../../../../reducers";
import { SiteCordsMapContainer } from "./components/site-cords-map/site-cords-map-container";

function _goToSubsite(_map, _mapSelectedSubSite) {
  if (_map?.renderMap && _mapSelectedSubSite) {
    _map.goToSubSite(_mapSelectedSubSite);
  }
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    position: "relative",
    justifyContent: "center",
    top: "20%",
  },
  paper: {
    minWidth: "684px",
    height: "431px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "25px",
    backgroundColor: theme.palette.base.elevation_1,
  },
  message: {
    fontSize:"18px",
    fontFamily:"ShellBold"
  }
}));

function backToSites(history, dispatch){
  return(
    <BackBttn
    show={true}
    label={"Back to Full List"}
    onClick={() => {
      history.push("/sites");
      dispatch({ type: SITES_REDUCER_TYPES.SET_CURRENT_SITE, payload: null });
      dispatch({ type: SITES_REDUCER_TYPES.RESET_CURRENT_SUBSITE, payload: null });
      dispatch({ type: SITES_REDUCER_TYPES.SET_PAGE_INDEX, payload: 0 });
      dispatch({ type: SITES_REDUCER_TYPES.SET_MAP_SELECTED_SUBSITE, payload: null });
    }}
  />
  )
}

function siteCordsForms({fullMapView,currentSite,showDefaultSiteForm,currentSubSite,
  newSiteFormVisible, goToSubSite, updateSite, mapSelectedSubSite, showMapInfoWidget,classes,commonStyles,isPageLoading}){
  return(
    <div
    style={{
      width: "100%",
      height: "70vh",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    }}
  >
 {!currentSite ? (
 <Grid className={commonStyles.page_wrapper}>
     <Paper className={classes.paper}>
  <div className={classes.message}>
   {!isPageLoading && !currentSite ? "YOU DO NOT HAVE PERMISSION TO VIEW THIS PAGE" :""}
    </div>
  </Paper></Grid>
  ): <> <div style={{ flexBasis: fullMapView ? "33%" : "66%" }}>
  <SiteCordsForm
    currentSite={currentSite}
    showDefaultSiteForm={showDefaultSiteForm}
    currentSubSite={currentSubSite}
    newSiteFormVisible={newSiteFormVisible}
    goToSubSite={goToSubSite}
    updateSite={updateSite}
    mapSelectedSubSite={mapSelectedSubSite}
    showMapInfoWidget={showMapInfoWidget}
  />
  </div>
<div style={{ flexBasis: fullMapView ? "66%" : "33%" }}>
  <SiteCordsMapContainer />
</div>
</>
 }
</div>
)
}

const SiteEditScreen = () => {
  const commonStyles = useCommonStyles();
  const [map, setMap] = useState(null);
  const { siteId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const currentSite = useSelector((state) => state.sitesScreen.currentSite);
  const showDefaultSiteForm = useSelector((state) => state.sitesScreen.showDefaultSiteForm);
  const currentSubSite = useSelector((state) => state.sitesScreen.currentSubSite);
  const removeSubsiteDialog = useSelector((state) => state.sitesScreen.dialogs.removeSubsiteDialog);
  const fullMapView = useSelector((state) => state.sitesScreen.fullMapView);
  const pageIndex = useSelector((state) => state.sitesScreen.pageIndex);
  const newSiteFormVisible = useSelector((state) => state.sitesScreen.newSiteFormVisible);
  const showMapInfoWidget = useSelector((state) => state.sitesScreen.mapState.showMapInfoWidget);
  const mapSelectedSubSite = useSelector((state) => state.sitesScreen.mapState.selectedSubSite);
  const isPageLoading = useSelector((state) => state.globalState.isPageLoading);
  const { Permissions } = useContext(UserPermissionContext);
  const classes = useStyles();

  function updateSite(_currentSubSite) {
    dispatch(SitesReducerActions.updateSiteConfig(_currentSubSite));
  }

  function goToSubSite(subSite) {
    dispatch({ type: SITES_REDUCER_TYPES.SET_CURRENT_SUBSITE, payload: subSite });
  }

  const onMapLoadCb = useCallback(
    (_currentSite) => {
      if (_currentSite?.subSites[0]) {
        dispatch({
          type: SITES_REDUCER_TYPES.SET_MAP_SELECTED_SUBSITE,
          payload: _currentSite.subSites[0],
        });
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (map?.renderMap) {
      map.renderMap(currentSite, currentSubSite, showMapInfoWidget);
    }
  }, [map, currentSite, currentSubSite, showMapInfoWidget]);

  useEffect(() => {
    _goToSubsite(map, mapSelectedSubSite);
  }, [map, mapSelectedSubSite]);

  useEffect(() => {
    if (siteId && pageIndex === 0) {
      dispatch(SitesReducerActions.getSiteBySiteId(siteId)).then(() => {
        setMap(
          new SiteCordsMap({
            onMapLoadCb: onMapLoadCb,
          })
        );
      });
    }
  }, [siteId, dispatch, onMapLoadCb, pageIndex]);

  useEffect(() => {
    return () => {
      dispatch({ type: SITES_REDUCER_TYPES.SET_PAGE_INDEX, payload: 0 });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DocumentTitle title="Sitewise | Site Config Edit" />
      <Grid className={commonStyles.page_wrapper}>
      {backToSites(history, dispatch)}
      <EditSiteName currentSite={currentSite} />

      {pageIndex === 0 ? (
        siteCordsForms({fullMapView,currentSite,showDefaultSiteForm,currentSubSite,
        newSiteFormVisible, goToSubSite, updateSite, mapSelectedSubSite, showMapInfoWidget,classes,commonStyles,isPageLoading})

        ) : (
          <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div style={{ flex: 2 }}>
            <SiteConfigForms currentSite={currentSite} />
          </div>
          </div>
      )}
    </Grid>
      <RemoveDialog
        title="Are you sure you want to remove sub site area?"
        show={removeSubsiteDialog.show}
        fullMapView={true}
        onConfirm={() => {
          dispatch(SitesReducerActions.deleteSubSite());
        }}
        onCancel={(event) => {
          event.stopPropagation();
          dispatch({
            type: SITES_REDUCER_TYPES.SET_REMOVE_SUBSITE_DIALOG_DISPLAY,
            payload: {
              show: false,
              id: null,
            },
          });
        }}
      />
    </>
  );
};

export default SiteEditScreen;
