import { MapUtilsV2, MAP_ICONS } from "../index";

export const renderUserDevicesOnMap = ({
  userDevices,
  Point,
  Graphic,
  userDevicesGraphicLayer,
}) => {
  userDevices.forEach((userDevice) => {
    const { alerts } = userDevice;
    const { location } = userDevice.status;

    const point = new Point({
      latitude: location.latitude,
      longitude: location.longitude,
    });

    let pointGraphicSymbol = null;
    let pointGraphic = null;

    if (alerts?.length) {
      const sortedAlerts = MapUtilsV2.getAlertPriorityArr(alerts);
      const highestPriorityAlert = sortedAlerts[0];

      if (highestPriorityAlert.alertType === "GasDetected") {
        pointGraphicSymbol = {
          type: "picture-marker",
          url: MAP_ICONS.GAS_DETECTED_BLINKER,
          width: "40px",
          height: "40px",
        };
      } else {
        pointGraphicSymbol = {
          type: "picture-marker",
          url: MAP_ICONS.ALERT_DETECTED_BLINKER,
          width: "40px",
          height: "40px",
        };
      }

      pointGraphic = new Graphic({
        geometry: point,
        symbol: pointGraphicSymbol,
        attributes: {
          type: "userDevice",
          UserCategory: userDevice.currentAssignedUser?.userCategory?.name,
          Role: userDevice.currentAssignedUser?.role?.role,
          UserType: userDevice.currentAssignedUser?.userType,
          ...userDevice,
        },
      });
    } else {
      var pathDef="";
      switch(userDevice.manufacturer.toLowerCase()){
        case "blackline":
        pathDef = MAP_ICONS.PERSON_ICON;
        break;
        case "realwear inc.":
        pathDef = MAP_ICONS.REALWARE_ICON;
        break;
        case "apple":
        pathDef = MAP_ICONS.IPHONE_ICON;
        break;
        default:
        pathDef = MAP_ICONS.ANDROID_ICON;
        break;
      }
      pointGraphicSymbol = {
        type: "simple-marker",
        style: "path",
        size: "28px",
        outline: {
          color: [255, 255, 255],
          width: 0,
        },
        path: pathDef,
      };

      if (userDevice.currentAssignedUser?.userCategory) {

        pointGraphicSymbol.color =
        userDevice.currentAssignedUser.userCategory.name === "Emergency Responder"
          ? "#13BC86"
          : "white";

        pointGraphic = new Graphic({
          geometry: point,
          symbol: pointGraphicSymbol,
          attributes: {
            type: "userDevice",
            UserCategory: userDevice.currentAssignedUser?.userCategory?.name,
            Role: userDevice.currentAssignedUser?.role?.role,
            UserType: userDevice.currentAssignedUser?.userType,
            ...userDevice,
          },
        });
      } else {

        pointGraphicSymbol.color = "white";

        pointGraphic = new Graphic({
          geometry: point,
          symbol: pointGraphicSymbol,
          attributes: {
            type: "userDevice",
            ...userDevice,
          },
        });
      }
    }

    pointGraphic && userDevicesGraphicLayer.add(pointGraphic);
  });
};
