import React from "react";
import { renderToString } from "react-dom/server";
import { DeviceBatteryIcon } from "../../device-battery-icon";
import { MapUtilsV2 } from "../map-utils";
import { setGasTypeEnabled } from "../../../utils";

const showManfactuerId = (showName, batteryHtmlString, manufacturerId, manufacturer, model) => {
  const devicesModel = model ? `${manufacturer} - ${model}` : manufacturer;
  return showName
    ? `<div class="user_id">
    ${devicesModel} #${manufacturerId} ${batteryHtmlString}
  </div>`
    : ` <div></div>`;
};

const getAlerts = (alert, viewAlert, gasTypeEnabled) => {
  let response = "";
  if (alert && viewAlert) {
    if (alert.includes("gas")) {
      if (gasTypeEnabled) {
        response = `<div class="alerts_wrapper">${alert}</div>`;
      }
    } else {
      response = `<div class="alerts_wrapper">${alert}</div>`;
    }
  }
  return response;
};

export const renderMapTooltipMarkup = (graphicsData, store, mapView, viewAlerts) => {
  let userName;

  if (graphicsData.graphic.attributes.currentAssignedUser) {
    userName = graphicsData.graphic.attributes.currentAssignedUser.name;
  }

  const {
    alerts,
    UserCategory,
    Role,
    UserType,
    manufacturerId,
    manufacturer,
    model,
    status: {
      battery: { internalLevel: level },
      isOnline,
      sensors,
      beacon
    },
    lastKnownLocationTime,
    id
  } = graphicsData.graphic.attributes;

  mapView.popup.location = graphicsData.mapPoint;

  const batteryHtmlString = renderToString(
    <DeviceBatteryIcon manufacturerId={manufacturerId} level={level} isOnline={isOnline} />
  );

  const showName =
    store.getState().globalState.site.privacySettings &&
     store.getState().globalState.site.privacySettings.mapShowNameAlways && userName;
  
  const showGasType = setGasTypeEnabled(store.getState().globalState.site.deviceConfiguration);

  if (showName) {
    mapView.popup.title = `${userName}`;
  } else {
    mapView.popup.title = `#${manufacturerId} ${batteryHtmlString}`;
  }

  const showUserInfo = (userName) => {
    return userName ? `
    <hr>
    <div class="user_details_wrapper">
      <div>${UserCategory}</div>
      <div>${Role}</div>
      <div>${UserType}</div>
    </div>` : "";
  }

  const toolTipHtml = (alertsStr = "",lastKnownLocation = "",beacon= "") => {
    return `
    <div class="map_user_popup">
      ${showManfactuerId(showName, batteryHtmlString, manufacturerId, manufacturer, model)}
      ${lastKnownLocation}
      ${beacon}
      ${getAlerts(alertsStr, viewAlerts, showGasType)}
      ${showUserInfo(userName)}
    </div>`;
  };

  const editThisAction = {
    title: "Send message",
    id: "send-message",
    className: "esri-icon-right",
    manufacturerId:manufacturerId, 
    deviceID:id,
    isAlerts:alerts.length
};
mapView.popup.actions =[editThisAction];

  if (alerts.length) {
    if (userName) {
      mapView.popup.title = `${userName}`;
    }
    const alertsStr = MapUtilsV2.getAlertsName(alerts, sensors);
    const lastKnownLocation = MapUtilsV2.showLastKnownTimestamp(lastKnownLocationTime);
    const beaconID = MapUtilsV2.showBeaconID(beacon);
    mapView.popup.content = toolTipHtml(alertsStr,lastKnownLocation,beaconID);
  } else {
    const lastKnownLocation = MapUtilsV2.showLastKnownTimestamp(lastKnownLocationTime);
    const beaconID = MapUtilsV2.showBeaconID(beacon);
    mapView.popup.content = toolTipHtml("",lastKnownLocation,beaconID);
  }
};
