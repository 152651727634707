import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Paper, Tabs, Tab, Box } from "@material-ui/core";
import {
  TabPanel,
  a11yProps,
  UserPermissionContext,
  ICONS,
  useCommonStyles,
  RemoveDialog as RoleRemoveDialog,
  RemoveDialog as UserRemoveDialog,
  TwoActionsDialog as NewUserDialog,
} from "../../commons";
import { UsersTable, RolesTable } from "./tables";
import { UsersReducerActions, USERS_REDUCER_TYPES } from "../../reducers";
import {
  NewRoleDialog,
  BatchUploadDialog,
  ConfirmationBatchUploadDialog,
  NewUsersCreateDialog,
} from "./dialogs";

export const UsersManagement = () => {
  
  const dispatch = useDispatch();
  const commonStyles = useCommonStyles();
  const [tabValue, setTabValue] = useState(0);
  const { Permissions } = useContext(UserPermissionContext);

  const users = useSelector((state) => state.usersScreen.users);
  const showNewUserDialog = useSelector((state) => state.usersScreen.dialogs.showNewUserDialog);
  const showNewRoleDialog = useSelector((state) => state.usersScreen.dialogs.showNewRoleDialog);
  const showRemoveRoleDialog = useSelector(
    (state) => state.usersScreen.dialogs.showRemoveRoleDialog
  );

  const showRemoveUserDialog = useSelector(
    (state) => state.usersScreen.dialogs.showRemoveUserDialog
  );

  const siteID = useSelector((state) => state.globalState.siteID);
  const site = useSelector((state) => state.globalState.site);

  const showBatchUploadDialog = useSelector(
    (state) => state.usersScreen.dialogs.showBatchUploadDialog
  );

  const showNewUserCreateDialog = useSelector(
    (state) => state.usersScreen.dialogs.showNewUserCreateDialog
  );

  const userRoles = useSelector((state) => state.usersScreen.userRoles);

  const batchUploadResponse = useSelector((state) => state.usersScreen.batchUploadResponse);

  const handleTabClick = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleConfirm = (event) => {
    return null;
  };
  useEffect(() => {
    if (siteID) {
      if (tabValue === 0) {
        dispatch(UsersReducerActions.init());
      } else {
       
        dispatch(UsersReducerActions.getUserRoles());
      }
    }
  }, [siteID, tabValue, dispatch]);

  return (
    <Paper>
      <Box className={commonStyles.tabs_wrapper}>
        <Tabs
          value={tabValue}
          onChange={handleTabClick}
          aria-label="tabs"
          style={{ display: "flex", justifyContent: "space-between" }}
          classes={{
            root: commonStyles.tabs_root,
            indicator: commonStyles.tabs_indicator,
          }}
        >
          {Permissions.View_Users && (
            <Tab
              label={`Users`}
              {...a11yProps(0)}
              classes={{
                root: commonStyles.tab_root,
                selected: commonStyles.tab_selected,
              }}
            />
          )}

          {Permissions.View_Roles && (
            <Tab
              label={`Roles`}
              {...a11yProps(1)}
              classes={{
                root: commonStyles.tab_root,
                selected: commonStyles.tab_selected,
              }}
            />
          )}
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={0}>
        {site ? <UsersTable data={users} site={site} /> : null}
        <UserRemoveDialog
          title="Are you sure you want to remove User?"
          show={showRemoveUserDialog}
          onConfirm={() => {
            dispatch(UsersReducerActions.deleteUser());
          }}
          onCancel={() => {
            dispatch({
              type: USERS_REDUCER_TYPES.SET_REMOVE_USER_DIALOG_DISPLAY,
              payload: false,
            });
          }}
        />

        <NewUserDialog
          title="Add a New User"
          show={showNewUserDialog}
          showDialogCloseBttn={true}
          bttnOne={{
            label: "Add Single User",
            Icon: ICONS.Person,
          }}
          bttnTwo={{
            label: "Batch Upload",
            Icon: ICONS.People,
          }}
          onConfirmActionOne={() => {
            dispatch({
              type: USERS_REDUCER_TYPES.SET_NEW_USER_CREATE_DIALOG,
              payload: true,
            });
            dispatch({
              type: USERS_REDUCER_TYPES.SET_NEW_USER_DIALOG_DISPLAY,
              payload: false,
            });
          }}
          onConfirmActionTwo={() => {
            dispatch({
              type: USERS_REDUCER_TYPES.SET_BATCH_UPLOAD_DIALOG_DISPLAY,
              payload: true,
            });
            dispatch({
              type: USERS_REDUCER_TYPES.SET_NEW_USER_DIALOG_DISPLAY,
              payload: false,
            });
          }}
          onCancel={() => {
            dispatch({
              type: USERS_REDUCER_TYPES.SET_NEW_USER_DIALOG_DISPLAY,
              payload: false,
            });
          }}
        />
        <NewUsersCreateDialog
          show={showNewUserCreateDialog}
          onConfirm={handleConfirm}
          onCancel={() => {
            dispatch({
              type: USERS_REDUCER_TYPES.SET_NEW_USER_CREATE_DIALOG,
              payload: false,
            });
          }}
          siteID={siteID}
          site={site}
        />
        <BatchUploadDialog
          show={showBatchUploadDialog}
          onConfirm={() => {}}
          onCancel={() => {
            dispatch({
              type: USERS_REDUCER_TYPES.SET_BATCH_UPLOAD_DIALOG_DISPLAY,
              payload: false,
            });
          }}
        />

        <ConfirmationBatchUploadDialog
          data={batchUploadResponse}
          show={!!batchUploadResponse}
          onCancel={() => {
            dispatch({
              type: USERS_REDUCER_TYPES.SET_BATCH_UPLOAD_RESPONSE,
              payload: null,
            });
          }}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <RolesTable data={userRoles} />
        <NewRoleDialog
          title="Create New Role"
          show={showNewRoleDialog}
          onCancel={() => {
            dispatch({
              type: USERS_REDUCER_TYPES.SET_NEW_ROLE_DIALOG_DISPLAY,
              payload: false,
            });
          }}
        />

        <RoleRemoveDialog
          title="Are you sure you want to remove Role?"
          show={showRemoveRoleDialog}
          onConfirm={() => {
            dispatch(UsersReducerActions.deleteRole());
          }}
          onCancel={() => {
            dispatch({
              type: USERS_REDUCER_TYPES.SET_REMOVE_ROLE_DIALOG_DISPLAY,
              payload: false,
            });
          }}
        />
      </TabPanel>
    </Paper>
  );
};
