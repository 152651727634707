import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormControl, makeStyles, Button } from "@material-ui/core";
import { UserCategoryPermissionTable } from "./user-category-permission-table";
import { NewUserCategoryDialog } from "./new-user-category-dialog/new-user-category-dialog";
import { FONTS } from "../../../../utils";
import { SITES_REDUCER_TYPES } from "../../../../reducers";

const useStyles = makeStyles(() => ({
  content: {
    paddingTop: "30px",
  },
  description: {
    ...FONTS.B5_Bold_12,
  },
  buttonContainer: {
    justifyContent: "flex-end",
    display: "flex",
  },
  button: {
    margin: "10px",
    border: "1px solid",
    color: "#FBCE07",
    ...FONTS.Button1_Bold_16,
  },
  primaryButton: {
    backgroundColor: "#FBCE07",
    margin: "10px",
    ...FONTS.Button1_Bold_16,
  },
}));

const spv = "sitewise.Permissions.View"
const permissions = {
  viewUsers: `${spv}.Users`,
  viewRoles: `${spv}.Roles`,
  viewZones: `${spv}.Zones`,
  viewDevices: `${spv}.Devices`,
  viewBulkMessages: `${spv}.BulkMessages`
}

const dependencyMatrix = {
  "sitewise.Permissions.Manage.Users": [
    permissions.viewUsers,
    permissions.viewRoles,
  ],
  "sitewise.Permissions.Delete.Users": [permissions.viewUsers],
  "sitewise.Permissions.Manage.Roles": [permissions.viewRoles],
  "sitewise.Permissions.Manage.Zones": [permissions.viewZones],
  "sitewise.Permissions.Manage.Devices": [permissions.viewDevices],
  "sitewise.Permissions.View.Alerts": [permissions.viewDevices],
  "sitewise.Permissions.Manage.Alerts": [permissions.viewAlerts],
  "sitewise.Permissions.Manage.BulkMessages": [
    permissions.viewUsers,
    permissions.viewRoles,
    permissions.viewDevices,
    permissions.viewZones,
    permissions.viewBulkMessages,
  ],
};

const suggestionsMatrix = {
  "sitewise.Permissions.View.Zones": [
    permissions.viewDevices,
    permissions.viewAlerts,
  ],
  "sitewise.Permissions.Manage.Devices": [
    permissions.viewUsers,
    permissions.viewRoles,
  ],
  "sitewise.Permissions.View.Alerts": [permissions.viewZones],
};

export const UserCategoryPermissionsForm = (props) => {
  const { navigateToSites, currentSite, setActive } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const userCategories = useSelector((state) => state.sitesScreen.currentUserCategories);
  const addNewCategoryDialogDisplay = useSelector(
    (state) => state.sitesScreen.dialogs.createUserCategory
  );

  return (
    <>
      <FormControl style={{ width: "100%" }}>
        <div className={classes.description}>
          {" "}
          VIEW AND CREATE NEW USER CATEGORIES WITH DIFFERENT PERMISSIONS ON SITEWISE
        </div>
        <div className={classes.content}>
          <UserCategoryPermissionTable
            data={userCategories}
            addNewCategoryDialogDisplay={addNewCategoryDialogDisplay}
            dependencyMatrix={dependencyMatrix}
            suggestionsMatrix={suggestionsMatrix}
          />
        </div>
      </FormControl>
      <NewUserCategoryDialog
        show={addNewCategoryDialogDisplay}
        siteID={currentSite.id}
        onCancel={() => {
          dispatch({
            type: SITES_REDUCER_TYPES.SET_CREATE_NEW_USER_CATEGORY_DIALOG,
            payload: false,
          });
        }}
        dependencyMatrix={dependencyMatrix}
        suggestionsMatrix={suggestionsMatrix}
      />
      <div className={classes.buttonContainer}>
        <Button
          size="medium"
          variant="outlined"
          className={classes.button}
          onClick={() => {
            navigateToSites();
          }}
        >
          Back to Site Coordinates
        </Button>
        <Button
          size="medium"
          variant="outlined"
          className={classes.button}
          onClick={() => {
            navigateToSites();
          }}
        >
          Cancel
        </Button>
        <Button
          size="medium"
          disabled={false}
          variant="contained"
          className={classes.primaryButton}
          onClick={() => {
            setActive(3);
          }}
        >
          Next
        </Button>
      </div>
    </>
  );
};
