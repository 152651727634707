export const zoomInfoMapComponent = (previewCB, cancelCB, fullMapView) => {
  const mapActionCMP = document.createElement("div");
  mapActionCMP.innerHTML = `<div style="display:flex; flex-direction:column;">
    <div style="background-color:white;padding: 10px; font-weight: normal; border-radius: 10px;">
        <span style="color:black">Please adjust zoom level to desired amount and save.</span>
    </div>
    <div style="margin-top: 10px;">
        <button id="preview-action-bttn" style="padding: 10px; background-color:white; border-radius: 10px; border: none; font-weight: bold;">${
          fullMapView ? "Preview Small Map" : "Preview Large Map"
        }</button>
        <button id="cancel-action-bttn" style="padding: 10px; background-color:white; border-radius: 10px; border: none; font-weight: bold;">Cancel</button>
    </div>
  </div>`;
  mapActionCMP.id = "zoomInfoMapComponent";
  mapActionCMP.querySelector("#preview-action-bttn").onclick = previewCB;
  mapActionCMP.querySelector("#cancel-action-bttn").onclick = cancelCB;
  return mapActionCMP;
};
