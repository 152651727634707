import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { FONTS } from "../../utils";
import { AuthReducerActions } from "../../reducers";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { ICONS } from "../../commons";

const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  menuItem: {
    paddingLeft: "15px",
    "&:hover": {
      backgroundColor: "#373746",
    },
    "&:active": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  menuItemActive: {
    color: "white",
    borderLeft: `2px solid ${theme.palette.primary.main}`,
  },
  logoutBtn: { padding: "12px", ...FONTS.B4_BOLD_14, color: "#FBCE07" },
}));

export const DrawerNavBar = (props) => {
  const { showDrawerMenu, setShowDrawerMenu, menuItemClick } = props;
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const userInfo = localStorage.sitewise_user_info ? JSON.parse(localStorage.sitewise_user_info) : "user";

  const toggleDrawer = (open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }

    setShowDrawerMenu(open);
  };

  function handleUserLogout() {
    dispatch(AuthReducerActions.logout());
  }

  const list = () => (
    <div
      className={clsx(classes.list)}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {props.MainMenuListJSON.map((item) => {
          const isActiveLink = location.pathname.includes(item.href);
          return (
            <ListItem button key={item.label} onClick={menuItemClick(item.href)}>
              <ListItemText
                className={`${classes.menuItem} ${isActiveLink ? classes.menuItemActive : ""}`}
                primary={item.label}
              />
            </ListItem>
          );
        })}
      </List>
      <Divider />
      <List>
        <ListItem key={userInfo.name}>
          <ListItemIcon>
            <ICONS.PersonOutline />
          </ListItemIcon>
          <ListItemText primary={userInfo.name} />
        </ListItem>
        <ListItem key={userInfo.role}>
          <ListItemText primary={userInfo.role} />
        </ListItem>
        <ListItem key={userInfo.category}>
          <ListItemText primary={userInfo.category} />
        </ListItem>
        <ListItem key={userInfo.userType}>
          <ListItemText primary={userInfo.userType} />
        </ListItem>
        <Divider />
        <ListItem button >
          <ListItemText className={classes.logoutBtn} onClick={handleUserLogout} primary="LOG OUT" />
        </ListItem>
      </List>
    </div>
  );

  return (
    <React.Fragment>
        <Drawer anchor="left" open={showDrawerMenu} onClose={toggleDrawer(false)}>
          {list()}
        </Drawer>
    </React.Fragment>
  );
};
