import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  makeStyles,
  Paper,
  Table,
  TableFooter,
  TableBody,
  TableContainer,
  TablePagination,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { Row } from "./row";
import {
  useCustomTableHook,
  useCustomTableStyles,
  EnhancedTableHead,
  TwoActionsDialog,
  ICONS,
} from "../../../../../commons";
import { ZonesReducerActions, ZONE_REDUCER_TYPES } from "../../../../../reducers";

const headCells = [
  {
    id: "name",
    sortBy: "name",
    label: "Zone Name",
    width: "15%",
    content: (row) => row.name,
  },
  {
    id: "activeUsers",
    sortBy: "headcount",
    label: "Active Users",
    width: "5%",
    content: (row) => row.headcount,
  },
  {
    id: "alerts",
    sortBy: "alerts",
    label: "Alerts",
    width: "5%",
    content: (row) => row.alerts.length,
  },
  {
    id: "categories",
    sortBy: "categories",
    label: "Categories",
    width: "25%",
    content: (row) => row.categories,
  },
  {
    id: "createdTimestamp",
    sortBy: "createdTimestamp",
    label: "Created on",
    width: "10%",
    content: (row) => row.createdTimestamp,
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  buttonRoot: {
    border: "1px solid white",
  },
}));

export const CurrentZonesTable = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const tableStyles = useCustomTableStyles();
  const dispatch = useDispatch();
  const selectedZone = useSelector((state) => state.zonesScreen.selectedZone);
  const searchVal = useSelector((state) => state.zonesScreen.search);
  const editZoneDialog = useSelector((state) => state.zonesScreen.dialogs.showEditZoneDialog);
  const graphic = useSelector((state) => state.zonesScreen.mapEditShapeInProgress);
  const isTablet = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    data,
    isLargeMapView,
    onTableRowSelectedCb,
    onTableExpandBttnCb,
    selectZoneAndEdit,
    showFilterPanel,
    map,
  } = props;

  const {
    order,
    orderBy,
    page,
    rowsPerPage,
    setPage,
    getComparator,
    stableSortZone,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
  } = useCustomTableHook({
    data: data,
    defaultSortBy: "name",
    selectedZone: selectedZone,
    rowsPerPage: isTablet ? 3 : 10,
    isZoneRoute: true,
  });

  const checkIfRowSelected = (id) => selectedZone?.id === id;
  if (isLargeMapView) {
    // when true
    headCells.forEach(cellItem => {
      if (cellItem.sortBy === "name" || cellItem.sortBy === orderBy) {
        cellItem.isVisibleInRow = true;
      } else {
        cellItem.isVisibleInRow = false;
      }
      return cellItem;
    });
  }
  const noSaveActions = (onTableExpandBttnCb, graphic, map) => {
    if (graphic) {
      map.cancelCreateGraphic();
    }
    dispatch({
      type: ZONE_REDUCER_TYPES.SET_MAP_EDIT_SHAPE_IN_PROGRESS,
      payload: false,
    });
    dispatch({
      type: ZONE_REDUCER_TYPES.SET_EDIT_ZONE_DIALOG_DISPLAY,
      payload: false,
    });
    dispatch({
      type: ZONE_REDUCER_TYPES.SET_EDIT_FORM,
      payload: false,
    });
    onTableExpandBttnCb(null);
    dispatch({
      type: ZONE_REDUCER_TYPES.SET_SELECTED_ZONE,
      payload: null,
    });
  };

  const saveActions = (onTableExpandBttnCb, graphic, map) => {
    if (graphic) {
      map.updateGraphic();
    } else {
      dispatch(ZonesReducerActions.updateZone()).then(() => {
        dispatch({
          type: ZONE_REDUCER_TYPES.SET_EDIT_FORM,
          payload: false,
        });
        onTableExpandBttnCb && onTableExpandBttnCb(null);
      });
    }
    dispatch({
      type: ZONE_REDUCER_TYPES.SET_EDIT_ZONE_DIALOG_DISPLAY,
      payload: false,
    });
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer style={{ maxHeight: isTablet ? "24vh" : "60vh" }}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="Current Zones Table"
            stickyHeader
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={headCells}
              toShow={!isLargeMapView}
              tableWithRowActions={true}
              onChangeSearch={(event) => {
                setPage(0);
                dispatch({
                  type: ZONE_REDUCER_TYPES.SET_ZONES_SEARCH_VALUE,
                  payload: event.target.value,
                });
              }}
              toFade={showFilterPanel}
              searchLabel="Zone Name"
              searchVal={searchVal}
            />
            <TableBody>
              {stableSortZone(data, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((rowData) => {
                  const isItemSelected = checkIfRowSelected(rowData.id);
                  return (
                    <Row
                      key={`${rowData.id}`}
                      orderBy={orderBy}
                      isLargeMapView={isLargeMapView}
                      rowData={rowData}
                      onTableRowSelectedCb={onTableRowSelectedCb}
                      onTableExpandBttnCb={onTableExpandBttnCb}
                      headCells={headCells}
                      isItemSelected={isItemSelected}
                      selectZoneAndEdit={selectZoneAndEdit}
                    />
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TableFooter className={tableStyles.tabelFooterRoot} component="div">
          <div></div>
          <TablePagination
            component="div"
            classes={{
              root: tableStyles.tablePaginationRoot,
            }}
            rowsPerPageOptions={[]}
            labelRowsPerPage=""
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TableFooter>
      </Paper>
      {editZoneDialog ? (
        <TwoActionsDialog
          show={editZoneDialog}
          title={`Do you want to save changes made to ${selectedZone?.name}`}
          bttnOne={{ label: "Save", Icon: ICONS.Save }}
          bttnTwo={{ label: "Don't Save", Icon: ICONS.RemoveCircleOutline }}
          showDialogCloseBttn
          onCancel={() =>
            dispatch({
              type: ZONE_REDUCER_TYPES.SET_EDIT_ZONE_DIALOG_DISPLAY,
              payload: false,
            })
          }
          onConfirmActionOne={() => {
            saveActions(onTableExpandBttnCb, graphic, map);
          }}
          onConfirmActionTwo={() => {
            noSaveActions(onTableExpandBttnCb, graphic, map);
          }}
        />
      ) : null}
    </div>
  );
};
