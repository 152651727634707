import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardMedia,
  CardActionArea,
  CardActions,
  makeStyles,
  IconButton,
  Tooltip,
} from "@material-ui/core";

import { ICONS, UserPermissionContext, useCommonStyles } from "../../../../commons";
import {
  GLOBAL_REDUCER_TYPES,
  SITES_REDUCER_TYPES,
  AuthReducerActions
} from "../../../../reducers";
import { FONTS } from "../../../../utils";
import { useStaticMapApi } from "../../../../hooks";
import worldMapPlaceholder from "../../screens/site-edit-screen/components/worldMap.png";

const useStyles = makeStyles((theme) => ({
  card: {
    flexBasis: "22%",
    marginBottom: "40px",
    marginRight: "40px",
    background: "transparent",
    boxShadow: "none",
    height: "60%",
    [theme.breakpoints.down("sm")]: {
      flexBasis: "35%",
    },
  },
  media: {
    height: "98%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    '@media (max-height: 765px)': {
      height: "180px",
    },
    '@media (max-height: 535px)': {
      height: "100px",
    }
  },
  previewImage: {
    color: "#2b2b36",
    opacity: "0.8",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  siteName: {
    ...FONTS.H5_ShellBold_18,
    width: "50%",
    whiteSpace: "no-wrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    '@media (max-width: 1300px)': {
      fontSize: "14px",
      width: "40%",
    }
  },
  actionIcons: {
    marginLeft: '10px',
    '@media (max-width: 1300px)': {
      marginLeft: '1px',
      width: "25px",
    }
  },
  mediaContainer: {
    position: "relative",
    textAlign: "center",
  },
}));

function renderSiteCardActionArea({
  site,
  handleGoToSiteBttn,
  handleSiteEditBttn,
  renderDeleteIcon,
  classes,
  Permissions,
  dispatch,
}) {
  return (
    <CardActions style={{ display: "flex", justifyContent: "space-between", paddingTop: "15px" }}>
      <Tooltip title={site.name} placement="bottom-start">
        <span className={classes.siteName}>
          {site.name} {site.active ? null : "(Deactivated)"}
        </span>
      </Tooltip>
      {site.active ? (
        <div>
          <Tooltip title="Go to site">
            <IconButton
              size="small"
              color="primary"
              variant="outlined"
              className={classes.actionIcons}
              onClick={handleGoToSiteBttn}
            >
              <ICONS.ArrowForwardIcon />
            </IconButton>
          </Tooltip>
          {Permissions.Manage_Sites ? (
            <Tooltip title="Edit">
              <IconButton
                size="small"
                color="primary"
                variant="outlined"
                className={classes.actionIcons}
                onClick={handleSiteEditBttn}
              >
                <ICONS.Edit />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="View site settings">
            <IconButton
              size="small"
              color="primary"
              variant="outlined"
              className={classes.actionIcons}
              onClick={handleSiteEditBttn}
            >
              <ICONS.Visibility />
            </IconButton>
          </Tooltip>
          )

        }

          {Permissions.Manage_Sites && renderDeleteIcon({ site, dispatch, classes })}
        </div>
      ) : (
        <div>{Permissions.Manage_Sites && renderDeleteIcon({ site, dispatch })}</div>
      )}
    </CardActions>
  );
}

function renderDeleteIcon({ site, dispatch, classes }) {
  if (site.active) {
    return (
      <Tooltip title="Deactivate">
        <IconButton
          size="small"
          color="primary"
          variant="outlined"
          className={classes.actionIcons}
          onClick={() =>
            dispatch({
              type: SITES_REDUCER_TYPES.SET_DEACTIVATE_SITE_DIALOG,
              payload: {
                showInfoDialog: true,
                site: site,
                showConfirmDialog: false,
              },
            })
          }
        >
          <ICONS.Delete />
        </IconButton>
      </Tooltip>
    );
  } else {
    return (
      <Tooltip title="Activate">
        <IconButton
          size="small"
          color="primary"
          variant="outlined"
          style={{ marginLeft: "15px" }}
          onClick={() =>
            dispatch({
              type: SITES_REDUCER_TYPES.SET_REACTIVATE_SITE_DIALOG,
              payload: {
                showInfoDialog: true,
                site: site,
                showConfirmDialog: false,
              },
            })
          }
        >
          <ICONS.Restore />
        </IconButton>
      </Tooltip>
    );
  }
}

export const SiteCard = (props) => {
  const classes = useStyles();
  const commonStyles = useCommonStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [goToZone, setGoToZone] = useState(false);
  const { site } = props;
  const authorizedRoute = useSelector((state) => state.auth?.authorizedRoute);

  const { Permissions } = useContext(UserPermissionContext);
  const [img] = useStaticMapApi(site.subSites[0] ? site.subSites[0].extent : null, [
    site.subSites.length && JSON.stringify(site.subSites[0].extent),
  ]);

  function updateSiteInfo() {
    dispatch({ type: GLOBAL_REDUCER_TYPES.SET_SITE_ID, payload: site.id });
    dispatch(AuthReducerActions.updateSiteUserInfo());
  }

  async function handleGoToSiteBttn() {
    updateSiteInfo();
    setGoToZone(true);
  }

  async function handleSiteEditBttn() {
    updateSiteInfo();
    history.push(`/sites/${site.id}`);
  }

  useEffect(() => {
    if (goToZone) {
      history.push(authorizedRoute);
    }

    return () => {
      setGoToZone(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorizedRoute])

  return (
    <Card className={classes.card}>
      <CardActionArea
        className={classes.mediaContainer}
        style={{ pointerEvents: site.active ? "pointer" : "none" }}
        onClick={handleGoToSiteBttn}
      >
        <CardMedia
          style={{ opacity: site.active ? 1 : 0.3 }}
          className={classes.media}
          src={img ? img : worldMapPlaceholder}
          title={site.name}
          component="img"
        />
        {img ? null : <span className={classes.previewImage}>Loading Map Preview Image</span>}
      </CardActionArea>
      {renderSiteCardActionArea({
        site,
        commonStyles,
        handleGoToSiteBttn,
        handleSiteEditBttn,
        renderDeleteIcon,
        classes,
        Permissions,
        dispatch,
      })}
    </Card>
  );
};
