import React from "react";
import { Grid, Box } from "@material-ui/core";
import { UsersManagement } from "./users-management";
import { DocumentTitle, useCommonStyles } from "../../commons";

const UsersScreen = () => {
  const commonStyles = useCommonStyles();

  return (
    <>
      <DocumentTitle title="Sitewise | Users" />
      <Grid className={commonStyles.page_wrapper}>
        <Box className={commonStyles.page_header}>
          <span className={commonStyles.page_title}>User Management & Permissions</span>
        </Box>
        <UsersManagement />
      </Grid>
    </>
  );
};

export default UsersScreen;
