import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { makeStyles, Dialog, DialogTitle, FormControl, TextField, Button } from "@material-ui/core";
import { useDialogStyles } from "../../../../../commons";
import { CheckboxesForm } from "../checkboxes-form";
import { SitesReducerActions } from "../../../../../reducers";
import { FONTS } from "../../../../../utils";
import { ParagraphJSON } from "../../../../../commons/paragraphs";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    backgroundColor: theme.palette.base.elevation_1,
    padding: "20px",
    maxWidth: "660px",
  },
  description: {
    ...FONTS.B4_Reg_12,
  },
  sectionTitle: {
    ...FONTS.B5_Bold_12,
    marginTop: "28px",
  },
  formControl: {
    width: "100%",
    marginTop: "24px",
    marginBottom: "24px",
  },
  dialogActionButton: {
    maxWidth: "213px",
    width: "213px",
    height: "102px",
    display: "flex",
    flexDirection: "column",
    background: theme.palette.base.elevation_overlay_1,
    "&:hover": {
      color: theme.palette.warning.main,
    },
  },
  formControlActions: {
    flexDirection: "row",
    justifyContent: "flex-end",
    marginTop: "32px",
    display: "flex",
  },
}));

export const NewUserCategoryDialog = ({
  show,
  onCancel,
  onConfirm,
  siteID
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const dialogStyles = useDialogStyles();
  const [categoryName, setCategoryName] = useState("");
  const spv = "sitewise.Permissions.View";
  const viewPermissions = [`${spv}.Users`, `${spv}.Roles`, `${spv}.Zones`, `${spv}.Devices`, `${spv}.Alerts`, `${spv}.BulkMessages`, `${spv}.DashboardMessages`];
  const [list, setList] = useState(viewPermissions.slice());

  function handleCancelBttnClick() {
    setList(viewPermissions);
    onCancel();
  }

  function resetForm() {
    setCategoryName("");
    setList(viewPermissions);
  }

  function handleFormSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    dispatch(
      SitesReducerActions.addNewUserCategory({
        name: categoryName,
        siteLocationId: siteID,
        permissions: list,
      })
    );
    onConfirm && onConfirm(event);
  }

  function handleChange(e) {
    const name = e.target.name;
    if (list.includes(name)) {
      list.splice(list.indexOf(name), 1);
      setList([...list]);
    } else {
      setList([...list, name]);
    }
  }

  function setKeyObject(key, label) {
    return { key, label };
  }

  useEffect(() => {
    resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  if (!show) {
    return null;
  }

  return (
    <Dialog open={show} onClose={onCancel} PaperProps={{ className: classes.dialogPaper }}>
      <DialogTitle className={dialogStyles.dialogTitle} disableTypography onClose={onCancel}>
        <span>Add New User</span>
        <form onSubmit={handleFormSubmit} autocomplete="off">
          <FormControl required variant="filled" className={classes.formControl}>
            <TextField
              required
              id="filled-basic"
              label="Category Name"
              variant="filled"
              fullWidth={true}
              value={categoryName}
              onChange={(e) => setCategoryName(e.target.value)}
            />
          </FormControl>
          <span className={classes.description}>
            {ParagraphJSON.permissionsAttributesMessage}
          </span>
          <div className={classes.sectionTitle}>USER MANAGEMENT</div>
          <div style={{ display: "flex", marginTop: "4px" }}>
            <CheckboxesForm
              handleChange={handleChange}
              list={list}
              boxes={{
                label: "Users",
                keys: [
                  setKeyObject("sitewise.Permissions.View.Users", "View"),
                  setKeyObject("sitewise.Permissions.Manage.Users", "Edit"),
                  setKeyObject("sitewise.Permissions.Delete.Users", "Delete"),
                ],
              }}
            />

            <CheckboxesForm
              list={list}
              handleChange={handleChange}
              boxes={{
                label: "Roles",
                keys: [
                  setKeyObject("sitewise.Permissions.View.Roles", "View"),
                  setKeyObject("sitewise.Permissions.Manage.Roles", "Edit"),
                ],
              }}
            />
          </div>

          <div className={classes.sectionTitle}>ZONE & ALERT MANAGEMENT</div>

          <div style={{ display: "flex", marginTop: "4px" }}>
            <CheckboxesForm
              list={list}
              handleChange={handleChange}
              boxes={{
                label: "Zones",
                keys: [
                  setKeyObject("sitewise.Permissions.View.Zones", "View"),
                  setKeyObject("sitewise.Permissions.Manage.Zones", "Edit")
                ],
              }}
            />

            <CheckboxesForm
              list={list}
              handleChange={handleChange}
              boxes={{
                label: "Devices",
                keys: [
                  setKeyObject("sitewise.Permissions.View.Devices", "View"),
                  setKeyObject("sitewise.Permissions.Manage.Devices", "Edit")
                ],
              }}
            />

            <CheckboxesForm
              list={list}
              handleChange={handleChange}
              boxes={{
                label: "Alerts",
                keys: [
                  setKeyObject("sitewise.Permissions.View.Alerts", "View"),
                  setKeyObject("sitewise.Permissions.Manage.Alerts", "Edit")
                ],
              }}
            />
          </div>

          <div className={classes.sectionTitle}>MESSAGES</div>

          <div style={{ display: "flex", marginTop: "4px" }}>
            <CheckboxesForm
              list={list}
              handleChange={handleChange}
              boxes={{
                label: "Bulk Messages",
                keys: [
                  setKeyObject("sitewise.Permissions.View.BulkMessages", "View"),
                  setKeyObject("sitewise.Permissions.Manage.BulkMessages", "Edit")
                ],
              }}
            />

            <CheckboxesForm
              handleChange={handleChange}
              list={list}
              boxes={{
                label: "Dashboard Messages",
                keys: [
                  setKeyObject("sitewise.Permissions.View.DashboardMessages", "View"),
                ],
              }}
            />
          </div>

          <FormControl variant="filled" className={classes.formControlActions}>
            <Button color="primary" variant="outlined" onClick={handleCancelBttnClick}>
              Cancel
            </Button>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ marginLeft: "10px" }}
              disabled={!list.length}
            >
              Create
            </Button>
          </FormControl>
        </form>
      </DialogTitle>
    </Dialog>
  );
};
