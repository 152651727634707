import React, { useState, useContext } from "react";
import {
  FormControl,
  Select,
  MenuItem,
  makeStyles,
  InputLabel,
  IconButton,
  Button,
  Tooltip,
  FormControlLabel,
  FormGroup,
  Box,
  TextField,
  OutlinedInput,
  Chip,
  Checkbox,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  GlobalReducerActions,
  SitesReducerActions,
  SITES_REDUCER_TYPES,
} from "../../../../reducers";
import { DeviceDialog } from "./device-dialog";
import { FONTS } from "../../../../utils";
import { ICONS, UserPermissionContext } from "../../../../commons";
import { YellowSwitch } from "../../../../commons/switch";
import { ParagraphJSON } from "../../../../commons/paragraphs";
import { YellowCheckbox } from "../../../../commons/checkbox";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { validateEmail } from "../../../../utils";

const spaceEvenly = "space-evenly";

const useStyles = makeStyles(() => ({
  formControl: {
    width: "auto",
  },
  formGroup: {
    display: "flex",
  },
  formLabel1: {
    ...FONTS.Button1_Bold_14,
  },
  checkboxContainer: {
    marginRight: "60px",
    flexDirection: "row",
    width: "100%",
  },
  formContainer: {
    display: "flex",
    justifyContent: spaceEvenly,
    marginBottom: "15px",
  },
  alertsContainer: {
    display: "flex",
    marginBottom: "0",
    paddingTop: "25px",
    justifyContent: spaceEvenly,
    paddingBottom: "25px",
  },
  formContentContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    overflowY: "auto",
  },
  formPlotClearOptionContainer: {
    display: "inline-block",
    width: "90%",
  },
  plotClearButtonContainer: {
    display: "flex",
    justifyContent: "flex-start",
    width: "320px",
  },
  updateMessageContainer: {
    display: "inline-block",
    width: "45%",
  },
  emergencyContactContainer: {
    color: "black",
    background: "transparent",
    marginTop: "5px",
    marginBottom: "15px",
  },
  emergencyContactDropdown: {
    maxHeight: "190px !important",
  },
  plotClearButtonParagraph: {
    height: "28px",
    fontSize: "12px",
    lineHeight: "120%",
  },
  plotClearButtonLabel: {
    height: "14px",
    fontSize: "14px",
    fontWeight: "bold",
    lineHeight: "100%",
  },
  formLabel2: {
    marginBottom: "17px",
    ...FONTS.Label2_ShellBold_12,
  },
  deviceList: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    margin: "10px",
    color: "#FBCE07",
    border: "1px solid",
    ...FONTS.Button1_Bold_16,
  },
  gasTypesContainer: {
    display: "flex",
    marginBottom: "0",
    justifyContent: spaceEvenly,
    paddingBottom: "25px",
    width: "320px",
  },
  primaryButton: {
    margin: "10px",
    backgroundColor: "#FBCE07",
    ...FONTS.Button1_Bold_16,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const checkboxLabels = {
  sos: {
    label: "SOS",
  },
  gasAlert: {
    label: "Gas Alert",
  },
  manDown: {
    label: "Man Down",
  },
};

const availableDevices = ["Blackline", "Mobile"];

const GasTypesList = ["LEL", "H2S", "CO", "O2", "PID"];

export const SiteDeviceForm = (props) => {
  const { currentSite, navigateToSites } = props;

  const dispatch = useDispatch();
  const classes = useStyles();
  const { Permissions } = useContext(UserPermissionContext);

  const [currentDeviceInfo, setCurrentDeviceInfo] = useState({
    device: "",
  });

  const [alertConfiguration, setAlertConfigurationState] = useState(
    currentSite?.alertConfiguration ?? {}
  );
  const [evacuationMessage, setEvacuationMessage] = useState(currentSite?.evacuationMessage ?? "");
  const devicesList = Object.keys(currentSite.deviceConfiguration);
  const showDeviceDialog = useSelector((state) => state.sitesScreen.dialogs.showDeviceDialog);

  const isGasTypes = alertConfiguration?.gasConfigurationJSON || [];
  const gasTypes = Array.isArray(isGasTypes) ? isGasTypes : isGasTypes?.split(",").map((e) => e);
  const [gasType, setgasType] = React.useState(gasTypes);
  const [emergencyContactDetails, setEmergencyContactDetails] = useState(
    currentSite?.emergencyContact ?? {}
  );
  const [errorText, setErrorText] = useState("");
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    const finalValue = typeof value === "string" ? value.split(",") : value;
    setgasType(
      // On autofill we get a stringified value.
      finalValue
    );
    setAlertConfigurationState({
      ...alertConfiguration,
      gasConfigurationJSON: finalValue,
    });
  };

  const handleDelete = (selected) => () => {
    const types = gasType.filter((type) => type !== selected);
    setgasType(types);
    setAlertConfigurationState({
      ...alertConfiguration,
      gasConfigurationJSON: types,
    });
  };

  const onChangeAlertConfiguration = (e, key) => {
    const gasConfigurationJSON = Array.isArray(alertConfiguration.gasConfigurationJSON)
      ? alertConfiguration.gasConfigurationJSON
      : [alertConfiguration.gasConfigurationJSON];
    setAlertConfigurationState({
      ...alertConfiguration,
      gasConfigurationJSON: gasConfigurationJSON,
      [key]: e.target.checked,
    });
  };

  const onSave = () => {
    const gasConfigurationJSON = Array.isArray(alertConfiguration.gasConfigurationJSON)
      ? alertConfiguration.gasConfigurationJSON
      : [alertConfiguration.gasConfigurationJSON];
    const finalConfiguration = {
      ...alertConfiguration,
      gasConfigurationJSON: gasConfigurationJSON,
    };

    dispatch(SitesReducerActions.updateAlertConfigurationsBySiteId(finalConfiguration, false));
    dispatch(SitesReducerActions.updateEvacuationMessageBySiteId(evacuationMessage, false));
    if(emergencyContactDetails.emergencyEmail){
      if (validateEmail(emergencyContactDetails.emergencyEmail)) {
        setErrorText("");
        dispatch(SitesReducerActions.updateEmergencyContactsBySiteId(emergencyContactDetails, false));
        navigateToSites();
      } else {
        setErrorText("Invalid Email");
      }
    }else{
      dispatch(SitesReducerActions.updateEmergencyContactsBySiteId(emergencyContactDetails, false));
      navigateToSites();
    }

  };

  const handleSelection = (e) => {
    setCurrentDeviceInfo({
      device: e.target.value,
    });
    dispatch({ type: SITES_REDUCER_TYPES.SET_DEVICE_DIALOG_DISPLAY, payload: true });
  };

  function handlePlotClearReminder({ event, dispatch }) {
    event.preventDefault();
    const newDeviceConfiguration = currentSite.deviceConfiguration;
    newDeviceConfiguration.enablePlotClearReminder =
      !currentSite.deviceConfiguration.enablePlotClearReminder;
    dispatch(SitesReducerActions.updateDeviceConfigById(newDeviceConfiguration));
  }

  const removeDevice = (device) => {
    const disabledMobileConfiguration = currentSite.deviceConfiguration;
    switch (device) {
      case "Blackline":
        delete disabledMobileConfiguration.Blackline;
        break;
      case "Mobile":
        delete disabledMobileConfiguration.Mobile;
        break;
      case "TWIG":
      default:
        break;
    }
    dispatch(SitesReducerActions.updateDeviceConfigById(disabledMobileConfiguration));
  };

  const editDevice = (name, props) => {
    setCurrentDeviceInfo({ device: name, ...props });
    dispatch({ type: SITES_REDUCER_TYPES.SET_DEVICE_DIALOG_DISPLAY, payload: true });
  };

  return (
    <>
      <div className={classes.formContainer}>
        <div className={classes.formContentContainer}>
          <label className={classes.formLabel2}>SELECT DEVICE TO ENABLE</label>
          <FormControl disabled={!Permissions.Manage_Sites}>
            <InputLabel htmlFor="select-label">Available Devices</InputLabel>
            <Select
              labelId="select-label"
              id="select"
              data-testid="select"
              value={currentDeviceInfo?.device}
              onChange={handleSelection}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              inputProps={{
                id: "select-label",
              }}
              style={{ minWidth: 216, width: "50%" }}
            >
              {availableDevices.map((device, i) => (
                <MenuItem
                  key={i}
                  value={device}
                  name={device}
                  disabled={devicesList.find((ele) => ele === device)}
                >
                  {device === "Mobile" ? "Mobile Device" : device}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className={classes.formContentContainer}>
          <label className={classes.formLabel2}>CURRENTLY ENABLED DEVICES</label>
          {devicesList.map(
            (device, i) =>
              device !== "enablePlotClearReminder" && (
                <div key={i} className={classes.deviceList}>
                  {device === "Mobile" ? "Mobile Device" : device}
                  <div>
                    {device === "Blackline" && (
                      <>
                        <Tooltip title="Edit">
                          <IconButton
                            disabled={!Permissions.Manage_Sites}
                            onClick={() =>
                              editDevice(device, currentSite.deviceConfiguration[device])
                            }
                          >
                            <ICONS.Edit />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={`Go to ${device}`}>
                          <IconButton
                            href="https://live.blacklinesafety.com/sign-in"
                            target="_blank"
                            rel="noopener"
                          >
                            <ICONS.OpenInNewIcon />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                    <Tooltip title="Remove">
                      <IconButton
                        disabled={!Permissions.Manage_Sites}
                        onClick={() => removeDevice(device)}
                      >
                        <ICONS.Delete />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              )
          )}
        </div>
      </div>

      <div className={classes.formContainer}>
        <div className={classes.formContentContainer}>
          <div className={classes.formPlotClearOptionContainer}>
            <label className={classes.formLabel2}>SELECT ALERT FREQUENCY</label>
            <p>{ParagraphJSON.selectAlertFrequencyMessage}</p>
            <div className={classes.plotClearButtonContainer}>
              <FormControlLabel
                disabled={!Permissions.Manage_Sites}
                label={
                  <span className={classes.plotClearButtonLabel}>
                    ENABLE REPEAT ALERTS DURING EVACUATION
                  </span>
                }
                className={classes.formContainer}
                control={
                  <YellowSwitch
                    checked={currentSite.deviceConfiguration.enablePlotClearReminder}
                    onChange={(event) => handlePlotClearReminder({ event, dispatch })}
                  />
                }
              />
            </div>
          </div>

          <FormControl disabled={!Permissions.Manage_Sites} className={classes.alertsContainer}>
            <label className={classes.formLabel2}>SELECT ALERT PREFERENCES</label>
            <div className={classes.formGroup}>
              <FormGroup className={classes.checkboxContainer}>
                {Object.keys(alertConfiguration).map((key) => {
                  const value = alertConfiguration[key];
                  if (key === "gasConfigurationJSON") {
                    return null;
                  }
                  return (
                    <FormControlLabel
                      classes={{
                        root: classes.formControl,
                      }}
                      control={
                        <YellowCheckbox
                          checked={value}
                          onChange={(e) => {
                            onChangeAlertConfiguration(e, key);
                          }}
                          name={key}
                        />
                      }
                      label={
                        <span className={classes.formLabel1}>
                          {checkboxLabels[key]?.label ?? key}
                        </span>
                      }
                    />
                  );
                })}
              </FormGroup>
            </div>
          </FormControl>

          <FormControl className={classes.gasTypesContainer}>
            <label className={classes.formLabel2}>Select Gas Types</label>
            <Select
              disabled={!Permissions.Manage_Sites || !alertConfiguration.gasAlert}
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              multiple
              value={gasType}
              onChange={handleChange}
              input={<OutlinedInput />}
              renderValue={(selected) => (
                <Box style={{ display: "flex", gap: 2 }}>
                  {selected.map((value) => (
                    <Chip
                      disabled={!Permissions.Manage_Sites || !alertConfiguration.gasAlert}
                      onMouseDown={(event) => {
                        event.stopPropagation();
                      }}
                      onDelete={handleDelete(value)}
                      key={value}
                      label={value}
                    />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {GasTypesList.map((name) => (
                <MenuItem key={name} value={name}>
                  <YellowCheckbox checked={gasType.indexOf(name) > -1} />
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div className={classes.formContentContainer}>
          <label className={classes.formLabel2}>ADD EMERGENCY CONTACT DETAILS</label>
          <div className={classes.contactDetailsContainer}>
            <div>
              <label className={classes.formLabel2}>Add voice call number</label>
              <PhoneInput
                disabled={!Permissions.Manage_Sites}
                className={classes.countryphone}
                inputStyle={{ backgroundColor: "transparent", color: "#ffffff" }}
                dropdownClass={classes.emergencyContactDropdown}
                containerClass={classes.emergencyContactContainer}
                value={emergencyContactDetails.phoneNumber}
                onChange={(value, country, e, formattedValue) => {
                  setEmergencyContactDetails({
                    ...emergencyContactDetails,
                    phoneNumber: formattedValue,
                  });
                }}
              />
            </div>

            <div>
              <label className={classes.formLabel2}>Add MS teams number</label>
              <PhoneInput
                disabled={!Permissions.Manage_Sites}
                inputStyle={{ backgroundColor: "transparent", color: "#ffffff" }}
                containerClass={classes.emergencyContactContainer}
                dropdownClass={classes.emergencyContactDropdown}
                value={emergencyContactDetails.teamsNumber}
                onChange={(value, country, e, formattedValue) => {
                  setEmergencyContactDetails({
                    ...emergencyContactDetails,
                    teamsNumber: formattedValue,
                  });
                }}
              />
            </div>

            <div>
              <Box style={{ width: "300px" }}>
                <label className={classes.formLabel2}>Add MS teams email address</label>
                <FormControl>
                <TextField
                  style={{ marginTop: "5px" }}
                  disabled={!Permissions.Manage_Sites}
                  fullWidth
                  type="email"
                  rows={1}
                  variant="outlined"
                  value={emergencyContactDetails.emergencyEmail}
                  onChange={(event) => {
                    if (errorText) {
                      setErrorText("");
                    }
                    setEmergencyContactDetails({
                      ...emergencyContactDetails,
                      emergencyEmail: event.target.value,
                    });
                  }}
                  helperText={errorText}
                  error={errorText}
                />
                </FormControl>
              </Box>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.updateMessageContainer}>
        <label className={classes.formLabel2}>UPDATE MESSAGE FOR EVACUATION</label>

        <Box pt={2}>
          <TextField
            disabled={!Permissions.Manage_Sites}
            fullWidth
            multiline
            rows={1}
            rowsMax={3}
            variant="filled"
            value={evacuationMessage}
            onChange={(event) => {
              setEvacuationMessage(event.target.value);
            }}
          />
        </Box>
      </div>

      <div className={classes.buttonContainer}>
        <Button
          className={classes.button}
          variant="outlined"
          size="medium"
          onClick={() => {
            navigateToSites();
          }}
        >
          Back to Site Coordinates
        </Button>
        <Button
          onClick={() => {
            navigateToSites();
          }}
          variant="outlined"
          size="medium"
          className={classes.button}
        >
          Cancel
        </Button>
        <Button
          className={classes.primaryButton}
          variant="contained"
          onClick={() => {
            onSave();
          }}
          size="medium"
        >
          {Permissions.Manage_Sites ? "Save and Exit" : "Exit"}
        </Button>
      </div>

      <DeviceDialog
        currentDeviceInfo={currentDeviceInfo}
        show={showDeviceDialog}
        onCancel={() => {
          dispatch({ type: SITES_REDUCER_TYPES.SET_DEVICE_DIALOG_DISPLAY, payload: false });
          setCurrentDeviceInfo({});
        }}
        setCurrentDeviceInfo={setCurrentDeviceInfo}
        onConfirm={() => dispatch(SitesReducerActions.getSiteBySiteId(currentSite.id))}
      />
    </>
  );
};